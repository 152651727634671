import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../core/i18n'
import { IWindow } from '../../../core/interfaces'
import { IJsonResponse } from '../../../core/JsonApiSerializer'
import { adminService } from '../../../core/services'
import { Button, InputText, Select, Spacer } from '../../atoms'
import { EditTable } from '../../organisms'
import AdminLayoutIndex from './_layouts/'

declare var window: IWindow

interface IProps {
  posts: {
    posts: IJsonResponse
  }
  search_params?: any
}

const COLUMNS = [
  {
    name: I18n.t('generic.id'),
    field: 'id',
    required: true,
  },
  {
    name: I18n.t('generic.name'),
    field: 'name',
    required: true,
  },
  {
    name: I18n.t('generic.status'),
    field: 'aasm_state_i18n',
    required: false,
  },
  {
    name: '限定公開状態',
    field: 'limited_access_flag',
    required: false,
  },
  {
    name: I18n.t('post.limited_access_password'),
    field: 'limited_access_password',
    required: false,
  },
  {
    name: I18n.t('generic.updated_at'),
    field: 'updated_at',
    required: false,
  },
]

const statusOptions = () => {
  return [
    { label: 'すべて', value: 'all' },
    { label: '公開（注文受付中）', value: 'published' },
    { label: '一時停止（注文非受付中）', value: 'suspended' },
    { label: '非公開（注文非受付中）', value: 'closed' },
  ]
}

const FIELDS = {
  status: 'status',
  keyword: 'keyword',
}

const Index: React.FC<IProps> = props => {
  const { data } = adminService.getDataFromJson(props.posts.posts)
  const [posts, setPosts] = React.useState(data)
  const handleDelete = React.useCallback(
    async id => {
      const response = await adminService.deletePost(id)
      const flush = response.data.flush
      window.flashMessages.addMessage({ text: flush.message, type: flush.type })
      window.globalModal.closeModal()

      setPosts(posts.filter(post => post.id !== id))
    },
    [posts]
  )
  return (
    <AdminLayoutIndex
      model="post"
      heading={
        <S.Heading>
          <span>{I18n.t('meta.post.shop')}</span>
          <Button small={true}>
            <a href="/posts/new">{I18n.t('generic.create_new')}</a>
          </Button>
        </S.Heading>
      }
      main={
        <EditTable
          editable={true}
          columns={COLUMNS}
          records={posts}
          pagination={props.posts.posts.meta}
          handleDelete={handleDelete}
          getEditLink={id => `/posts/${posts.find(post => post.id === id).slug}/edit`}
          formSearch={
            <>
              <InputText
                required={false}
                name="keyword"
                defaultValue={props.search_params.keyword || ''}
                label="キーワード"
                width={300}
              />
              <Spacer />
              <Select
                required={false}
                name="status"
                label="状態別"
                options={statusOptions()}
                defaultValue={props.search_params.status || 'all'}
                width={300}
              />
            </>
          }
          formFields={FIELDS}
        />
      }
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Heading = styled.div`
  .Button {
    margin-left: 24px;
  }
`

export default Index
