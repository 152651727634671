import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../core/i18n'
import { IUser, IWindow } from '../../../core/interfaces'
import { settingsService, userService } from '../../../core/services'
import { COLORS, THEME_COLOR_VARIABLE_NAME } from '../../../static/constants'
import { Button, InputText, Panel } from '../../atoms'
import { Form } from '../../molecules'
import { IImperativeMethods } from '../../molecules/Form'
import SettingLayoutIndex from '../settings/_layouts/'
import { ActiveTypes } from '../settings/Nav'

declare var window: IWindow

interface IProps {
  user: any
  active_path: ActiveTypes
}

interface IState {
  user: IUser
  // social_profiles: string[]
  email: string
  showModal: boolean
  isSubmitEnabled: boolean
  errors: { [key: string]: null | string }
}

export default class AccountEdit extends React.Component<IProps, IState> {
  private mailFormRef: React.RefObject<IImperativeMethods>
  private passwordFormRef: React.RefObject<IImperativeMethods>

  constructor(props) {
    super(props)

    const { user: initialUser } = userService.getUserFromJson(props.user)

    this.state = {
      user: initialUser,
      // social_profiles: [...initialUser.social_profiles],
      email: initialUser.email,
      showModal: false,
      isSubmitEnabled: true,
      errors: {},
    }
    this.mailFormRef = React.createRef()
    this.passwordFormRef = React.createRef()
  }

  public handleUpdateForm = (errors, isSubmitEnabled) => {
    this.setState({ errors, isSubmitEnabled })
  }

  public updateEmail = async values => {
    const response = await userService.updateUserEmail(values)
    if (response.flush && response.flush.type === 'error') {
      this.setState({ errors: { email: response.flush.message } }, () => {
        this.showMailForm()
      })
    } else {
      this.showEmailConfirm(values.email)
    }
  }

  public updatePassword = async values => {
    const { flush } = await userService.updatePassword({ user: values })
    window.flashMessages.addMessage({ text: flush.message, type: flush.type })
    window.globalModal.closeModal()
  }

  public showMailForm = () => {
    const FIELDS_MAIL = { email: 'email' }
    const MailForm: React.FC<{}> = props => {
      const [errors, setErrors] = React.useState<{ [key: string]: string | null }>({ email: null })

      React.useEffect(() => {
        if (this.state.errors.email) {
          setErrors(prev => ({ ...prev, email: this.state.errors.email }))
        }
      }, [this.state.errors.email])

      return (
        <Form
          ref={this.mailFormRef}
          fields={FIELDS_MAIL}
          handleUpdateForm={(updatedErrors, isSubmitEnabled) => setErrors(updatedErrors)}
          handleSubmit={(initialValues, values) => this.updateEmail(values)}
        >
          <InputText
            required={true}
            name="email"
            label={I18n.t('settings.new_email')}
            error={errors.email}
            defaultValue={this.state.email}
          />
          <p className="description">
            {I18n.t('settings.confirmation_email_will_be_sent_to_this_email_address')}
          </p>
        </Form>
      )
    }

    window.globalModal.showModal({
      title: I18n.t('settings.change_email_address'),
      body: <MailForm />,
      closeText: I18n.t('generic.cancel'),
      submitText: I18n.t('generic.update'),
      handleSubmit: () => this.mailFormRef.current.handleFormSubmit(),
    })
  }

  public showEmailConfirm = email => {
    const EmailConfirm: React.FC<{}> = props => (
      <>
        <p>'{email}'</p>
        <p className="description">{I18n.t('settings.confirm_email')}</p>
      </>
    )

    window.globalModal.showModal({
      title: I18n.t('settings.change_email_address'),
      body: <EmailConfirm />,
      closeText: I18n.t('generic.close'),
      submitText: '',
      handleSubmit: null,
    })
  }

  public showPasswordForm = () => {
    const FIELDS_PASSWORD = {
      password: 'password',
      current_password: 'current_password',
    }
    const PasswordForm: React.FC<{}> = props => {
      const [errors, setErrors] = React.useState<{ [key: string]: string | null }>({
        password: null,
        current_password: null,
      })

      return (
        <Form
          ref={this.passwordFormRef}
          fields={FIELDS_PASSWORD}
          handleUpdateForm={(updatedErrors, isSubmitEnabled) => {
            setErrors(updatedErrors)
          }}
          handleSubmit={(initialValues, values) => {
            this.updatePassword(values)
          }}
        >
          <S.FormItem>
            <InputText
              required={true}
              inputType="password"
              name="current_password"
              label={I18n.t('settings.current_password')}
              defaultValue=""
              error={errors.current_password}
            />
          </S.FormItem>
          <S.FormItem>
            <InputText
              required={true}
              inputType="password"
              name="password"
              label={I18n.t('settings.new_password')}
              defaultValue=""
              error={errors.password}
            />
            <S.FormComment>
              <p>英字小文字、英字大文字、数字、記号のうち、3種類以上</p>
              <p>10文字以上で作成してください</p>
            </S.FormComment>
          </S.FormItem>
        </Form>
      )
    }

    window.globalModal.showModal({
      title: I18n.t('settings.change_password'),
      body: <PasswordForm />,
      closeText: I18n.t('generic.cancel'),
      submitText: I18n.t('generic.update'),
      handleSubmit: () => this.passwordFormRef.current.handleFormSubmit(),
    })
  }

  public deleteSocialProfile = async provider => {
    const { user, flush } = await settingsService.deleteSocialProfile(provider)
    window.flashMessages.addMessage({
      text: flush.message,
      type: flush.type,
    })
  }
  public render() {
    return (
      <SettingLayoutIndex
        activePath={this.props.active_path}
        main={
          <Panel title={I18n.t('generic.accounts')}>
            <S.Field>
              <label>{I18n.t('generic.email')}</label>
              <div>
                <p>{this.state.email}</p>
                <a onClick={this.showMailForm}>{I18n.t('generic.change')}</a>
              </div>
            </S.Field>
            <S.Field>
              <label>{I18n.t('generic.password')}</label>
              <div>
                <p>******</p>
                <a onClick={this.showPasswordForm}>{I18n.t('generic.change')}</a>
              </div>
            </S.Field>
            <Button>
              <a href="/">トップページへ戻る</a>
            </Button>

            {this.state.showModal && (
              <S.Modal className="c-modal__window">
                <div className="c-modal__inner has-text-centered">
                  <p className="is-size-6 has-text-weight-bold u-margin--b20">アカウントの削除</p>
                  <p className="">
                    アカウント情報・履歴などの情報をすべて削除します。
                    <br />
                    <span className="has-text-weight-bold">復元の対応はできません</span>
                    ので、ご確認の上削除をお願いいたします。
                  </p>
                  <div className="u-margin--t10">
                    <ul className="c-modal__list">
                      <li>
                        <span className="has-text-weight-bold">
                          本人環境をご利用の上、決済の利用契約を停止させていただきます。
                        </span>
                      </li>
                      <li>
                        稼働中の定期課金がある場合は、アカウント削除前に停止処理を行ってください。
                      </li>
                      <li>
                        <span className="has-text-weight-bold">
                          未入金の売り上げ金額が振込手数料である250円以上残っている場合、翌月の末日にご登録済みの口座へ入金させていただきます。
                        </span>
                      </li>
                    </ul>
                  </div>
                  <form>
                    <div className="u-margin--b20 u-margin--t20">
                      <label className="checkbox">
                        <input type="checkbox" />
                        上記の内容すべてに同意します
                      </label>
                    </div>
                    <div className="control u-width--300 u-margin--auto">
                      <button
                        type="submit"
                        className="button is-danger is-fullwidth"
                        value="アカウントを削除"
                      />
                    </div>
                  </form>
                </div>
                <a className="c-modal__close" onClick={() => this.setState({ showModal: false })} />
              </S.Modal>
            )}
          </Panel>
        }
      />
    )
  }
}
const S: { [key: string]: AnyStyledComponent } = {}
S.FormItem = styled.div`
  & + & {
    margin-top: 16px;
  }
`
S.FormComment = styled.div`
  font-size: 12px;
  text-align: right;
  color: #777;
`

S.Field = styled.div`
  display: flex;
  flex-wrap: wrap;
  & + & {
    margin-top: 24px;
    padding-top: 20px;
    border-top: solid 1px ${COLORS.Border};
  }

  > label {
    width: 200px;
    font-weight: bold;
  }

  > div {
    min-width: 120px;
    width: 200px;
    flex: 1;
    overflow-wrap: break-word;
  }

  a {
    color: var(${THEME_COLOR_VARIABLE_NAME});
    display: inline-block;
    margin-top: 4px;
    font-size: 14px;
    cursor: pointer;
  }
`
S.RemoveSocialLinks = styled.ul`
  margin-top: 24px;

  li {
    color: var(${THEME_COLOR_VARIABLE_NAME});
    text-decoration: underline;
    cursor: pointer;
  }

  li + li {
    margin-top: 12px;
  }
`

S.Modal = styled.div`
  display: block;
  opacity: 1;
  transform: none;
  z-index: 6000;
`
