import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../../core/i18n'
import { IWindow } from '../../../../../core/interfaces'
import { reservationService } from '../../../../../core/services'
import { Button, InputText, Select, Spacer } from '../../../../atoms'
import { Form } from '../../../../molecules'

declare var window: IWindow

interface IDelivery {
  status: string
}
interface IProps {
  reservationId: number
  deliveryStatus: string
  isAdmin: boolean
  setDeliveryStatus(IDelivery): void
}

const StatusModal: React.FC<IProps> = props => {
  const [modalErrors, setModalErrors] = React.useState<{ [key: string]: null | string }>({
    reason: null,
  })
  const [isSubmittable, setIsSubmittable] = React.useState<boolean>(false)
  const [isProcessing, setIsProcessing] = React.useState(false)
  const AdminOptions = [
    { label: 'ステータスを選択してください', value: '' },
    { label: '発注完了', value: 'completed' },
  ]
  const deliveryOptions = AdminOptions
  const onChangeHandle = e => {
    const value = e.target.value
    const status = props.deliveryStatus
    let result = false
    if (status === 'requested') {
      if (value === 'completed') {
        result = true
      }
    } else {
      result = false
    }
    setIsSubmittable(result)
  }

  const handleSubmit = async (initialValues, values) => {
    setIsProcessing(true)
    let params = {}
    params = {
      delivery: {
        status: values.status,
      },
    }

    const { flush, reservation } = await reservationService.updateDelivery(
      props.reservationId,
      params
    )
    props.setDeliveryStatus(reservation.delivery)
    window.globalModal.closeModal()
    window.flashMessages.addMessage({ text: flush.message, type: flush.type })
  }

  return (
    <Form
      fields={{ status: 'status' }}
      handleUpdateForm={updatedErrors => {
        setModalErrors(updatedErrors)
      }}
      handleSubmit={handleSubmit}
    >
      <Select
        required={true}
        name="status"
        options={deliveryOptions}
        label={I18n.t('generic.status')}
        defaultValue="requested"
        onChangeHandler={onChangeHandle}
      />
      <S.Footer>
        <Button primary={true} disabled={isProcessing || !isSubmittable}>
          {I18n.t('generic.update')}
        </Button>
      </S.Footer>
    </Form>
  )
}
const S: { [key: string]: AnyStyledComponent } = {}

S.Footer = styled.div`
  text-align: center;
  margin-top: 20px;
`
export default StatusModal
