import * as React from 'react'
import { BREAKPOINT_TABLET_SMALL, COLORS, THEME_COLOR_VARIABLE_NAME } from 'static/constants'
import styled, { AnyStyledComponent } from 'styled-components'
import { IAddress } from '../../core/interfaces'
import injectGoogleMaps from '../../utils/injectGoogleMaps'

interface IMapProps {
  coordinate: { latitude: null | number; longitude: null | number }
  setCoordinate(coordinate: { latitude: null | number; longitude: null | number }): void
  setFields(place): void
}

const MapBase: React.FC<IMapProps> = ({ coordinate, setCoordinate, setFields }) => {
  const [map, setMap] = React.useState(null)
  const [marker, setMarker] = React.useState(null)
  const [dragging, setDragging] = React.useState<'dragging' | ''>('')
  const isVisible = React.useMemo(() => {
    return coordinate.latitude !== null && coordinate.longitude !== null
  }, [coordinate])

  const autocompleteRef = React.useRef<google.maps.places.Autocomplete>(null)

  React.useEffect(() => {
    if (coordinate?.latitude && coordinate?.longitude) {
      const { latitude, longitude } = coordinate
      const LatLng = new google.maps.LatLng(Number(latitude), Number(longitude))
      createMap(LatLng)
    }
  }, [])

  const setPlace = () => {
    const place = autocompleteRef.current.getPlace()
    if (place.name === '' || !place.address_components) {
      return
    }

    const geocoder = new google.maps.Geocoder()
    geocoder.geocode({ placeId: place.place_id }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results[0]) {
        const { lat, lng } = results[0].geometry.location
        const newLatLng = new google.maps.LatLng(lat(), lng())
        setCoordinate({ latitude: lat(), longitude: lng() })
        setFields(place)
        if (map) {
          map.panTo(newLatLng)
        } else {
          createMap(newLatLng)
        }
      }
    })
  }

  const createMap = async initCoordinate => {
    const Options = {
      zoom: 15,
      disableDefaultUI: true,
      gestureHandling: 'greedy' as const,
      center: initCoordinate,
      mapTypeId: 'roadmap',
      clickableIcons: false,
    }
    const initializedMap = new google.maps.Map(document.getElementById('map'), Options)
    const newMarker = new google.maps.Marker({
      position: initCoordinate,
      map: initializedMap,
      draggable: true,
    })

    setMarker(newMarker)

    google.maps.event.addListener(newMarker, 'dragend', event => {
      const newPosition = event.latLng
      setCoordinate({ latitude: newPosition.lat(), longitude: newPosition.lng() })
    })

    // let timeoutID = null

    // const centerChangeHandler = () => {
    //   if (dragging !== 'dragging') {
    //     setDragging('dragging')
    //   }
    //   if (timeoutID) {
    //     clearTimeout(timeoutID)
    //   }
    //   timeoutID = setTimeout(() => {
    //     const center = initializedMap.getCenter()
    //     setCoordinate({ latitude: center.lat(), longitude: center.lng() })
    //     setDragging('')
    //     timeoutID = null
    //   }, 500)
    // }

    // google.maps.event.addListener(initializedMap, 'center_changed', centerChangeHandler)

    setMap(initializedMap)
  }

  return (
    <S.Map isVisible={isVisible}>
      <div id="map" />
      <AutoCompleteInput autocompleteRef={autocompleteRef} onPlaceChange={setPlace} />
    </S.Map>
  )
}
const AutoCompleteInputBase: React.FC<{
  autocompleteRef: React.MutableRefObject<google.maps.places.Autocomplete>
  defaultValue?: string
  onPlaceChange(place: any): void
}> = ({ autocompleteRef, defaultValue, onPlaceChange }) => {
  const addressInput = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (!google) {
      return
    }
    autocompleteRef.current = new google.maps.places.Autocomplete(addressInput.current, {
      types: ['geocode'],
      componentRestrictions: { country: 'jp' },
    })
    autocompleteRef.current?.setComponentRestrictions({ country: 'jp' })
    autocompleteRef.current?.addListener('place_changed', onPlaceChange)
  }, [])

  return (
    <S.AddressSearch>
      <S.Input
        type="search"
        name="autocomplete_field"
        placeholder="住所を入力して検索"
        defaultValue={defaultValue || ''}
        ref={addressInput}
        // onChange={onChange}
        autocomplete="off"
      />
    </S.AddressSearch>
  )
}

const AutoCompleteInput = AutoCompleteInputBase
const S: { [key: string]: AnyStyledComponent } = {}

S.AddressSearch = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px;
  width: 100%;
  background-color: #f7f7f7;
  border-top: solid 1px ${COLORS.Border};
`

S.Input = styled.input`
  display: inline-block;
  width: 100%;
  padding: 4px 8px;
  border: solid 1px ${COLORS.Border};
  border-radius: 4px;
  font-size: 15px;
  transition: border 0.2s ease;
  outline: none;
  background-color: #fff;

  &.error {
    border: 1px solid ${COLORS.Danger};
  }

  &:not([readonly]]):focus {
    border: solid 1px var(${THEME_COLOR_VARIABLE_NAME});
  }

  &[readonly] {
    background-color: ${COLORS.Border};
    color: #888;
  }
`

S.Note = styled.p`
  font-size: 14px;
  margin-bottom: 16px;
`

S.Map = styled.div<{ isVisible: boolean }>`
  position: relative;
  height: 300px;
  margin-bottom: 32px;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    margin-bottom: 16px;
  }

  #map {
    /* display: ${({ isVisible }) => (isVisible ? 'block' : 'none')}; */
    height: 100%;
    width: 100%;
  }
`

export default MapBase
