import ClassNames from 'classnames'
import * as Color from 'color'
import * as React from 'react'
import { render } from 'react-dom'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import { IPost } from '../../core/interfaces'
import { postService } from '../../core/services'
import { COLORS, PAYMENT_REQUIRED, THEME_COLOR_VARIABLE_NAME } from '../../static/constants'
import Score from './Score'

interface IDetailMarkerProps {
  post: IPost
}

interface IMarkerProps {
  post: IPost
}

const Detail: React.FC<IDetailMarkerProps> = ({ post }) => {
  const isPublished = post.aasm_state === 'published'
  const postImageURL = post.post_image?.image_url
  return (
    <DetailMarker
      id={`marker-detail-${post.id}`}
      className={`Marker_Detail ${post.is_open && isPublished ? '' : 'disabled'}`}
      href={`/posts/${post.slug}`}
      target="_blank"
    >
      <div className="Marker_Wrapper">
        <div className="Marker_Image">
          {postImageURL ? (
            <img src={postImageURL} alt="" loading="lazy" decoding="async" />
          ) : (
            <p className="NoImage">No Image</p>
          )}
        </div>
        <div className="Marker_Body">
          <h3 className="Marker_PostName">{post.name}</h3>
          {!post.is_open && <p>受付時間外です</p>}
          {post.is_open && !isPublished && <p>受付停止中です</p>}
          <p className="Marker_PostDescription">{post.description}</p>
        </div>
      </div>
    </DetailMarker>
  )
}

const PostName: React.FC<IMarkerProps> = props => {
  const isActive = props.post.is_open && props.post.aasm_state === 'published'
  return (
    <PostNameMarker className={`PostName ${isActive ? '' : 'disabled'}`}>
      <p>{props.post.name}</p>
    </PostNameMarker>
  )
}

const PostNameMarker = styled.div`
  padding: 6px;
  background-color: #fff;
  border-radius: 3px;
  p {
    max-width: 280px;
    white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  &.disabled {
    color: #00000055;
  }
  .hovered & {
    background-color: var(${THEME_COLOR_VARIABLE_NAME});
    color: #fff;
    p {
      -webkit-line-clamp: 10;
    }
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -4px;
    display: block;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    background-color: inherit;
    z-index: -1;
  }
`

const DetailMarker = styled.a`
  display: block;
  width: 280px;
  height: 320px;
  white-space: initial;
  border-radius: 3px;
  overflow: hidden;
  background-color: #fff;
  color: ${COLORS.Text};
  &.disabled {
    pointer-events: none;

    .Marker_Wrapper {
      opacity: 0.3;
    }
  }
  img {
    width: 100%;
  }

  .NoImage {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e4e4e4;
  }

  .Marker_Body {
    padding: 8px;
  }

  .Marker_Image {
    width: 100%;
    height: 180px;

    > img {
      object-fit: cover;
      height: inherit;
    }
  }

  .Marker_Favorite {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 12px;
    right: 12px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;

    .material-icons {
      font-size: 20px;
      color: ${Color(COLORS.Text)
        .lighten(3.6)
        .hex()};

      &.filled {
        display: none;
        color: #fb585c;
      }
    }
  }

  .Marker_PostPrice {
    font-weight: normal;
    margin-top: 8px;
  }

  .Score {
    margin-top: 12px;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -4px;
    display: block;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    background-color: inherit;
    z-index: -1;
  }
`

const Z_INDEX = {
  default: 'auto',
  hovered: '10',
  active: '15',
}

function CustomMarker(googleMaps) {
  class Marker extends googleMaps.OverlayView {
    public PADDING_TOP = 66
    public PADDING_LEFT = 16
    public PADDING_RIGHT = 66
    public PADDING_BOTTOM = 16
    public INFO_MARKER_WIDTH = 280
    public INFO_MARKER_HEIGHT = 320
    public latlng: any
    public map: any
    public args: any
    public markerId: number
    public div: any

    constructor(latlng, map, args) {
      super()

      this.latlng = latlng
      this.map = map
      this.args = args
      this.markerId = args.post.id
      this.setMap(map)
    }

    public getMarkerId() {
      return this.markerId
    }

    public showDetail() {
      this.div.classList.add('active')
      this.div.classList.remove('hovered')
      this.div.style.zIndex = Z_INDEX.active
      this.adjustMapPan()
    }

    public closeDetail() {
      render(<PostName post={this.args.post} />, this.div)
      this.div.classList.remove('active')
      this.div.style.zIndex = Z_INDEX.default
    }

    public handleOnMouseEnter() {
      if (this.div) {
        this.div.classList.add('hovered')
        this.div.style.zIndex = Z_INDEX.hovered
      }
    }

    public handleOnMouseLeave() {
      if (this.div) {
        this.div.classList.remove('hovered')
        this.div.style.zIndex = this.div.classList.contains('active')
          ? Z_INDEX.active
          : Z_INDEX.default
      }
    }

    private onAdd() {
      let div = this.div

      if (!div) {
        div = this.div = document.createElement('div')
        div.id = `marker_${this.args.post.id}`
        div.classList.add('Marker')
        div.style.zIndex = 'auto'
        div.style.position = 'absolute'
        div.style.display = 'inline-block'
        div.style.whiteSpace = 'nowrap'
        div.style.fontSize = '14px'
        div.style.transform = 'translate(-50%, -100%)'
        div.style.boxShadow = '0 1px 3px 0 rgba(21, 27, 38, 0.15)'
        div.style.cursor = 'pointer'

        render(<PostName post={this.args.post} />, div)

        googleMaps.event.addDomListener(div, 'click', async event => {
          event.stopPropagation()
          this.showDetail()
          this.args.setActiveMarker(this.markerId)
        })
        googleMaps.event.addDomListener(div, 'mouseenter', async event => {
          event.stopPropagation()
          this.handleOnMouseEnter()
        })
        googleMaps.event.addDomListener(div, 'mouseleave', async event => {
          event.stopPropagation()
          this.handleOnMouseLeave()
        })
        const panes = this.getPanes()
        panes.floatPane.appendChild(div)
      }
    }

    private draw() {
      const point = this.getProjection().fromLatLngToDivPixel(this.latlng)

      if (!this.div) {
        return
      }

      this.div.style.left = `${point.x}px`
      this.div.style.top = `${point.y}px`
    }

    private adjustMapPan() {
      const containerPoint = this.getProjection().fromLatLngToContainerPixel(this.latlng)
      const mapWidth = this.map.getDiv().offsetWidth
      const mapHeight = this.map.getDiv().offsetHeight
      const markerPositions = {
        left: containerPoint.x - this.INFO_MARKER_WIDTH / 2,
        right: containerPoint.x + this.INFO_MARKER_WIDTH / 2,
        top: containerPoint.y - this.INFO_MARKER_HEIGHT,
        bottom: containerPoint.y,
      }

      let panX = 0
      let panY = 0

      if (markerPositions.top < 0 + this.PADDING_TOP) {
        panY = markerPositions.top - this.PADDING_TOP
      }

      if (markerPositions.bottom > mapHeight - this.PADDING_BOTTOM) {
        const offset = markerPositions.bottom - mapHeight + this.PADDING_BOTTOM
        panY = offset
      }

      if (markerPositions.left < 0 + this.PADDING_LEFT) {
        panX = markerPositions.left - this.PADDING_LEFT
      }

      if (markerPositions.right > mapWidth - this.PADDING_RIGHT) {
        const offset = markerPositions.right - mapWidth + this.PADDING_RIGHT
        panX = offset
      }

      if (panX !== 0 || panY !== 0) {
        this.map.panBy(panX, panY)
      }
    }

    private onRemove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div)
        this.div = null
      }
    }
  }

  return (latlng, map, args) => new Marker(latlng, map, args)
}

export default CustomMarker
