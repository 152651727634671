import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons'
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import parse from 'html-react-parser'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { IJsonResponse, IPager, IPostItem, IUser, IWindow } from '../../../core/interfaces'
import { postService } from '../../../core/services'
import {
  BREAKPOINT_TABLET_LARGE,
  BREAKPOINT_TABLET_MOBILE,
  BREAKPOINT_TABLET_SMALL,
  COLORS,
} from '../../../static/constants'
import { formatPrice } from '../../../utils/number'
import { Button, Pagination, Select } from '../../atoms'
import { Form } from '../../molecules'
import PostGallery from './Gallery'

declare var window: IWindow

const SEARCH_FIELDS = {
  order: 'order',
}

const orderOptions = () => {
  return [
    { label: 'お気に入り登録順', value: 'favorite_order' },
    { label: '新着商品順', value: 'recent' },
    { label: '価格が安い順', value: 'price_low' },
    { label: '価格が高い順', value: 'price_high' },
  ]
}

interface IFavoriteIndexProps {
  user: IUser
  page: string
  postItems: IPostItem[]
  pagination?: IPager
  search_params?: any
  post_items: {
    postitems: IJsonResponse
  }
  changePage(page: string): void
}

export const FavoriteIndex: React.FC<IFavoriteIndexProps> = ({ ...props }) => {
  const { data, pagination } = postService.getDataFromJson(props.post_items.postitems)
  const [postItems, setPostItems] = React.useState(data)
  const scrollContainerRef = React.useRef(null)
  const showCartDetailModal = newItem => {
    const ModalBody: React.FC<{}> = modalBodyProps => {
      const [isItemFavorite, setIsFavorite] = React.useState<boolean>(newItem.is_favorite ?? false)
      const allergyImages = []
      const allergies = newItem.allergy
        .filter(item => item !== '')
        .map(item =>
          allergyImages.push({
            id: item,
            image_url: `/images/allergy-icon/allergy-${item}.png`,
          })
        )
      const itemImages = []
      newItem.post_item_images.map(image =>
        itemImages.push({
          id: image.id,
          image_url: image.image_url,
        })
      )

      const FavoriteStarIcon = ({ isFavorite }) => (
        <div
          style={{
            position: 'absolute',
            top: '60px',
            right: '60px',
            color: '#ffcc33',
            fontSize: '36px',
            zIndex: 1000,
          }}
        >
          <FontAwesomeIcon icon={isFavorite ? solidStar : regularStar} />
        </div>
      )

      return (
        <S.CartModal>
          <S.Close onClick={window.globalModal.closeModal}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </S.Close>
          {itemImages.length === 0 ? (
            <S.ItemNoImageWrap>
              <FavoriteStarIcon isFavorite={isItemFavorite} />
              <S.NoImage>No Image</S.NoImage>
            </S.ItemNoImageWrap>
          ) : (
            <>
              <FavoriteStarIcon isFavorite={isItemFavorite} />
              <PostGallery postImages={itemImages} />
            </>
          )}
          <S.CartItemName>{newItem.name}</S.CartItemName>
          <S.Price>{formatPrice(newItem.retail_price)}</S.Price>
          <S.ModalItemDescription>{parse(newItem.description)}</S.ModalItemDescription>
          {newItem.jan && <S.ModalItemJan>商品番号 : {newItem.jan}</S.ModalItemJan>}
          {allergies.length > 0 && (
            <S.AllergyImageWrap>
              {allergyImages.map((allergy, index) => {
                return (
                  <S.ModalAllergyImage key={index}>
                    <img src={allergy.image_url} loading="lazy" decoding="async" />
                  </S.ModalAllergyImage>
                )
              })}
            </S.AllergyImageWrap>
          )}
          <S.AddCartWrapper>
            <S.ButtonWrapper>
              <Button handleClick={window.globalModal.closeModal}>閉じる</Button>
              <Button
                primary={true}
                handleClick={() => {
                  location.href = `/posts/${newItem.post_id}?keyword=${newItem.name}`
                  return
                }}
                backgroundColor={COLORS.Primary}
              >
                商品ページへ
              </Button>
            </S.ButtonWrapper>
          </S.AddCartWrapper>
        </S.CartModal>
      )
    }
    window.globalModal.showModal({
      title: null,
      body: <ModalBody />,
      closeText: null,
      submitText: null,
      handleSubmit: null,
    })
  }

  const onChangePageHandler = React.useCallback(page => {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('page')) {
      searchParams.set('page', page)
    } else {
      searchParams.append('page', page)
    }
    location.href = `${location.pathname}?${searchParams.toString()}`
  }, [])

  const handleOrderChange = e => {
    const order = e.currentTarget.value
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('order')) {
      searchParams.set('order', order)
    } else {
      searchParams.append('order', order)
    }
    location.href = `${location.pathname}?${searchParams.toString()}`
  }

  const FavoriteStar = ({ isFavorite }) => (
    <div
      style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        color: '#ffcc33',
        fontSize: '24px',
      }}
    >
      <FontAwesomeIcon icon={isFavorite ? solidStar : regularStar} />
    </div>
  )

  const toggleFavorite = async item => {
    try {
      const csrfToken = document.querySelector<HTMLMetaElement>('meta[name="csrf-token"]')?.content
      const config = {
        headers: {
          'X-CSRF-Token': csrfToken,
          'Content-Type': 'application/json',
        },
      }

      let response
      if (item.is_favorite) {
        response = await axios.delete('/api/favorites', {
          data: {
            favorite: {
              user_id: props.user.id,
              post_item_id: item.id,
            },
          },
          ...config,
        })
      } else {
        response = await axios.post(
          '/api/favorites',
          {
            favorite: {
              user_id: props.user.id,
              post_item_id: item.id,
            },
          },
          config
        )
      }
      if (response.data.success) {
        const updatedItems = postItems.map(it => {
          if (it.id === item.id) {
            return { ...it, is_favorite: !it.is_favorite }
          }
          return it
        })
        setPostItems(updatedItems)
      }

      window.flashMessages.addMessage({
        text: response.data.flash.message,
        type: response.data.flash.type,
      })
    } catch (error) {
      console.error(error)
      const errorMessage =
        error.response && error.response.data ? error.response.data.error : 'エラーが発生しました'
      window.flashMessages.addMessage({
        text: errorMessage,
        type: 'error',
      })
    }
  }

  return (
    <div className="Panel">
      <div className="PanelTitle">
        <S.PanelTitle>
          <S.TitleText>お気に入り</S.TitleText>
          <S.SelectOrder>
            <Select
              required={false}
              name="order"
              label=""
              options={orderOptions()}
              defaultValue={props.search_params?.order || 'favorite_order'}
              onChangeHandler={handleOrderChange}
            />
          </S.SelectOrder>
        </S.PanelTitle>
      </div>
      <div className="PanelBody">
        <S.FavoriteIndex ref={scrollContainerRef}>
          <S.Main>
            <S.Content>
              <S.Section>
                {postItems.length > 0 ? (
                  <S.Items>
                    {postItems.map(item => {
                      return (
                        <S.Item key={item.id}>
                          <S.ItemImage onClick={() => showCartDetailModal(item)}>
                            <FavoriteStar isFavorite={item.is_favorite ?? false} />
                            {item.post_item_images.length === 0 ? (
                              <S.NoImage>No Image</S.NoImage>
                            ) : (
                              <img
                                src={item.post_item_images[0].image_url}
                                loading="lazy"
                                decoding="async"
                              />
                            )}
                          </S.ItemImage>
                          <S.ItemInfo>
                            <S.ItemCategoryName>{item.item_category_name}</S.ItemCategoryName>
                            <S.ItemName>{item.name}</S.ItemName>
                            <S.ItemDescription>{parse(item.description)}</S.ItemDescription>
                            <S.ItemPrice>{formatPrice(item.retail_price)}</S.ItemPrice>
                            <Button
                              primary={true}
                              handleClick={() => {
                                showCartDetailModal(item)
                              }}
                            >
                              商品詳細
                            </Button>
                            <S.FavoriteButton>
                              <Button
                                primary={false}
                                handleClick={() => {
                                  toggleFavorite(item)
                                }}
                              >
                                {item.is_favorite ? 'お気に入り解除' : 'お気に入り追加'}
                              </Button>
                            </S.FavoriteButton>
                          </S.ItemInfo>
                        </S.Item>
                      )
                    })}
                  </S.Items>
                ) : (
                  <div>お気に入り商品はありません。</div>
                )}
                {pagination && (
                  <Pagination
                    onChangePageHandler={onChangePageHandler}
                    currentPage={pagination.current_page}
                    prevPage={pagination.prev_page}
                    nextPage={pagination.next_page}
                    totalPages={pagination.total_pages}
                    totalCount={pagination.total_count}
                  />
                )}
              </S.Section>
            </S.Content>
          </S.Main>
        </S.FavoriteIndex>
      </div>
    </div>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.FavoriteIndex = styled.div``

S.PanelTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
`
S.TitleText = styled.h2`
  font-size: 18px;
`

S.SelectOrder = styled.div`
  margin-left: auto;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    width: 100%;
  }
`

S.CartModal = styled.div`
  min-height: 50%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  > p {
    width: 100%;
  }
`

S.CartItemName = styled.h3`
  flex-grow: 0;
  flex-basis: auto;
  font-size: 20px;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    font-size: 18px;
  }
`

S.AllergyImageWrap = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center:
`

S.ModalAllergyImage = styled.div`
  width: 65px;
  margin: 4px;
  flex-grow: 0;
  flex-basis: auto;
  > img {
    height: 60px;
    width: 60px;
    object-fit: contain;
  }
`

S.ModalItemImage = styled.div`
  display: flex;
  width: 100%;
  min-height: 240px;
  margin-bottom: 16px;
  flex-grow: 0;
  flex-basis: auto;
  > img {
    max-height: 320px;
    min-height: 240px;
    width: 100%;
    object-fit: contain;
    border-radius: 4px;

    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      max-height: 240px;
    }
  }
`

S.ModalItemDescription = styled.p`
  margin-bottom: 16px;
  flex-grow: 1;
  flex-basis: 88px;
  overflow-y: auto;
  font-size: 14px;
  color: #8b8b8b;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    font-size: 12px;
  }
`

S.ModalItemJan = styled.p`
  margin-bottom: 16px;
  font-size: 14px;
  color: #8b8b8b;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    font-size: 12px;
  }
`

S.Price = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
`

S.AddCartWrapper = styled.div`
  flex-grow: 0;
  flex-basis: auto;
`

S.ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 58px;
  > button {
    height: 100%;
    font-size: 18px;
    margin: 0 12px;
    padding: 8px 30px;
  }
  > button:disabled {
    background-color: #f3f3f3;
    color: #fff;
  }
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    height: 40px;
    > button {
      font-size: 12px;
      padding: 8px;
    }
  }
`

S.FavoriteButton = styled.div`
  width: 100%;
  margin-top: 16px;
  margin-left: 0px;

  button {
    width: 100%;
  }
`

S.Main = styled.div`
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
`

S.Content = styled.div`
  flex: 1;
  line-height: 1.5;
`

S.Section = styled.section`
  padding: 24px 0 24px;

  > h3 {
    font-size: 20px;
  }
`

S.Items = styled.section`
  display: flex;
  flex-wrap: wrap;
`

S.Item = styled.div`
  position: relative;
  width: 33.33333%;
  margin-bottom: 48px;
  overflow: hidden;
  padding: 0 15px;
  display: table;

  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    width: 50%;
    padding: 0 5px;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`
S.ItemDisabled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff99;
`

S.ItemInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 0;

  > button {
    width: 100%;
  }
`
S.ItemName = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  width: 100%;
  height: 40px;
  margin-top: 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`

S.ItemCategoryName = styled.p`
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  height: 40px;
  margin-top: 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`

S.ItemDescription = styled.p`
  width: 100%;
  font-size: 14px;
  height: 66px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  color: #8b8b8b;
  margin-top: 8px;
`

S.ItemPrice = styled.p`
  width: 100%;
  text-align: right;
  font-size: 22px;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 8px;
`

S.RetailPrice = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
`

S.ItemImage = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 160px;
  max-height: 250px;
  cursor: pointer;

  > img {
    width: 100%;
    height: 240px;
    object-fit: cover;
    border-radius: 4px;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      height: 180px;
    }
  }

  &:hover {
    opacity: 0.75;
  }
`

S.ItemNoImageWrap = styled.div`
  height: 240px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    height: 180px;
  }
`

S.NoImage = styled.div`
  width: 100%;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    height: 180px;
  }
`

S.Search = styled.div`
  margin-bottom: 20px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    margin-top: 20px;
  }
`

S.SearchForm = styled.div`
  display: flex;
  align-items: center;

  input {
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      width: auto;
    }
  }
`

S.ButtonSearch = styled.div`
  text-align: center;
  margin-left: 10px;
`

S.Close = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`

export default FavoriteIndex
