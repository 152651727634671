import { once } from 'lodash'
import * as React from 'react'
import { Waypoint } from 'react-waypoint'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IPost } from '../../../../core/interfaces'
import { postService } from '../../../../core/services'
import {
  BREAKPOINT_TABLET_LARGE,
  BREAKPOINT_TABLET_MOBILE,
  COLORS,
} from '../../../../static/constants'
import { Score, Spinner } from '../../../atoms'

interface IProps {
  post: IPost
  scrollContainerRef: React.MutableRefObject<null | HTMLElement>
}

const RelatedPosts: React.FC<IProps> = ({ post, scrollContainerRef }) => {
  const [relatedPosts, setRelatedPosts] = React.useState(null)
  const getRelatedPosts = async () => {
    const { relatedPosts: loaded } = await postService.getRelatedPosts(post)
    setRelatedPosts(loaded)
  }

  return (
    <Waypoint onEnter={once(getRelatedPosts)}>
      <S.RelatedPosts>
        <h3>{I18n.t('post.related_posts')}</h3>

        {relatedPosts === null ? (
          <S.Loading>
            <Spinner />
          </S.Loading>
        ) : relatedPosts.length > 0 ? (
          <S.Inner>
            {relatedPosts.map(relatedPost => (
              <S.Item key={relatedPost.id} href={`/posts/${relatedPost.slug}`}>
                <S.Image>
                  {relatedPost.post_image && relatedPost.post_image.image_url ? (
                    <img
                      src={relatedPost.post_image.image_url}
                      alt=""
                      loading="lazy"
                      decoding="async"
                    />
                  ) : (
                    <S.NoImage>No Image</S.NoImage>
                  )}
                </S.Image>
                <S.Info>
                  <S.Name>{relatedPost.name}</S.Name>
                  <S.Price>
                    <span>¥ {relatedPost.price}</span> {I18n.t('generic.per_day')}
                  </S.Price>
                </S.Info>
              </S.Item>
            ))}
          </S.Inner>
        ) : null}
      </S.RelatedPosts>
    </Waypoint>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.RelatedPosts = styled.div`
  max-width: 1088px;
  margin: 0 auto 48px;
  padding: 0 24px 48px;
  overflow: hidden;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    padding: 0 12px 48px;
  }

  > h3 {
    font-size: 20px;
    padding-top: 32px;
    border-top: solid 1px ${COLORS.Border};
  }
`

S.Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 32px -12px 0;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    display: block;
  }
`

S.Loading = styled.div`
  margin: 32px 0;
`

S.Item = styled.a`
  width: calc(33.33% - 24px);
  margin: 12px;
  display: flex;
  flex-direction: column;
  color: ${COLORS.Text};
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    width: auto;
    flex-direction: row;
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    flex-direction: column;
  }
`

S.NoImage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
`

S.Name = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-top: 8px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    font-size: 14px;
  }
`

S.Price = styled.p`
  margin-top: 8px;
  color: #7c7c7c;
  font-size: 12px;

  > span {
    font-size: 14px;
    font-weight: bold;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      font-size: 12px;
    }
  }
`

S.Image = styled.div`
  width: 100%;
  height: 180px;
  border-radius: 3px;
  overflow: hidden;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    width: 180px;
    height: 120px;
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    width: 100%;
    height: 180px;
  }

  > img {
    width: 100%;
    height: inherit;
    object-fit: cover;
  }
`

S.Info = styled.div`
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    padding-left: 16px;
    flex: 1;
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    padding-left: 0;
  }
`

export default RelatedPosts
