import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IUser, IWindow } from '../../../../core/interfaces'
import { reservationService } from '../../../../core/services'
import { BREAKPOINT_TABLET_SMALL, COLORS } from '../../../../static/constants'
import { Button } from '../../../atoms'
import CancelModal from './CancelModal'
import ReceiptModal from './ReceiptModal'

declare var window: IWindow

interface IProps {
  heading: any
  user: IUser
  receiptId: any

  purchaseStatementId: any
  reservationId: number
  reservation: any
}

const Info: React.FC<IProps> = ({
  heading,
  user,
  reservationId,
  receiptId: initialReceiptId,
  purchaseStatementId: initialPurchaseStatementId,
  reservation: initialReservation,
}) => {
  const formRef = React.useRef(null)
  const [reservation, setReservation] = React.useState<any>(initialReservation)
  const [receiptId, setReceiptId] = React.useState(initialReceiptId || null)
  const [purchaseStatementId, setPurchaseStatementId] = React.useState(
    initialPurchaseStatementId || null
  )

  const currentUserIsHost = reservation?.user?.id === user.id

  const showCancelModal = async id => {
    const { price } = await reservationService.refundAmountReservation(id)

    window.globalModal.showModal({
      title: I18n.t('cancel.cancel_reservation'),
      body: (
        <CancelModal
          price={price}
          formRef={formRef}
          reservationId={id}
          setReservation={setReservation}
        />
      ),
      closeText: I18n.t('generic.label_no'),
      submitText: I18n.t('generic.label_yes'),
      handleSubmit: () => formRef.current.handleFormSubmit(),
    })
  }

  const approveReservation = React.useCallback(async id => {
    const { reservation: changedReservation, flush } = await reservationService.approveReservation(
      id,
      {}
    )

    setReservation(changedReservation)
    window.flashMessages.addMessage({ text: flush.message, type: flush.type })
  }, [])

  const declineReservation = React.useCallback(async id => {
    const { reservation: changedReservation, flush } = await reservationService.declineReservation(
      id
    )

    setReservation(changedReservation)
    window.flashMessages.addMessage({ text: flush.message, type: flush.type })
  }, [])

  const createReceiptFormRef = React.useRef(null)
  const showCreateReceiptModal = () => {
    if (receiptId) {
      return
    }

    window.globalModal.showModal({
      title: I18n.t('receipt.create_receipt'),
      body: (
        <ReceiptModal
          reservationId={reservationId}
          createReceiptFormRef={createReceiptFormRef}
          setReceiptId={setReceiptId}
        />
      ),
      closeText: I18n.t('generic.cancel'),
      submitText: I18n.t('generic.create'),
      handleSubmit: () => createReceiptFormRef.current.handleFormSubmit(),
    })
  }

  const renderWorkFlowStateButtons = React.useCallback(() => {
    if (!reservation.next_work_flow_state) {
      return null
    }

    return reservation.next_work_flow_state.map((state, index) => {
      let button = null
      switch (state) {
        case 'approved':
          button = (
            <Button
              key={`${reservation.id}-${index}`}
              small={true}
              handleClick={() => approveReservation(reservation.id)}
            >
              {I18n.t('generic.approve')}
            </Button>
          )
          break
        case 'declined':
          button = (
            <Button
              key={`${reservation.id}-${index}`}
              small={true}
              handleClick={() => declineReservation(reservation.id)}
            >
              {I18n.t('generic.decline')}
            </Button>
          )
          break
        case 'canceled':
          button = (
            <Button
              key={`${reservation.id}-${index}`}
              small={true}
              handleClick={() => showCancelModal(reservation.id)}
            >
              {I18n.t('cancel.cancel_reservation')}
            </Button>
          )
          break
      }
      return button
    })
  }, [reservation])

  return (
    <S.Info>
      <S.Host>
        <h1>{heading}</h1>
        <div className="Host_Avator">
          <div className="Host_AvatorImage">
            <img src={user.avatar_url} alt="" loading="lazy" decoding="async" />
          </div>
          <div className="Host_AvatorInfo">
            <div className="Host_AvatorName">{user.username}</div>
            <p className="Host_AvatorBio">{user.bio}</p>
          </div>
        </div>
      </S.Host>
      {reservation && (
        <>
          <S.Reservation>
            <h1>{I18n.t('reservation.about_reservation')}</h1>
            <dl>
              <dt>{I18n.t('generic.status')}</dt>
              <dd>{reservation.workflow_state}</dd>
              <dt>{I18n.t('post.post_name')}</dt>
              <dd>{reservation.post_name}</dd>
              <dt>{I18n.t('generic.price')}</dt>
              <dd>{reservation.price}</dd>
            </dl>
            {reservation.next_work_flow_state && (
              <S.Buttons>
                {renderWorkFlowStateButtons()}
                {!currentUserIsHost && reservation.receipt_accessible && (
                  <Button handleClick={showCreateReceiptModal}>
                    {receiptId ? (
                      <a href={`/receipts/${receiptId}`} target="_blank">
                        {I18n.t('generic.receipt')}
                      </a>
                    ) : (
                      I18n.t('generic.receipt')
                    )}
                  </Button>
                )}
              </S.Buttons>
            )}
          </S.Reservation>
        </>
      )}
    </S.Info>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Info = styled.div`
  display: block;
`

S.Host = styled.div`
  width: 320px;
  padding: 32px 16px 24px;
  border: solid 1px ${COLORS.Border};
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    padding: 16px 12px 12px;
    width: auto;
  }

  > h1 {
    font-size: 18px;
    margin-bottom: 24px;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      font-size: 16px;
      margin-bottom: 12px;
    }
  }

  .Host_Avator {
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      display: flex;
    }
  }

  .Host_AvatorInfo {
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      flex: 1;
      margin-left: 16px;
    }
  }

  .Host_AvatorName {
    margin-top: 8px;
    text-align: center;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      text-align: left;
    }
  }

  .Host_AvatorBio {
    margin-top: 16px;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      font-size: 14px;
    }
  }

  .Host_AvatorImage {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;

    > img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }
`

S.Buttons = styled.div`
  display: flex;
  margin-top: 12px;

  .Button {
    width: 50%;
    height: 40px;
  }
`

S.Reservation = styled.div`
  width: 320px;
  margin-top: 12px;
  padding: 32px 16px 24px;
  border: solid 1px ${COLORS.Border};
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    padding: 16px 12px 12px;
    width: auto;
  }

  > h1 {
    font-size: 18px;
    margin-bottom: 24px;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      font-size: 16px;
      margin-bottom: 12px;
    }
  }

  > dl {
    font-size: 15px;

    dt {
      font-weight: bold;
    }

    dd + dt {
      margin-top: 12px;
    }
  }
`

export default Info
