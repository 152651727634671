import { IPostItem } from '../../../../../../core/interfaces'

// Form が指定した fields の値しかサーバーへ submit しない作りになっているため、
// 商品のオプションフォーム部分が動的であるため、動的に fields に Name を格納できるような関数を作成
// 商品オブジェクトとfieldsを受け取ってオブジェクトのIndexをnameに埋め込みfieldsを生成
// 新たに submit したい input を増やした場合こちらに追加する

const buildFormInputName = (selectItem: IPostItem, fields: any) => {
  // 商品を変更しても'post_options_attributes' 以下の fields が残り続け Submit ができなくなるため
  // post_option がない場合は 'post_options_attributes' 以下の fields をすべて削除する
  if (!selectItem.post_options || selectItem.post_options.length === 0) {
    Object.keys(fields).forEach(key => {
      if (key.includes('post_options_attributes')) {
        delete fields[key]
      }
    })
    return fields
  }

  // post_option(オプション単位) ex) スープの種類
  selectItem.post_options.map((option, index) => {
    const basePostOptionName = `post_options_attributes[${index}]`

    if (option.id) {
      // option.idがある場合に追加（編集時のみ追加、新規オプション作成時には追加しない）
      Object.assign(fields, { [`${basePostOptionName}[id]`]: `${basePostOptionName}[id]` })
    } else {
      delete fields[`${basePostOptionName}[id]`]
    }
    if (selectItem.id) {
      // selectItem.idがある場合に追加（編集時のみ追加、新規商品作成時には追加しない）
      Object.assign(fields, {
        [`${basePostOptionName}[post_item_id]`]: `${basePostOptionName}[post_item_id]`,
      })
    }
    if (!option.deleted_at) {
      // 削除フラグがない場合に追加
      Object.assign(fields, {
        [`${basePostOptionName}[title]`]: `${basePostOptionName}[title]`,
        [`${basePostOptionName}[description]`]: `${basePostOptionName}[description]`,
        [`${basePostOptionName}[is_required]`]: `${basePostOptionName}[is_required]`,
        [`${basePostOptionName}[is_multiple]`]: `${basePostOptionName}[is_multiple]`,
      })
    }
    // 削除フラグは常に送る
    Object.assign(fields, {
      [`${basePostOptionName}[deleted_at]`]: `${basePostOptionName}[deleted_at]`,
    })

    // option_item(オプションアイテム単位) ex) 甘口、中辛、激辛
    option.option_items.map((option_item, child_index) => {
      const baseOptionItemName = `${basePostOptionName}[option_items_attributes][${child_index}]`
      if (option_item.id) {
        // option_item.idがある場合に追加(編集時のみ追加、新規オプションアイテム作成時には追加しない)
        Object.assign(fields, { [`${baseOptionItemName}[id]`]: `${baseOptionItemName}[id]` })
      }
      if (option.id) {
        // option.idがある場合に追加（編集時のみ追加、新規オプション作成時には追加しない）
        Object.assign(fields, {
          [`${baseOptionItemName}[post_option_id]`]: `${baseOptionItemName}[post_option_id]`,
        })
      }
      if (!option_item.deleted_at) {
        // 削除フラグがない場合に追加
        Object.assign(fields, {
          [`${baseOptionItemName}[title]`]: `${baseOptionItemName}[title]`,
          [`${baseOptionItemName}[price]`]: `${baseOptionItemName}[price]`,
          [`${baseOptionItemName}[retail_price]`]: `${baseOptionItemName}[retail_price]`,
          [`${baseOptionItemName}[settlement_price]`]: `${baseOptionItemName}[settlement_price]`,
          [`${baseOptionItemName}[tax_rate]`]: `${baseOptionItemName}[tax_rate]`,
          [`${baseOptionItemName}[quantity]`]: `${baseOptionItemName}[quantity]`,
          [`${baseOptionItemName}[is_quantity_limited]`]: `${baseOptionItemName}[is_quantity_limited]`,
          [`${baseOptionItemName}[quantity_limited_status]`]: `${baseOptionItemName}[quantity_limited_status]`,
          [`${baseOptionItemName}[jan]`]: `${baseOptionItemName}[jan]`,
        })
      }
      // 削除フラグは常に送る
      Object.assign(fields, {
        [`${baseOptionItemName}[deleted_at]`]: `${baseOptionItemName}[deleted_at]`,
      })
    })
  })
  return fields
}

export default buildFormInputName
