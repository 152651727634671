import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { COLORS, THEME_COLOR_VARIABLE_NAME } from '../../../../static/constants'
import { Score } from '../../../atoms'

interface IProps {
  reviews: { data: any[]; pagination: any }
  count: number
}

const Reviews: React.FC<IProps> = ({ reviews, count }) => (
  <S.Reviews>
    <h1>{I18n.t('generic.review_count', { count })}</h1>
    <div className="ReviewList">
      {reviews.data.map(review => (
        <div key={review.id} className="ReviewList_Item">
          <div className="score">
            <Score score={review.rating} />
          </div>
          <div className="name">
            <a href={`/posts/${review.reviewable?.slug}`} target="_blank">
              {review.reviewable?.name}
            </a>
          </div>
          <div className="date">{review.review_date}</div>
          <div className="body">{review.body}</div>
          <div className="Reviewer">
            <div className="avator">
              <img src={review.reviewer.avatar_url} alt="" loading="lazy" decoding="async" />
            </div>
            <div className="info">
              <div className="name">{review.reviewer.username}</div>
              <div className="date">
                {I18n.t('generic.joined_in', { date: review.reviewer.joined_date })}
              </div>
            </div>
          </div>
          {review.review_reply && (
            <div className="ReviewReply">
              <div className="header">
                {I18n.t('generic.response_from', { user: review.review_reply.user.username })}
              </div>
              <div className="body">{review.review_reply.body}</div>
            </div>
          )}
        </div>
      ))}
    </div>
  </S.Reviews>
)

const S: { [key: string]: AnyStyledComponent } = {}

S.Reviews = styled.div`
  padding: 0 24px;
  border-bottom: solid 1px ${COLORS.Border};

  > h1 {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .ReviewList_Item {
    padding: 20px 0;
    border-top: solid 1px ${COLORS.Border};

    .name {
      margin-top: 12px;
    }

    .name > a {
      color: var(${THEME_COLOR_VARIABLE_NAME});
    }

    .date {
      margin-top: 8px;
      color: #888;
      font-size: 12px;
    }

    .body {
      margin-top: 16px;
    }
  }

  .Reviewer {
    display: flex;
    margin-top: 12px;

    .avator {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;

      > img {
        width: inherit;
        height: inherit;
        object-fit: cover;
      }
    }

    .info {
      margin-left: 12px;
    }

    .name {
      font-weight: bold;
    }

    .date {
      margin-top: 4px;
      font-size: 12px;
    }
  }

  .ReviewReply {
    margin-top: 16px;
    padding: 24px;
    background: #f7f7f7;

    .header {
      font-size: 12px;
      color: #888;
    }
  }
`

export default Reviews
