import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import * as constants from '../../../../static/constants'
import { Button, Panel } from '../../../atoms'
import { Form } from '../../../molecules'
import Nav, { ActiveTypes } from '../Nav'

interface IProps {
  model: ActiveTypes
  title: string
  fields: any
  indexLink: string
  formItems: any
  isSubmitting?: boolean
  handleSubmit(initialValues, values): void
}

const AdminLayoutEdit: React.FC<IProps> = props => {
  return (
    <S.AdminLayoutEdit>
      <Nav active={props.model} />
      <S.IndexMain>
        <Panel title={props.title}>
          <Form fields={props.fields} handleSubmit={props.handleSubmit}>
            {props.formItems}
            <S.Buttons>
              <Button>
                <a href={props.indexLink}>
                  <span className="material-icons">keyboard_arrow_left</span>
                  {I18n.t('generic.back_to_index')}
                </a>
              </Button>
              <Button primary={true} disabled={props.isSubmitting}>
                {I18n.t('generic.update')}
              </Button>
            </S.Buttons>
          </Form>
        </Panel>
      </S.IndexMain>
    </S.AdminLayoutEdit>
  )
}
const S: { [key: string]: AnyStyledComponent } = {}
S.AdminLayoutEdit = styled.div`
  display: flex;
  @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
    flex-direction: column;
  }
  height: calc(100vh - ${constants.HEADER_HEIGHT}px);
  margin: 0 auto;
  background-color: #f6f8f9;
  .InputText + .InputText,
  .InputText + .CheckBox,
  .CheckBox + .CheckBox {
    margin-top: 24px;
  }
`
S.IndexMain = styled.section`
  flex: 1;
  padding: 16px;
  overflow: auto;
`
S.Buttons = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 48px;

  .Button[type='submit'] {
    width: 160px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      width: 120px;
    }
  }

  .Button[type='button'] {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      position: relative;
    }
    .material-icons {
      margin-left: -10px;
    }
  }
`

export default AdminLayoutEdit
