export function isTouchDevice() {
  const regexp = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
  return window.navigator.userAgent.search(regexp) !== -1
}

export function getGmoPublicKey(): any {
  const element: any = document.querySelector('meta[name=gmo_pg_shop_id]')

  return element ? element.content : ''
}
