import * as React from 'react'
import SliderMenu, { IMenuPanel } from '../molecules/SliderMenu'

const MENU_PANELS: IMenuPanel[] = []

const GenreSelect: React.FC<{}> = props => {
  return <SliderMenu title="カテゴリを選択" link={0} menuPanels={MENU_PANELS} />
}

export default GenreSelect
