import * as React from 'react'
import Modal from 'react-modal'
import styled, { AnyStyledComponent } from 'styled-components'
import {
  BREAKPOINT_TABLET_LARGE,
  BREAKPOINT_TABLET_MOBILE,
  BREAKPOINT_TABLET_SMALL,
  COLORS,
  THEME_COLOR_VARIABLE_NAME,
} from '../../static/constants'
import Spinner from '../atoms/Spinner'

const DeliveryAreaModal = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [zipcode, setZipcode] = React.useState('')
  const [inDeliveryArea, setInDeliveryArea] = React.useState(null)

  const checkDeliveryArea = async () => {
    setLoading(true)
    const response = await fetch(`/api/check_delivery_area?zipcode=${zipcode}`)
    const data = await response.json()
    setInDeliveryArea(data.in_delivery_area)
    setLoading(false)
    setModalIsOpen(true)
  }

  const handleZipcodeChange = e => {
    setZipcode(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    checkDeliveryArea()
  }

  return (
    <DeliveryAreaWrapper className="SearchPanel">
      <form onSubmit={handleSubmit} className="Form">
        <div className="DeliveryArea_FormItem">
          {/* <div className="DeliveryArea_Label">
            <label>郵便番号から配送エリアを確認</label>
          </div> */}
          <div className="DeliveryArea_Input">
            <input
              type="text"
              value={zipcode}
              placeholder={'郵便番号を入力（ハイフンを除く7桁）'}
              onChange={handleZipcodeChange}
            />
          </div>
          <div className="DeliveryArea_Button">
            <S.Buttons>
              <button type="submit" className="Button">
                確認
              </button>
            </S.Buttons>
          </div>
        </div>
      </form>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          },
          content: {
            position: 'static',
            margin: '24px',
          },
        }}
      >
        {loading ? (
          <div>
            <Spinner />
          </div>
        ) : (
          inDeliveryArea !== null && <div style={{ padding: '36px' }}>{inDeliveryArea}</div>
        )}
        <S.Buttons>
          <button className="Button" onClick={() => setModalIsOpen(false)}>
            閉じる
          </button>
        </S.Buttons>
      </Modal>
    </DeliveryAreaWrapper>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

const DeliveryAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 12px;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    max-width: none;
    width: 100%;
  }

  .Form {
    width: 100%;
    display: flex;
    justify-content: center;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      flex-wrap: wrap;
    }
  }

  .DeliveryArea_FormItem {
    width: 100%;
    margin: 24px 10px 0;
    padding: 0 50px;
    display: flex;
    justify-content: center;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      display: block;
      margin: 24px 0 0;
      padding: 0;
    }

    .DeliveryArea_Label {
      width: 30%;
      padding-right: 20px;
      @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
        width: 100%;
        padding-right: 0;
        padding-bottom: 20px;
      }
    }

    .DeliveryArea_Input {
      width: 50%;
      padding-right: 20px;
      @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
        width: 100%;
        padding-right: 0;
        padding-bottom: 20px;
      }
      input {
        display: block;
        width: 100%;
        padding: 8px 12px;
        border: solid 1px #eaedef;
        border-radius: 4px;
        font-size: 15px;
        -webkit-transition: border 0.2s ease;
        transition: border 0.2s ease;
        outline: none;
        background: #fff;
      }
    }

    .DeliveryArea_Button {
      width: 20%;
      @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
        width: 100%;
        padding-right: 0;
      }
    }
  }
`

S.Buttons = styled.div`
  text-align: center;

  > p {
    display: block;
    margin: 16px auto 0;

    a {
      color: var(${THEME_COLOR_VARIABLE_NAME});
      text-decoration: underline;
    }
  }

  .Button {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 35px;
    font-weight: bold;
    font-size: 16px;
    padding: 0 16px;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    line-height: 1;
    background-color: var(--theme-color);
    color: #fff;
    border: none;
  }
`

export default DeliveryAreaModal
