import ClassNames from 'classnames'
import I18n from 'core/i18n'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { createTrue } from 'typescript'
import { IOptionItem } from '../../core/interfaces'
import { COLORS } from '../../static/constants'
import { Button, CheckBox, InputText, InputTextV2, Select } from '../atoms'

const urlParams = new URLSearchParams(window.location.search)

interface IOptionFormProps {
  index: number
  optionItems: IOptionItem[]
  addOptionItem: () => void
  errors: any
  removeOptionItem(option_index: number, item_index: number): void
}

interface IErrors {
  [key: string]: string | null
}

const OptionItemForm: React.FC<IOptionFormProps> = props => {
  function toBoolean(data) {
    return data.length > 0 && data.toLowerCase() === 'true'
  }

  // オプション項目ごとに配列でアイテムの在庫上限数フラグを配列で管理
  const limitedItemsArray = () => {
    const limitedArray = []
    props.optionItems.map((optionItem, index) => {
      limitedArray.push(toBoolean(optionItem.is_quantity_limited))
    })
    return limitedArray
  }

  // 在庫数上限フラグON/OFF時に在庫数disabled制御処理
  const [limitedItems, setLimitedItems] = React.useState<boolean[]>(limitedItemsArray())
  const setItemQuantityLimitedStatus = (event, optionItemIndex) => {
    const checked = event.target.checked
    setLimitedItems(prev => {
      const newState = [...prev]
      newState[optionItemIndex] = Boolean(checked)
      return newState
    })
  }

  const [price, setPrice] = React.useState<string[]>(
    props.optionItems.map(optionItem => (optionItem.price ? String(optionItem.price) : '0'))
  )
  const [retailPrice, setRetailPrice] = React.useState<string[]>(
    props.optionItems.map(optionItem =>
      optionItem.retail_price ? String(optionItem.retail_price) : '0'
    )
  )
  const [settlementPrice, setSettlementPrice] = React.useState<string[]>(
    props.optionItems.map(optionItem =>
      optionItem.settlement_price ? String(optionItem.settlement_price) : '0'
    )
  )
  const [taxRate, setTaxRate] = React.useState<string[]>(
    props.optionItems.map(optionItem => optionItem.tax_rate)
  )
  const [calcTaxRate, setCalcTaxRate] = React.useState<number[]>(
    props.optionItems.map(optionItem => (optionItem.tax_rate === 'ten' ? 1.1 : 1.08))
  )

  // 税率など価格関連
  React.useEffect(() => {
    props.optionItems.map((optionItem, index) => {
      const inputPrice = price[index] ? price[index] : 0
      const inputCalcTaxRate = calcTaxRate[index] ? calcTaxRate[index] : 1.08
      calcTaxPrice(inputPrice, index, inputCalcTaxRate)
    })
  }, [calcTaxRate, price, taxRate])

  const handleTaxRateChange = (e, itemIndex) => {
    const value = e.target.value
    setTaxRate(prevTaxRates => {
      const newTaxRates = [...prevTaxRates]
      newTaxRates[itemIndex] = value
      return newTaxRates
    })
    setCalcTaxRate(prevCalcTaxRates => {
      const newCalcTaxRates = [...prevCalcTaxRates]
      newCalcTaxRates[itemIndex] = value === 'ten' ? 1.1 : 1.08
      return newCalcTaxRates
    })
  }

  const customCeil = value => {
    return value % 1 === 0 ? value : Math.ceil(value)
  }
  const customFloor = value => {
    return value % 1 === 0 ? value : Math.floor(value)
  }

  const handlePriceChange = (e, itemIndex) => {
    const priceNoTax = parseFloat(e.target.value) || 0
    setPrice(prevPrices => {
      const newPrices = [...prevPrices]
      newPrices[itemIndex] = Number(priceNoTax).toString()
      return newPrices
    })
  }

  const calcTaxPrice = (inputPrice, itemIndex, inputCalcTaxRate) => {
    let priceNoTax
    priceNoTax = Number(inputPrice)
    const priceWithTax = priceNoTax * inputCalcTaxRate
    const priceWithTaxCeil = customCeil(parseFloat(priceWithTax.toFixed(2)))
    const priceWithTaxFloor = customFloor(parseFloat(priceWithTax.toFixed(2)))
    setRetailPrice(prevPrices => {
      const newPrices = [...prevPrices]
      newPrices[itemIndex] = Number(priceWithTaxCeil).toString()
      return newPrices
    })
    setSettlementPrice(prevPrices => {
      const newPrices = [...prevPrices]
      newPrices[itemIndex] = Number(priceWithTaxFloor).toString()
      return newPrices
    })
  }

  return (
    <S.Inner>
      <S.Title className="FormLabel">オプションアイテム設定</S.Title>
      {props.optionItems.map((optionItem, index) => {
        return (
          <div key={index} style={{ display: `${optionItem.deleted_at ? 'none' : 'block'}` }}>
            <S.OptionItemFormWrapper>
              <input
                type="hidden"
                id={`post_options_attributes[${props.index}][option_items_attributes][${index}][id]`}
                name={`post_options_attributes[${props.index}][option_items_attributes][${index}][id]`}
                readOnly={true}
                value={optionItem.id}
              />
              <input
                type="hidden"
                id={`post_options_attributes[${props.index}][option_items_attributes][${index}][post_option_id]`}
                name={`post_options_attributes[${props.index}][option_items_attributes][${index}][post_option_id]`}
                readOnly={true}
                value={optionItem.post_option_id}
              />
              <input
                type="hidden"
                id={`post_options_attributes[${props.index}][option_items_attributes][${index}][deleted_at]`}
                name={`post_options_attributes[${props.index}][option_items_attributes][${index}][deleted_at]`}
                readOnly={true}
                value={optionItem.deleted_at ?? ''}
              />
              <S.AddButtonWrap>
                <S.Title className="FormLabel">{`オプションアイテム${index + 1}`}</S.Title>
                <Button small={true} handleClick={() => props.removeOptionItem(props.index, index)}>
                  アイテム削除
                </Button>
              </S.AddButtonWrap>
              <S.FormItem>
                <InputText
                  required={optionItem.deleted_at ? false : true}
                  name={`post_options_attributes[${props.index}][option_items_attributes][${index}][title]`}
                  label={`アイテム${index + 1} 名`}
                  defaultValue={optionItem.title}
                  error={
                    props.errors[
                      `post_options_attributes[${props.index}][option_items_attributes][${index}][title]`
                    ]
                  }
                />
              </S.FormItem>
              <S.FormItem>
                <Select
                  required={optionItem.deleted_at ? false : true}
                  name={`post_options_attributes[${props.index}][option_items_attributes][${index}][tax_rate]`}
                  label={`アイテム${index + 1} 消費税率`}
                  options={[
                    { label: '8%', value: 'eight' },
                    { label: '10%', value: 'ten' },
                  ]}
                  initialText={I18n.t('meta.post.set_tax_rate')}
                  defaultValue={optionItem.tax_rate ? optionItem.tax_rate.toString() : ''}
                  onChangeHandler={e => handleTaxRateChange(e, index)}
                  width={200}
                />
              </S.FormItem>
              <S.FormItem>
                <InputTextV2
                  required={optionItem.deleted_at ? false : true}
                  name={`post_options_attributes[${props.index}][option_items_attributes][${index}][price]`}
                  label={`アイテム${index + 1} 税抜価格`}
                  value={price[index]}
                  inputType="number"
                  error={
                    props.errors[
                      `post_options_attributes[${props.index}][option_items_attributes][${index}][price]`
                    ]
                  }
                  onChangeHandler={e => handlePriceChange(e, index)}
                />
              </S.FormItem>
              <S.FormItem>
                <InputTextV2
                  required={false}
                  name={`post_options_attributes[${props.index}][option_items_attributes][${index}][retail_price]`}
                  label={`アイテム${index + 1} 表示価格`}
                  value={retailPrice[index]}
                  error={
                    props.errors[
                      `post_options_attributes[${props.index}][option_items_attributes][${index}][retail_price]`
                    ]
                  }
                  readonly={true}
                />
                <InputTextV2
                  required={false}
                  name={`post_options_attributes[${props.index}][option_items_attributes][${index}][settlement_price]`}
                  label={`アイテム${index + 1} 決済価格`}
                  value={settlementPrice[index]}
                  error={
                    props.errors[
                      `post_options_attributes[${props.index}][option_items_attributes][${index}][settlement_price]`
                    ]
                  }
                  readonly={true}
                />
              </S.FormItem>
              <S.FormItem>
                <InputText
                  required={optionItem.deleted_at ? false : true}
                  name={`post_options_attributes[${props.index}][option_items_attributes][${index}][jan]`}
                  label={`アイテム${index + 1} JANコード`}
                  defaultValue={optionItem.jan}
                  error={
                    props.errors[
                      `post_options_attributes[${props.index}][option_items_attributes][${index}][jan]`
                    ]
                  }
                />
              </S.FormItem>

              <S.FormItem>
                <CheckBox
                  label={`アイテム${index + 1} 在庫設定`}
                  name={`post_options_attributes[${props.index}][option_items_attributes][${index}][is_quantity_limited]`}
                  defaultChecked={Boolean(limitedItems[index])}
                  onChangeHandler={e => setItemQuantityLimitedStatus(e, index)}
                />
              </S.FormItem>
              <S.FormItem>
                <Select
                  required={optionItem.deleted_at ? false : Boolean(limitedItems[index])}
                  label={`アイテム${index + 1} 在庫ステータス`}
                  name={`post_options_attributes[${props.index}][option_items_attributes][${index}][quantity_limited_status]`}
                  options={[
                    { label: '24H', value: 'one_day' },
                    { label: 'トータル', value: 'total' },
                  ]}
                  defaultValue={
                    optionItem.quantity_limited_status
                      ? optionItem.quantity_limited_status.toString()
                      : ''
                  }
                  width={200}
                  disabled={!Boolean(limitedItems[index])}
                />
              </S.FormItem>
              <S.FormItem>
                <InputText
                  required={optionItem.deleted_at ? false : Boolean(limitedItems[index])}
                  name={`post_options_attributes[${props.index}][option_items_attributes][${index}][quantity]`}
                  inputType="number"
                  label={`アイテム${index + 1} 在庫数`}
                  defaultValue={optionItem.quantity ? String(optionItem.quantity) : '0'}
                  readonly={!Boolean(limitedItems[index])}
                  error={
                    props.errors[
                      `post_options_attributes[${props.index}][option_items_attributes][${index}][quantity]`
                    ]
                  }
                />
                {Boolean(limitedItems[index]) && (
                  <>
                    <S.Title className="FormLabel">{I18n.t('post.remaining_stock')}</S.Title>
                    <>{optionItem.remaining_stock}</>
                  </>
                )}
              </S.FormItem>
            </S.OptionItemFormWrapper>
          </div>
        )
      })}
      <S.RemoveButtonWrap>
        <Button small={true} handleClick={props.addOptionItem}>
          アイテム追加
        </Button>
      </S.RemoveButtonWrap>
    </S.Inner>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Inner = styled.div`
  margin: 24px 0px;
  padding-bottom: 24px;
  border-bottom: solid 1px ${COLORS.Border};
  button {
  }
`
S.AddButtonWrap = styled.div`
  margin: 24px 0px;
  display: flex;
  align-items: center;
`

S.RemoveButtonWrap = styled.div`
  button {
    margin: 24px 0 auto 40px;
    display: block;
  }
`

S.OptionItemButtonWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  & + & {
    margin-top: 24px;
  }
`

S.FormItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  & + & {
    margin-top: 24px;
  }
`
S.Title = styled.p`
  padding-left: 16px;
  width: 240px;
  font-size: 15px;
  font-weight: bold;
`

S.OptionItemFormWrapper = styled.div`
  padding-left: 24px;
  p {
    width: 216px;
    padding-left: 16px;
    font-size: 15px;
    font-weight: bold;
  }
  .FormItem {
  }
`
S.Hidden = styled.div`
  display: none;
`

export default OptionItemForm
