import { Button, Pagination, Panel } from 'components/atoms'
import { utilService } from 'core/services'
import moment from 'moment'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { formatPrice } from 'utils/number'
import I18n from '../../../core/i18n'
import {
  BREAKPOINT_TABLET_MOBILE,
  COLORS,
  THEME_COLOR_VARIABLE_NAME,
} from '../../../static/constants'
import SettingLayoutIndex from '../settings/_layouts/'
import { ActiveTypes } from '../settings/Nav'

interface IProps {
  posts?: any
  payments: {
    payments: any
  }
  active_path: ActiveTypes
}

export const formatSelectOptions = options => {
  if (options.length === 0) {
    return []
  }
  return [
    { value: '', label: '全て' },
    ...options.map((option: { id: number; name: string }) => ({
      value: option.id.toString(),
      label: option.name,
    })),
  ]
}

const getFormattedDate = (created_at: string) =>
  moment(created_at, 'YYYYMMDD HHmmss').format('YYYY/MM/DD')
const params = new URLSearchParams(location.search.substring(1))
const filterDateLabel = params.get('created_at')
const currentPostID = params.get('post_id') || ''

const PaymentPage: React.FC<IProps> = props => {
  const { data: payments, pagination } = utilService.getDataPaginationFromJson(
    props.payments.payments
  )
  const onChangePageHandler = page => {
    const pageParams = new URLSearchParams(window.location.search)
    pageParams.set('page', page)
    location.href = `${location.pathname}?${pageParams.toString()}`
  }

  return (
    <SettingLayoutIndex
      activePath={'payments'}
      main={
        <S.Main>
          <Panel title={I18n.t('payment_history.history')}>
            <div className="Wrapper_Main">
              <table>
                <thead>
                  <tr>
                    <th>注文日</th>
                    <th>支払金額</th>
                    <th>注文詳細</th>
                  </tr>
                </thead>
                <tbody>
                  {payments.map(payment => (
                    <tr key={payment.id}>
                      <td>
                        {moment(payment.reservation.created_at, 'YYYYMMDD HHmmss').format(
                          'YYYY/MM/DD'
                        )}
                      </td>
                      <td>{formatPrice(payment.reservation.total_price)}</td>
                      <td>
                        <a href={`/reservations/${payment.reservation.id}`}>詳細を見る</a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <S.Button>
                <Button small={true}>
                  <a href="/">トップページへ戻る</a>
                </Button>
              </S.Button>
              {pagination && (
                <Pagination
                  onChangePageHandler={onChangePageHandler}
                  currentPage={pagination.current_page}
                  prevPage={pagination.prev_page}
                  nextPage={pagination.next_page}
                  totalCount={pagination.total_count}
                  totalPages={pagination.total_pages}
                />
              )}
            </div>
          </Panel>
        </S.Main>
      }
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Main = styled.div`
  margin: 0 auto;
  padding: 0 12px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    font-size: 14px;
  }

  .Wrapper_Main {
    overflow: scroll;
  }

  table {
    text-align: center;
    padding-bottom: 16px;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      font-size: 12px;
    }

    tr {
      th,
      td {
        padding: 8px 16px;
        > a {
          color: var(${THEME_COLOR_VARIABLE_NAME});
        }
      }
    }
  }

  .Pagination {
    height: 48px;
    border-top: solid 1px ${COLORS.Border};
  }
`

S.Button = styled.div`
  margin-top: 16px;
  margin-left: 16px;
`

export default PaymentPage
