import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IWindow } from '../../../../core/interfaces'
import { reservationService } from '../../../../core/services'
import { InputText } from '../../../atoms'
import { Form } from '../../../molecules'

declare var window: IWindow

interface IProps {
  reservationId: number
  createReceiptFormRef: any
  setReceiptId(id: number): void
}

const ReceiptModal: React.FC<IProps> = props => {
  const [errors, setErrors] = React.useState<{ [key: string]: null | string }>({
    receipt_address: null,
  })
  const handleSubmit = async (initialValues, values) => {
    const { receipt } = await reservationService.createReceipt({
      name: values.receipt_address,
      reservation_id: props.reservationId,
    })
    props.setReceiptId(receipt.id)

    const link = document.createElement('a')
    link.setAttribute('target', '_blank')
    link.setAttribute('href', `/receipts/${receipt.id}`)

    document.body.appendChild(link).click()
    window.globalModal.closeModal()
  }

  return (
    <div>
      <Form
        ref={props.createReceiptFormRef}
        fields={{ receipt_address: 'receipt_address' }}
        handleUpdateForm={(updatedErrors, isSubmitEnabled) => {
          setErrors(updatedErrors)
        }}
        handleSubmit={handleSubmit}
      >
        <InputText
          required={true}
          name="receipt_address"
          label={I18n.t('receipt.receipt_address')}
          error={errors.receipt_address}
          defaultValue=""
        />
        <S.Note>{I18n.t('receipt.note')}</S.Note>
        <S.Note>{I18n.t('receipt.note2')}</S.Note>
      </Form>
    </div>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Note = styled.span`
  display: block;
  font-size: 14px;
  margin-top: 12px;
`

export default ReceiptModal
