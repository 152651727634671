import moment from 'moment'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../core/i18n'
import { infoService } from '../../../core/services'
import {
  BREAKPOINT_TABLET_SMALL,
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
  HEADER_HEIGHT_MOBILE,
} from '../../../static/constants'
import Button from '../../atoms/Button'

interface IContents {
  createdAt: string
  id: string
  info_image: {
    image_url: string
  }
  title: string
  open_reservation_at: string
  HTML_page_code: string
  updatedAt: string
  viewDate: string
  visible: boolean
  content: string
}

interface IProps {
  info_post: any
}

const TopNews: React.FC<IProps> = props => {
  const [info, setInfo] = React.useState<IContents>(Object)
  React.useEffect(() => {
    infoService
      .getInfo(props.info_post.id)
      .then(data => setInfo(data?.info ?? null))
      .catch(console.error)
  }, [])

  return (
    <S.TopNews>
      <S.Contents>
        {info ? (
          <S.Content>
            <S.ContentTitle>
              <img src={info.info_image?.image_url} alt="" loading="lazy" decoding="async" />
              <h2>{info.title}</h2>
              <h4>{moment(info.open_reservation_at).format(I18n.t('generic.date_format'))}</h4>
            </S.ContentTitle>
            <S.ContentMain>
              <div dangerouslySetInnerHTML={{ __html: info.content }} />
            </S.ContentMain>
          </S.Content>
        ) : (
          <S.NoContents>お知らせはありません</S.NoContents>
        )}
      </S.Contents>
      <S.Button>
        <Button>
          <a href="/">トップページへ戻る</a>
        </Button>
      </S.Button>
    </S.TopNews>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.TopNews = styled.section`
  width: 100%;
  padding-top: 32px;
  padding-bottom: 64px;
  margin-top: 16px;
  min-height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px);
`

S.Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
`

S.NoContents = styled.h2`
  font-size: 18px;
  font-weight: normal;
  padding: 0 20px;
`

S.Contents = styled.div`
  margin: 0 auto;
  padding: 0 16px;
  max-width: 680px;
  position: relative;
  overflow: hidden;
`

S.Content = styled.div`
  padding: 10px 20px;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
  }
`

S.ContentTitle = styled.div`
  width: 100%;
  word-break: break-all;

  h2 {
    margin-top: 32px;
    font-size: 18px;
    text-align: center;
  }

  h4 {
    text-align: center;
    margin-top: 16px;
  }

  p {
    margin-top: 32px;
    font-size: 16px;
  }

  > img {
    width: 100%;
    object-fit: cover;
  }
`

S.ContentMain = styled.div`
  width: 100%;
  word-break: break-all;
`

S.Button = styled.div`
  margin-top: 32px;
  text-align: center;
`
export default TopNews
