import { AxiosInstance } from 'axios'
import { IJsonApiSerializer, IJsonResource, IJsonResponse } from '../JsonApiSerializer'

class ReservationService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getDataFromJson(JsonData) {
    const data = this.serializer.parseResourceData(JsonData, JsonData.data)
    const pagination = { ...JsonData.meta }

    return { data, pagination }
  }

  public parseRelationships(resources: IJsonResponse, resource: IJsonResource | IJsonResource[]) {
    return this.serializer.parseResourceData(resources, resource)
  }

  public getReservationFromJson(data) {
    const reservation = this.serializer.parseResourceData(data, data.data)

    return { reservation }
  }

  public getReservationsFromJson(data) {
    const reservations = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { reservations, pagination }
  }

  public async searchReservations(params) {
    const { data } = await this.httpClient.get(`/api/reservations/search`, { params })
    const reservations = this.serializer.parseResourceData(
      data.reservations,
      data.reservations.data
    )
    const pagination = data.reservations.meta

    return { reservations, pagination }
  }

  public async createReservation(params) {
    const { data } = await this.httpClient.post(`/api/reservations`, { ...params }).catch(() => {
      return { data: null }
    })
    if (!data) {
      return {}
    }
    const { flush } = data
    const reservation = this.serializer.parseResourceData(data.reservation, data.reservation.data)

    return { reservation, flush }
  }

  public async approveReservation(id, params) {
    const { data } = await this.httpClient.put(`/api/reservations/${id}/approve`, params)
    const { flush } = data
    const reservation = this.serializer.parseResourceData(data.reservation, data.reservation.data)

    return { reservation, flush }
  }

  public async modifyReservationItemQuantity(id, params) {
    const { data } = await this.httpClient.put(`/api/reservation_items/${id}`, params)
    const { flush } = data
    const reservation = this.serializer.parseResourceData(data.reservation, data.reservation.data)

    return { reservation, flush }
  }

  public async declineReservation(id) {
    const { data } = await this.httpClient.put(`/api/reservations/${id}/decline`)
    const { flush } = data
    const reservation = this.serializer.parseResourceData(data.reservation, data.reservation.data)

    return { reservation, flush }
  }

  public async cancelReservation(id) {
    const { data } = await this.httpClient.put(`/api/reservations/${id}/cancel`)
    const { flush } = data
    const reservation = this.serializer.parseResourceData(data.reservation, data.reservation.data)

    return { reservation, flush }
  }

  public async refundAmountReservation(id) {
    const { data } = await this.httpClient.get(`/api/reservations/${id}/refund_amount`)
    const { price } = data

    return { price }
  }

  public async createMessage(formData) {
    const { data } = await this.httpClient.post(`/api/messages`, formData)
    const message = this.serializer.parseResourceData(data.message, data.message.data)

    return { message }
  }

  public async createReceipt(config) {
    const { data } = await this.httpClient.post('/api/receipts', { receipt: { ...config } })
    const receipt = this.serializer.parseResourceData(data.receipt, data.receipt.data)

    return { receipt }
  }

  // 値段の計算をサーバー側で行うためにここでAPIを叩き計算結果を受け取る
  public getParamStringForCalcPrice(meter, delivery_fee, coupons, store_coupon, items) {
    const arrayString = items.reduce((str, item, index) => {
      const query = item.cart_options
        .map((cart_option, option_index) => {
          return cart_option.cart_option_items.map((cart_option_item, option_item_index) => {
            return (
              `array[${index}][cart_options][${option_index}][cart_option_item][${option_item_index}][amount]=${cart_option_item.amount}&` +
              `array[${index}][cart_options][${option_index}][cart_option_item][${option_item_index}][id]=${cart_option_item.id}`
            )
          })
        })
        .flat()
        .join('&')
      return [
        str,
        `array[${index}][post_item_id]=${item.id}`,
        `array[${index}][quantity]=${item.amount}`,
        `array[${index}][post_id]=${item.post_id}`,
        query,
      ].join('&')
    }, '')
    let counter = 0
    let couponsStr = ''
    for (let i = 0; i < coupons.length; i++) {
      if (!coupons[i]) {
        continue
      }

      couponsStr += `&coupons[${counter}]=${coupons[i]}`
      counter++
    }
    return `?meter=${meter}&delivery_fee=${delivery_fee}${arrayString}${couponsStr}&store_coupon=${store_coupon}`
  }
  public async calcSettlementPriceTotal(meter, delivery_fee, coupons, store_coupon, items) {
    const paramString = this.getParamStringForCalcPrice(
      meter,
      delivery_fee,
      coupons,
      store_coupon,
      items
    )
    const { data } = await this.httpClient.get(
      `/api/post_items/calc_settlement_price_total${paramString}`
    )
    let couponValues = []
    if (data.coupon_values) {
      couponValues = data.coupon_values
    }

    let storeCouponValue = 0
    if (data.store_coupon_value) {
      storeCouponValue = data.store_coupon_value
    }

    if (data.settlement_price_total) {
      return {
        response: data.settlement_price_total,
        price_total: data.price_total,
        tax_total: data.tax_total,
        coupon_values: couponValues,
        store_coupon_value: storeCouponValue,
      }
    } else {
      return {
        response: data.post_items,
        price_total: data.price_total,
        tax_total: data.tax_total,
        coupon_values: couponValues,
        store_coupon_value: storeCouponValue,
      }
    }
  }

  public async calcDeliveryFeeAndTotal(meter, delivery_fee, coupons, store_coupon, items) {
    const paramString = this.getParamStringForCalcPrice(
      meter,
      delivery_fee,
      coupons,
      store_coupon,
      items
    )
    const { data } = await this.httpClient.get(
      `/api/post_items/calc_delivery_fee_and_total${paramString}`
    )
    let couponValues = []
    let storeCouponValue = 0
    let campaignValue = 0
    let campaignName = ''
    let deliveryFee = 0
    if (data.coupon_values) {
      couponValues = data.coupon_values
    }
    if (data.store_coupon_value) {
      storeCouponValue = data.store_coupon_value
    }
    if (data.campaign_value) {
      campaignValue = data.campaign_value
      campaignName = data.campaign_name
    }
    if (data.delivery_fee) {
      deliveryFee = data.delivery_fee
    }
    return {
      deliveryFee: data.delivery_fee,
      total_amount: data.total_amount,
      price_total: data.price_total,
      tax_total: data.tax_total,
      couponValues,
      storeCouponValue,
      campaignValue,
      campaignName,
    }
  }

  public async updateDelivery(id, params) {
    const { data } = await this.httpClient.put(`/api/reservations/${id}/update_delivery`, params)
    const { flush } = data
    const reservation = this.serializer.parseResourceData(data.reservation, data.reservation.data)

    return { flush, reservation }
  }
}

export default ReservationService
