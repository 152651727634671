import * as React from 'react'
import MediaQuery from 'react-responsive'
import Slider from 'react-slick'
import styled, { AnyStyledComponent } from 'styled-components'
import * as constants from '../../static/constants'

interface IImage {
  id: number
  image_url: string
}

interface IProps {
  postImages: IImage[]
}

const SlickSlider: React.FC<IProps> = props => {
  return (
    <S.SlickSlider>
      {props.postImages.length > 0 ? (
        <MediaQuery minDeviceWidth={constants.BREAKPOINT_DESKTOP}>
          {matches => (
            <Slider dots={true} arrows={matches}>
              {props.postImages.map(image => (
                <S.Item key={image.id}>
                  <img alt="" src={image.image_url} loading="lazy" decoding="async" />
                </S.Item>
              ))}
            </Slider>
          )}
        </MediaQuery>
      ) : (
        <S.Item>
          <img alt="" src="/images/no-image.svg" loading="lazy" decoding="async" />
        </S.Item>
      )}
    </S.SlickSlider>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.SlickSlider = styled.div`
  .slick-prev::before,
  .slick-next::before {
    color: #e7e7e7;
    opacity: 1;
  }

  .slick-prev:hover::before,
  .slick-next:hover::before {
    opacity: 0.75;
  }
`

S.Item = styled.div`
  height: 270px;
  overflow: hidden;
  background-color: #e7e7e7;
  border-radius: 4px;
  overflow: hidden;

  > img {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }
`

export default SlickSlider
