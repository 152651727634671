import * as React from 'react'
import { ROLES } from 'static/constants'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IPager, IWindow } from '../../../../core/interfaces'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { adminService } from '../../../../core/services'
import { Button, InputText, Select, Spacer } from '../../../atoms'
import { EditTable } from '../../../organisms'
import AdminLayoutIndex from '../_layouts/'

declare var window: IWindow

interface IProps {
  users: {
    users: IJsonResponse
    meta: IPager
  }
  current_user_id: number
  search_params?: any
}

const COLUMNS = [
  {
    name: I18n.t('generic.id'),
    field: 'id',
    required: false,
    readOnly: true,
  },
  {
    name: I18n.t('admin.type_of_user'),
    field: 'role_name',
    required: true,
  },
  {
    name: I18n.t('generic.email'),
    field: 'email',
    required: true,
  },
  {
    name: I18n.t('generic.username'),
    field: 'username',
    required: false,
  },
]
const getRoleName = id => {
  if (id) {
    return ROLES.find(role => role.value === id)?.label
  }
  return '未設定'
}

const roleOptions = () => {
  return [
    { label: '全て', value: 'all' },
    { label: 'ゲスト', value: 'guest' },
    { label: '店舗ユーザー', value: 'host' },
  ]
}

const FIELDS = {
  keyword: 'keyword',
  role: 'role',
}

const params = new URLSearchParams(location.search.substring(1))

const AdminUserIndex: React.FC<IProps> = props => {
  const { data } = adminService.getDataFromJson(props.users.users)

  const initUsers = data.map(user => {
    return { ...user, role_name: getRoleName(user.role?.id) }
  })
  const [users, setUsers] = React.useState(initUsers)
  const handleDelete = React.useCallback(
    async id => {
      const { flush } = await adminService.deleteUser(id)
      window.flashMessages.addMessage({
        text: flush.message,
        type: flush.type,
      })
      setUsers(users.filter(user => user.id !== id))
      window.globalModal.closeModal()
    },
    [users]
  )

  const handleBecome = React.useCallback(
    async id => {
      await adminService.becomeUser(id)
      location.href = '/'
    },
    [users]
  )

  return (
    <AdminLayoutIndex
      model="user"
      heading={
        <S.Heading>
          <span>{I18n.t('activerecord.models.user')}</span>
          <Button small={true}>
            <a href="/admin/users/new">{I18n.t('generic.create_new')}</a>
          </Button>
          <Button small={true}>
            <a href={`/admin/users/download.csv?${params.toString()}`}>CSVダウンロード</a>
          </Button>
        </S.Heading>
      }
      main={
        <EditTable
          editable={true}
          columns={COLUMNS}
          records={users}
          pagination={props.users.users.meta}
          handleDelete={handleDelete}
          getEditLink={id => `/admin/users/${id}/edit`}
          currentUserId={props.current_user_id}
          renderRecordControls={record => (
            <Button key="become" handleClick={() => handleBecome(record.id)}>
              {I18n.t('generic.become')}
            </Button>
          )}
          formSearch={
            <>
              <InputText
                required={false}
                name="keyword"
                defaultValue={props.search_params.keyword || ''}
                label="キーワード"
                placeholder="メールアドレス、ユーザー名（完全一致のみ）"
                width={500}
              />
              <Spacer />
              <Select
                required={false}
                name="role"
                label="権限別"
                options={roleOptions()}
                defaultValue={props.search_params.role || 'all'}
                width={500}
              />
              <Spacer />
            </>
          }
          formFields={FIELDS}
        />
      }
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Heading = styled.div`
  .Button {
    margin-left: 24px;
  }
`

export default AdminUserIndex
