import moment from 'moment'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../core/i18n'
import { infoService } from '../../core/services'
import { BREAKPOINT_TABLET_SMALL } from '../../static/constants'
import Button from '../atoms/Button'
import Spinner from '../atoms/Spinner'

interface IContents {
  createdAt: string
  id: string
  keyImage: {
    url: string
  }
  publishedAt: string
  title: string
  body: string
  updatedAt: string
  viewDate: string
  visible: boolean
}

const TopNews: React.FC<{}> = () => {
  const [infos, setInfos] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    infoService
      .getInfos()
      .then(data => {
        setInfos(data?.infos ?? [])
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error)
        setIsLoading(false)
      })
  }, [])

  return (
    <S.TopNews>
      <S.Title>お知らせ</S.Title>
      {isLoading ? (
        <Spinner />
      ) : (
        <S.Contents>
          {infos.map(content => (
            <S.Content key={content.id}>
              {content.info_image ? (
                <img src={content.info_image?.image_url} alt="" loading="lazy" decoding="async" />
              ) : (
                <p className="NoImage">No Image</p>
              )}
              <S.ContentMain>
                {content.title.length > 15 ? (
                  <h2>{content.title.substring(0, 15) + '…'}</h2>
                ) : (
                  <h2>{content.title}</h2>
                )}
                <p>{moment(content.open_reservation_at).format(I18n.t('generic.date_format'))}</p>
                <a href={'/infos/' + content.id}>{I18n.t('generic.see_more')}</a>
              </S.ContentMain>
            </S.Content>
          ))}
        </S.Contents>
      )}
      <S.Button>
        <Button>
          <a href="/">トップページへ戻る</a>
        </Button>
      </S.Button>
    </S.TopNews>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.TopNews = styled.section`
  width: 100%;
  max-width: 1360px;
  margin: 0 auto;
  padding: 32px 16px;
`

S.Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
`

S.NoContents = styled.h2`
  font-size: 18px;
  font-weight: normal;
  margin: 24px 0;
  padding: 0 20px;
`

S.Contents = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin: 0 -20px;
`

S.Content = styled.div`
  display: flex;
  width: 50%;
  padding: 10px 20px;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
  }

  > img {
    width: 160px;
    height: 120px;
    object-fit: cover;
  }

  .NoImage {
    width: 160px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e4e4e4;
  }
`

S.ContentMain = styled.div`
  margin-left: 20px;
  width: calc(100% - 180px);

  h2 {
    font-size: 18px;
    word-wrap: break-word;
  }

  p {
    margin-top: 8px;
    margin-bottom: 8px
    font-size: 16px;
  }
  a {
    color: #661f25;
  }
`

S.Button = styled.div`
  margin-top: 32px;
  text-align: center;
`

export default TopNews
