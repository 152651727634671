import * as Color from 'color'
import I18n from 'core/i18n'
import moment from 'moment'
import React from 'react'
import { DateRangePicker } from 'react-dates'
import 'react-dates/initialize'
import styled, { AnyStyledComponent } from 'styled-components'
import { createGlobalStyle } from 'styled-components'
import {
  BREAKPOINT_TABLET_LARGE,
  BREAKPOINT_TABLET_MOBILE,
  BREAKPOINT_TABLET_SMALL,
  COLORS,
  THEME_COLOR_VARIABLE_NAME,
} from '../../static/constants'
import { Button, InputText, Select } from '../atoms'
import { Form } from '../molecules'

interface IProps {
  large_categories_list: any[]
  post: any
}

const FIELDS = {
  keyword: 'keyword',
  category_id: 'category_id',
}

const SearchPanel: React.FC<IProps> = props => {
  const [mainCategoryList, setMainCategoryList] = React.useState<any[]>(props.large_categories_list)
  const mainCategoryListWithPlaceholder = [
    { id: '', name: 'カテゴリから検索' },
    ...mainCategoryList,
  ]

  const handleSubmit = React.useCallback(async (initialValues, values) => {
    location.href = `/posts/${props.post.id}?${new URLSearchParams(values).toString()}`
  }, [])

  return (
    <SearchPanelWrapper className="SearchPanel">
      <Form fields={FIELDS} handleSubmit={handleSubmit}>
        <div className="SearchPanel_FormItem">
          <div className="Search_Category">
            <Select
              required={false}
              name="category_id"
              options={mainCategoryListWithPlaceholder.map(mainCategory => {
                return { label: mainCategory.name, value: mainCategory.id }
              })}
            />
          </div>
          <div className="Search_Keyword">
            <InputText
              required={false}
              placeholder="キーワードで検索"
              name="keyword"
              defaultValue=""
            />
          </div>
          <div className="Search_Button">
            <S.Buttons>
              <Button primary={true}>検索</Button>
            </S.Buttons>
          </div>
        </div>
      </Form>
    </SearchPanelWrapper>
  )
}

const themeColor =
  getComputedStyle(document.documentElement)
    .getPropertyValue(`${THEME_COLOR_VARIABLE_NAME}`)
    .trim() || '#661F25'

const S: { [key: string]: AnyStyledComponent } = {}

const SearchPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 12px;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    max-width: none;
    width: 100%;
  }

  .Form {
    width: 100%;
    display: flex;
    justify-content: center;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      flex-wrap: wrap;
    }
  }

  .SearchPanel_FormItem {
    width: 100%;
    margin: 24px 10px 0;
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      display: block;
      margin: 24px 0 0;
      padding: 0;
    }

    .Search_Category {
      width: 30%;
      @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
        width: 100%;
        padding-right: 0;
        padding-bottom: 20px;
      }
      select {
        background: #fff;
      }
    }

    .Search_Keyword {
      width: 40%;
      @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
        width: 100%;
        padding-right: 0;
        padding-bottom: 20px;
      }
      input {
        background: #fff;
      }
    }

    .Search_Button {
      width: 25%;
      @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
        width: 100%;
        padding-right: 0;
      }
    }
  }
`

S.Buttons = styled.div`
  text-align: center;

  > p {
    display: block;
    margin: 16px auto 0;

    a {
      color: var(${THEME_COLOR_VARIABLE_NAME});
      text-decoration: underline;
    }
  }

  .Button {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 35px;
    font-weight: bold;
    font-size: 16px;
  }
`

export default SearchPanel
