import * as React from 'react'
import Slider from 'react-slick'
import styled, { AnyStyledComponent } from 'styled-components'
import {
  BREAKPOINT_TABLET_LARGE,
  BREAKPOINT_TABLET_MOBILE,
  THEME_COLOR_VARIABLE_NAME,
} from '../../../../static/constants'
import * as constants from '../../../../static/constants'
import { Button } from '../../../atoms'

interface ICategorySliderProps {
  large_categories_list: any[]
  post: any
}

const CategorySlider: React.FC<ICategorySliderProps> = props => {
  const nav = React.useRef(null)
  const subSettings = {
    arrows: false,
    dots: false,
    slidesToShow: 1,
    rows: 2,
    slidesPerRow: 5,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesPerRow: 2,
        },
      },
    ],
    // [aria-hidden="true"]内要素のフォーカス対策
    // 現在のスライドのフォーカス可能な要素に対して tabindex を -1 に設定
    beforeChange: (current, next) => {
      const currentSlide = document.querySelector(`.slick-slide[data-index="${current}"]`)
      if (currentSlide) {
        const focusableElements = currentSlide.querySelectorAll('a, button')
        focusableElements.forEach(el => el.setAttribute('tabindex', '-1'))
      }
    },
    // 新しいスライドのフォーカス可能な要素に対して tabindex をリセット
    afterChange: current => {
      const currentSlide = document.querySelector(`.slick-slide[data-index="${current}"]`)
      if (currentSlide) {
        const focusableElements = currentSlide.querySelectorAll('a, button')
        focusableElements.forEach(el => el.removeAttribute('tabindex'))
      }
    },
  }

  const nextSlider = () => {
    nav.current.slickNext()
  }

  const prevSlider = () => {
    nav.current.slickPrev()
  }

  return (
    <S.Gallery>
      <S.Nav>
        <Slider {...subSettings} ref={nav}>
          <div>
            <Button primary={true} verticalAlign="top">
              <a href={`/posts/${props.post.id}?`}>すべて</a>
            </Button>
          </div>
          {props.large_categories_list.map((category, index) => (
            <div key={category.id}>
              <Button primary={true} verticalAlign="top">
                <a href={`/posts/${props.post.id}?category_id=${category.id}`}>{category.name}</a>
              </Button>
            </div>
          ))}
        </Slider>
        <div className="slick-button">
          <button className="slick-arrow slick-prev" onClick={prevSlider} aria-label="前へ" />
          <button className="slick-arrow slick-next" onClick={nextSlider} aria-label="次へ" />
        </div>
      </S.Nav>
    </S.Gallery>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Gallery = styled.div`
  margin: 0 0 10px;
`

S.Nav = styled.div`
  margin-top: 12px;
  overflow: hidden;

  .slick-list {
    padding: 0 !important;
  }

  button.Button {
    width: 95%;
    height: 65px;
    background-color: var(${constants.THEME_COLOR_VARIABLE_NAME});
    margin-bottom: 10px;

    a {
      justify-content: flex-start;
      color: #fff;
      text-align: left;
      font-weight: bold;
    }
  }

  .slick-button {
    position: relative;
    margin-bottom: 30px;

    .slick-prev {
      left: 0;
      top: 10px;
    }
    .slick-next {
      left: 25px;
      top: 10px;
    }
  }
`

export default CategorySlider
