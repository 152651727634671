import * as Color from 'color'
import IReservationErrorItem from 'core/interfaces/IReservationErrorItem'
import * as moment from 'moment'
import * as React from 'react'
import { DateRangePicker } from 'react-dates'
import 'react-dates/initialize'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { ICartItem, IPost } from '../../../../core/interfaces'
import { postService } from '../../../../core/services'
import {
  BREAKPOINT_TABLET_LARGE,
  BREAKPOINT_TABLET_MOBILE,
  COLORS,
  HEADER_HEIGHT,
  HEADER_HEIGHT_MOBILE,
  PAYMENT_REQUIRED,
} from '../../../../static/constants'
import { formatReservationError } from '../../../../utils/formatAvailabilityError'
import { formatPrice } from '../../../../utils/number'
import { Button, Counter, InputText, InputTextV2 } from '../../../atoms'

// アコーディオンここから
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import ICartOption from 'core/interfaces/ICartOption'
import 'react-accessible-accordion/dist/fancy-example.css'
// アコーディオンここまで

interface IProps {
  post: IPost
  showReservationPanel: boolean
  isSignedIn: boolean
  isRegisteredCard: boolean
  hasDeliveryAddress: boolean
  cartItems: ICartItem[]
  reservationErrors: IReservationErrorItem[]
  settlementPriceTotal: number
  priceTotal: number
  taxTotal: number
  couponValues: number[]
  storeCouponValue: any
  user_already_used_coupon: boolean
  ordable: boolean
  available: boolean
  couponCodes: string[]
  setCouponCodes: React.Dispatch<React.SetStateAction<string[]>>
  updateCartItems(item, amount, options, sidebar, found?): void
  setShowReservationPanel(show: boolean): void
  createReservation(): void
  setStoreCouponCode(code: string): void
}

const SideBar: React.FC<IProps> = ({
  post,
  createReservation,
  showReservationPanel,
  setShowReservationPanel,
  reservationErrors,
  isSignedIn,
  isRegisteredCard,
  hasDeliveryAddress,
  cartItems,
  settlementPriceTotal,
  priceTotal,
  taxTotal,
  ordable,
  available,
  updateCartItems,
  couponCodes,
  setCouponCodes,
  setStoreCouponCode,
  couponValues,
  storeCouponValue,
  user_already_used_coupon,
}) => {
  const isOrdable = () => {
    const today = moment()
    const after_tomorrow = moment().add(2, 'days')
    const start = null
    const end = null
    let result = 1

    if (today > start && end !== null) {
      result = end.diff(moment(), 'days')
    }
    return result > 0 ? true : false
  }

  const canOrderState = post.is_open && post.aasm_state === 'published' && isOrdable()

  const CartItem: React.FC<{ item: ICartItem }> = ({ item }) => {
    const updateAmount = newVal => {
      if (item.amount !== newVal) {
        updateCartItems(item, newVal, item.cart_options, true, null)
      }
    }

    return (
      <S.Cartitem key={item.id}>
        <Accordion allowZeroExpanded={true}>
          <AccordionItem>
            <S.Close onClick={() => updateAmount(0)}>
              <i className="material-icons small">clear</i>
            </S.Close>
            {item.cart_options.length > 0 ? (
              <>
                <AccordionItemHeading>
                  <AccordionItemButton>{item.name}</AccordionItemButton>
                </AccordionItemHeading>
                {item.cart_options.map((option, index) => (
                  <AccordionItemPanel key={`${item.id}${option.id}${index}`}>
                    <S.OptionName>{option.title}</S.OptionName>
                    {option.cart_option_items.map((option_item, option_index) => (
                      <div key={`${item.id}${option_item.id}${option_index}`}>
                        <S.OptionItemName>{option_item.title}</S.OptionItemName>
                        <S.OptionItem>
                          <p>{formatPrice(option_item.settlement_price * option_item.amount)}</p>
                        </S.OptionItem>
                      </div>
                    ))}
                  </AccordionItemPanel>
                ))}
                <S.PriceSection>
                  <Counter initValue={item.amount} min={1} max={10} handleChange={updateAmount} />
                  <p>{formatPrice(calcTotalPrice(item))}</p>
                </S.PriceSection>
              </>
            ) : (
              <>
                <S.ItemName>{item.name}</S.ItemName>
                <S.PriceSection>
                  <Counter initValue={item.amount} min={1} max={10} handleChange={updateAmount} />
                  <p>{formatPrice(item.settlement_price * item.amount)}</p>
                </S.PriceSection>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </S.Cartitem>
    )
  }

  const [isStoreCouponDisplay, setIsStoreCouponDisplay] = React.useState(false)
  const onClickAddStoreCouponButton = event => {
    setIsStoreCouponDisplay(true)
  }
  const onClickDelStoreCouponButton = event => {
    setIsStoreCouponDisplay(false)
    setStoreCouponCode('')
  }

  const changeCouponCodeValue = (event, index) => {
    const newCoupons = [...couponCodes]
    newCoupons[index] = event.target.value
    setCouponCodes(newCoupons)
  }
  const onClickAddCouponButton = () => setCouponCodes([...couponCodes, ''])
  const onClickDelCouponButton = (e, index) => {
    setCouponCodes(couponCodes.filter((_c, i) => index !== i))
  }

  const changeStoreCouponCodeValue = event => {
    const value = event.target.value
    setStoreCouponCode(value)
  }
  React.useEffect(() => {
    if (couponCodes.length > 1 && couponCodes[0] === '') {
      setCouponCodes(couponCodes.filter((_c, i) => i !== 0))
    }
  }, [couponCodes])

  const calcTotalPrice = item => {
    let optionSumItemPrice = 0
    item.cart_options.map(cart_option => {
      cart_option.cart_option_items.map(cart_option_item => {
        optionSumItemPrice += Number(cart_option_item.settlement_price)
      })
    })
    return (item.settlement_price + optionSumItemPrice) * item.amount
  }
  const currentUrl = encodeURIComponent(window.location.href)

  return (
    <S.Sidebar show={showReservationPanel}>
      <S.Wrapper>
        <S.Panel>
          <S.Hide onClick={() => setShowReservationPanel(false)}>
            <i className="material-icons small">arrow_back</i>
          </S.Hide>
          {post.aasm_state !== 'published' && '注文受付停止中です'}
          {post.aasm_state === 'published' && !post.is_open && '受付時間外です'}
          {PAYMENT_REQUIRED && canOrderState && (
            <S.Cartitems>
              {cartItems.length > 0 ? (
                cartItems.map((item, index) => {
                  return <CartItem key={index} item={item} />
                })
              ) : (
                <p style={{ margin: '16px' }}>カートに商品がありません</p>
              )}
              {cartItems.length > 0 && settlementPriceTotal && priceTotal && taxTotal && (
                <>
                  {/* <div className="PostShow_Price">
                    <span>小計</span>
                    <p>{formatPrice(priceTotal)}</p>
                  </div>
                  <div className="PostShow_Price">
                    <span>消費税</span>
                    <p>{formatPrice(taxTotal)}</p>
                  </div> */}
                  <div className="PostShow_Price">
                    <span>{I18n.t('generic.total')}</span>
                    <p>{formatPrice(settlementPriceTotal)}</p>
                  </div>
                </>
              )}
              {cartItems.length > 0 && reservationErrors && !available && (
                <ul>
                  {formatReservationError(reservationErrors).map((availabilityError, index) => {
                    return (
                      <li key={index} style={{ fontSize: '12px', color: 'red' }}>
                        {availabilityError.name}は残り在庫{availabilityError.in_stock}です
                      </li>
                    )
                  })}
                </ul>
              )}
            </S.Cartitems>
          )}
          {cartItems.length > 0 && !ordable && available && (
            <p style={{ fontSize: '12px', color: 'red' }}>
              最低注文金額は{formatPrice(post.display_min_price)}円です
            </p>
          )}

          {isSignedIn &&
            post.coupon_exists &&
            ordable &&
            available &&
            couponCodes.map((couponCode, index) => (
              <S.Coupon key={index}>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <InputTextV2
                    required={false}
                    name="coupon_code"
                    value={couponCodes[index]}
                    label={index === 0 ? 'クーポンコード' : '        '}
                    onChangeHandler={e => changeCouponCodeValue(e, index)}
                  />
                  <div style={{ width: 4 }} />
                  {couponCodes.length > 0 && (
                    <S.IconButton className="del" onClick={e => onClickDelCouponButton(e, index)}>
                      <i className="large material-icons">remove_circle_outline</i>
                    </S.IconButton>
                  )}
                </div>
                {couponValues[index] && couponValues[index] !== 0 && couponValues[index] !== -1 && (
                  <div className="coupon_value"> - {formatPrice(couponValues[index])}</div>
                )}
                {couponValues[index] === -1 && (
                  <div className="coupon_value">入力されたクーポンは無効です</div>
                )}
              </S.Coupon>
            ))}
          {cartItems.length > 0 && post.coupon_exists && (
            <>
              {!isSignedIn ? (
                <>
                  <S.AddIconButton>
                    <S.IconButton style={{ color: 'red' }}>
                      ご利用にはログインが必要です。
                    </S.IconButton>
                  </S.AddIconButton>
                  <Button primary={true}>
                    <a href="/users/sign_in">{I18n.t('generic.login')}</a>
                  </Button>
                </>
              ) : isRegisteredCard && hasDeliveryAddress ? (
                <>
                  <S.AddIconButton>
                    <S.IconButton
                      style={{ color: 'red', fontSize: '14px' }}
                      onClick={onClickAddCouponButton}
                    >
                      クーポンはこちらに入力してください
                      <i className="large material-icons">add_circle_outline</i>
                    </S.IconButton>
                  </S.AddIconButton>
                  <p style={{ fontSize: '12px', opacity: '0.75', marginTop: '20px' }}>
                    カート内の商品を削除する場合は「×」マークを押してください。
                  </p>
                  <Button
                    disabled={
                      !canOrderState ||
                      !ordable ||
                      !available ||
                      couponValues.some(c => c === -1) ||
                      storeCouponValue === -1
                    }
                    primary={true}
                    handleClick={createReservation}
                  >
                    {I18n.t('generic.confirm_reservation')}
                  </Button>
                </>
              ) : (
                <>
                  {!isRegisteredCard && (
                    <>
                      <S.AddIconButton>
                        <S.IconButton style={{ color: 'red' }}>
                          ご利用にはクレジットカード登録が必要です。
                        </S.IconButton>
                      </S.AddIconButton>
                      <Button primary={true}>
                        <a href={`/settings/card?return_to=${currentUrl}`}>カード登録ページへ</a>
                      </Button>
                    </>
                  )}
                  {!hasDeliveryAddress && (
                    <>
                      <S.AddIconButton>
                        <S.IconButton style={{ color: 'red' }}>
                          登録住所が配送範囲外です。
                        </S.IconButton>
                      </S.AddIconButton>
                      <Button primary={true}>
                        <a href={`/settings/profile?return_to=${currentUrl}`}>住所登録ページへ</a>
                      </Button>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </S.Panel>
      </S.Wrapper>
    </S.Sidebar>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Sidebar = styled.div<{ show: boolean }>`
  margin-bottom: 48px;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    display: ${({ show }) => (show ? 'block' : 'none')};
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 10px; //0;
    margin: auto;
    background-color: #fff;
    z-index: 900;
  }
  .Button {
    height: 48px;
  }
`
S.Wrapper = styled.div`
  position: sticky;
  top: ${HEADER_HEIGHT + 32}px;
  width: 320px;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    position: static;
    width: 100%;
    max-width: 720px;
    margin: auto;
    height: 100%;
  }

  .Button {
    width: 100%;
    margin-top: 24px;
  }
`
S.Price = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
`
S.Panel = styled.div`
  padding: 16px;
  border: solid 1px ${COLORS.Border};
  background-color: #fff;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    padding: 24px;
    border: none;
    height: 100%;
    overflow-y: auto;
    padding-top: 110px;
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    padding-top: 70px;
  }
  .Button {
    font-weight: bold;
  }
`
S.Cartitems = styled.div`
  .PostShow_Price {
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
    margin: 8px 16px;
  }
  .PostShow_TotalPrice {
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
    margin: 8px 16px;
    border-top: 1px solid #eaedef;
  }
  .accordion__button {
    cursor: pointer;
  }
  .accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
  }
  .accordion__button[aria-expanded='true']::before,
  .accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
  }
`
S.Cartitem = styled.div`
  position: relative;
  padding-bottom: 16px;
  margin: 16px 16px;
  border-bottom: 1px solid #eaedef;
  > p {
    display: inline-block;
  }
`
S.PriceSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  margin-top: 16px;
`
S.Hide = styled.div`
  display: none;
  margin: 0 0 0 10px;
  cursor: pointer;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    display: block;
  }
`
S.Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    display: block;
  }
`
S.ItemName = styled.p`
  width: calc(100% - 30px);
`
S.Buttons = styled.div`
  padding: 16px;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    display: none;
  }
  .Button {
    font-weight: bold;
    color: ${COLORS.Text};
    border: solid 1px
      ${Color(COLORS.Text)
        .lighten(3.6)
        .hex()};
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      opacity: 1;
    }
    .material-icons {
      margin-right: 6px;
      color: ${Color(COLORS.Text)
        .lighten(3.6)
        .hex()};
    }
  }
`
S.Coupon = styled.div`
  margin-top: 20px;
  p {
    width: 165px;
  }
  div.coupon_value {
    display: flex;
    justify-content: flex-end;
    margin-top: 6px;
    margin-right: 26px;
  }
`
S.OptionName = styled.div`
  font-size: 14px;
  margin: 16px 16px;
`
S.OptionItem = styled.div`
  padding-bottom: 8px;
  margin-left: 32px;
  border-bottom: 1px solid #eaedef;
  p {
    text-align: right;
    font-size: 14px;
  }
`
S.OptionItemName = styled.div`
  font-size: 14px;
  margin: 8px 0 0 32px;
`
S.IconButton = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: #56a78a;
  > i {
    font-size: 24px;
  }
  &.del {
    color: #888888;
    margin-bottom: 6px;
    margin-left: 4px;
  }
`
S.AddIconButton = styled.div`
  clear: both;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export default SideBar
