import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class SettingsService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getDataFromJson(JsonData) {
    const data = this.serializer.parseResourceData(JsonData, JsonData.data)
    const pagination = { ...JsonData.meta }

    return { data, pagination }
  }

  public async deleteSocialProfile(provider) {
    const {
      data: { user, flush },
    } = await this.httpClient.delete(`/api/social_profiles/${provider}`)

    return { user, flush }
  }

  public async updateSetting(setting) {
    const { settings, flush } = await this.httpClient.patch(`/admin/api/setting`, setting)

    return { settings, flush }
  }

  public async getCard() {
    // cardをjson apiのレスポンスに
    const { data } = await this.httpClient.get('/api/card')

    return { card: data }
  }
}
export default SettingsService
