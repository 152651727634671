import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons'
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import IReservationErrorItem from 'core/interfaces/IReservationErrorItem'
import parse from 'html-react-parser'
import { clamp } from 'lodash'
import moment from 'moment'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import {
  ICartItem,
  IJsonResponse,
  IOptionItem,
  IPager,
  IPost,
  IPostItem,
  IPostOption,
  IReservation,
  IUser,
  IWindow,
} from '../../../../core/interfaces'
import {
  postService,
  reservationService,
  userService,
  utilService,
} from '../../../../core/services'
import {
  BREAKPOINT_TABLET_LARGE,
  BREAKPOINT_TABLET_MOBILE,
  BREAKPOINT_TABLET_SMALL,
  COLORS,
  THEME_COLOR_VARIABLE_NAME,
} from '../../../../static/constants'
import { formatReservationError } from '../../../../utils/formatAvailabilityError'
import injectGoogleMaps from '../../../../utils/injectGoogleMaps'
import { formatPrice } from '../../../../utils/number'
import { Button, CheckBox, Counter, InputText, Pagination, Select } from '../../../atoms'
import { Form } from '../../../molecules'
import ReservationNew from '../../reservations/new'
import CategorySlider from './CategorySlider'
import PostGallery from './Gallery'
import SideBar from './SideBar'

// アコーディオンここから
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import ICartOption from 'core/interfaces/ICartOption'
import 'react-accessible-accordion/dist/fancy-example.css'
// アコーディオンここまで

declare var window: IWindow

const DEFAULT_PAGE = 'show'

interface IProps {
  editable: boolean
  isOwner: boolean
  isSignedIn: boolean
  post_owner: IUser
  post: IJsonResponse
  related_posts: IPost[]
  user: IUser
  profile_required: boolean
  bundled_reservation?: any
  initial_delivery_fee?: number
  latest_reservation_id?: number
}

const DAYS_OF_WEEK = {
  Mon: '月',
  Tue: '火',
  Wed: '水',
  Thur: '木',
  Fri: '金',
  Sat: '土',
  Sun: '日',
}

const SEARCH_FIELDS = {
  keyword: 'keyword',
  order: 'order',
  category_id: 'category_id',
}

const orderOptions = () => {
  return [
    { label: '新着順', value: 'recent' },
    { label: '価格が安い順', value: 'price_low' },
    { label: '価格が高い順', value: 'price_high' },
  ]
}

export interface IReservationParams {
  reservation: {
    require_delivery: boolean
    specified_delivery_time_at: string
    distance: number
    post_id: number
    remarks: string
    delivery_fee: number
    bundled_flag: boolean
    reservation_item_attributes: Array<{
      post_item_id: number
      quantity: number
      snapshot_item_name: string
      reservation_item_options_attributes: Array<{
        post_option_id: number
        post_option_item_id: number
        quantity: number
        snapshot_option_title: string
        snapshot_option_item_title: string
      }>
    }>
    coupons: string[]
    store_coupon: string
  }
  address: {
    addressable: null | boolean
    city1: string
    state1: string
    other1_1: string
    other1_2: string
    postal_code1: string
    addressable_type: string
    phone: string
  }
  controller: 'api/reservations'
  action: 'create'
}

function getReservationParams(
  cartItems: ICartItem[],
  post: IPost,
  user: any,
  couponCodes: string[],
  storeCouponCode: string,
  deliveryFee: number
): IReservationParams {
  const reservation_item_options_attributes = cartItem => {
    return cartItem.cart_options
      .map(cart_option => {
        return cart_option.cart_option_items.map(cart_option_item => ({
          post_option_id: cart_option.id,
          option_item_id: cart_option_item.id,
          quantity: cart_option_item.amount,
          snapshot_option_title: cart_option.title,
          snapshot_option_item_title: cart_option_item.title,
        }))
      })
      .flat()
  }

  return {
    reservation: {
      require_delivery: true,
      specified_delivery_time_at: '',
      distance: 0,
      coupons: couponCodes,
      store_coupon: storeCouponCode,
      post_id: post.id,
      remarks: '',
      delivery_fee: 0,
      bundled_flag: false,
      reservation_item_attributes: cartItems.map(cartItem => ({
        post_item_id: cartItem.id,
        quantity: cartItem.amount,
        snapshot_item_name: cartItem.name,
        reservation_item_options_attributes: reservation_item_options_attributes(cartItem),
      })),
    },
    address: {
      addressable: null,
      addressable_type: 'Reservation',
      city1: user.address?.city1 || '',
      state1: user.address?.state1 || '',
      other1_1: user.address?.other1_1 || '',
      other1_2: user.address?.other1_2 || '',
      postal_code1: user.address?.postal_code1 || '',
      phone: user.address?.phone || '',
    },
    controller: 'api/reservations',
    action: 'create',
  }
}

export const PostShow: React.FC<IProps> = props => {
  const { data: post } = utilService.getDataFromJson(props.post)
  const [reservationParams, setReservationParams] = React.useState(null)
  const [page, setPage] = React.useState(DEFAULT_PAGE)
  const [currentUser, setCurrentUser] = React.useState<IUser | null>(null)

  React.useEffect(() => {
    let isMounted = true
    userService.getUserMe().then(({ data }) => {
      if (data && data.user) {
        const { data: user } = utilService.getDataFromJson(data.user)
        setCurrentUser(user)
      }
    })

    return () => {
      isMounted = false
    }
  }, [])
  const changePage = changedPage => {
    setPage(changedPage)
    history.pushState(null, null, `?active=${changedPage}`)
  }
  const changePageFromParam = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const active = urlParams.get('active')

    if (active) {
      setPage(active)
    } else {
      setPage(DEFAULT_PAGE)
    }
  }

  React.useEffect(() => {
    let isMounted = true
    changePageFromParam()
    window.onpopstate = () => {
      changePageFromParam()
    }

    return () => {
      isMounted = false
    }
  }, [])

  const [cartItems, setCartItems] = React.useState<ICartItem[]>([])

  if (reservationParams === null || page === 'show') {
    const PostDetailWithGoogleMap = injectGoogleMaps(PostDetail)
    return (
      <PostDetailWithGoogleMap
        {...props}
        post={post}
        page={page}
        user={currentUser}
        changePage={changePage}
        setReservationParams={setReservationParams}
        cartItems={cartItems}
        setCartItems={setCartItems}
      />
    )
  } else {
    return (
      <ReservationNew
        post={post}
        cartItems={cartItems}
        reservationParams={reservationParams}
        user={currentUser}
        bundled_reservation={props.bundled_reservation}
        initial_delivery_fee={props.initial_delivery_fee}
        latest_reservation_id={props.latest_reservation_id}
      />
    )
  }
}

interface IPostDetailProps {
  editable: boolean
  isOwner: boolean
  isSignedIn: boolean
  isRegisteredCard: boolean
  hasDeliveryAddress: boolean
  user_already_used_coupon: boolean
  post_owner: IUser
  related_posts: IPost[]
  user: IUser
  profile_required: boolean
  page: string
  post: IPost
  cartItems: ICartItem[]
  postItems: IPostItem[]
  pagination?: IPager
  search_params?: any
  item_categories: any[]
  setCartItems: React.Dispatch<React.SetStateAction<ICartItem[]>>
  post_items: {
    postitems: IJsonResponse
  }
  changePage(page: string): void
  setReservationParams(reservation: any): void
}

export const PostDetail: React.FC<IPostDetailProps> = ({ cartItems, setCartItems, ...props }) => {
  const [localCartItems, setLocalCartItems] = React.useState<ICartItem[]>(
    (JSON.parse(localStorage.getItem('cartItems')) || []).filter(
      item => item.post_id === props.post.id
    )
  )
  const [deliveryDistance, setDeliveryDistance] = React.useState<number>(0)
  const [deliveryFee, setDeliveryFee] = React.useState<number>(0)
  const [couponCodes, setCouponCodes] = React.useState<string[]>([])
  const [storeCouponCode, setStoreCouponCode] = React.useState<string>('')
  const [couponValues, setCouponValues] = React.useState<number[]>([])
  const [storeCouponValue, setStoreCouponValue] = React.useState<any>(0)
  const [settlementPriceTotal, setSettlementPriceTotalWithDeliveryFee] = React.useState<
    number | null
  >(null)
  const [priceTotal, setPriceTotal] = React.useState<number | null>(null)
  const [taxTotal, setTaxTotal] = React.useState<number | null>(null)
  const [reservationErrors, setShowReservationError] = React.useState<IReservationErrorItem[]>(null)
  const [showReservationPanel, setShowReservationPanel] = React.useState<boolean>(false)
  const [ordable, setOrdable] = React.useState<boolean>(false)
  const [available, setAvailable] = React.useState<boolean>(false)

  const { data, pagination } = postService.getDataFromJson(props.post_items.postitems)
  const [postItems, setPostItems] = React.useState(data)

  const currentCategory = props.item_categories.find(
    item => item.id === Number(props.search_params.category_id)
  )

  const getCategoryNameById = (id: number) => {
    const category = props.item_categories.find(item => item.id === id)
    return category ? category.name : ''
  }

  const searchCategory = currentCategory
    ? currentCategory.parent_id === null && currentCategory.ancestry === null
      ? `${getCategoryNameById(currentCategory.id)}` // 大カテゴリ名を表示
      : currentCategory.parent_id !== null &&
        currentCategory.ancestry &&
        !currentCategory.ancestry.includes('/')
      ? `${getCategoryNameById(currentCategory.parent_id)} / ${getCategoryNameById(
          currentCategory.id
        )}` // 中カテゴリ名を表示
      : currentCategory.parent_id !== null &&
        currentCategory.ancestry &&
        currentCategory.ancestry.includes('/')
      ? `${getCategoryNameById(
          Number(currentCategory.ancestry.split('/')[0])
        )} / ${getCategoryNameById(currentCategory.parent_id)} / ${getCategoryNameById(
          currentCategory.id
        )}` // 小カテゴリ名を表示
      : '' // その他の場合、何も表示しない
    : '' // currentCategory が設定されていない場合、何も表示しない

  const [displayCategories, setDisplayCategories] = React.useState<any[]>(
    currentCategory
      ? currentCategory.parent_id === null && currentCategory.ancestry === null
        ? props.item_categories.filter(item => item.parent_id === currentCategory.id) // 中カテゴリを表示
        : currentCategory.parent_id !== null &&
          currentCategory.ancestry &&
          !currentCategory.ancestry.includes('/')
        ? props.item_categories.filter(item => item.parent_id === currentCategory.id) // 小カテゴリを表示
        : currentCategory.parent_id !== null &&
          currentCategory.ancestry &&
          currentCategory.ancestry.includes('/')
        ? props.item_categories.filter(item => item.parent_id === currentCategory.parent_id) // 小カテゴリを表示
        : props.item_categories.filter(item => item.parent_id === null) // その他の場合、大カテゴリを表示
      : props.item_categories.filter(item => item.parent_id === null) // currentCategoryが設定されていない場合も大カテゴリを表示
  )

  const scrollContainerRef = React.useRef(null)

  const canOrderState = props.post.is_open && props.post.aasm_state === 'published'

  const TIME_FORMAT = new RegExp('^([01]?[0-9]|2[0-4]):([0-5][0-9])$')

  const createReservation = () => {
    if (props.editable) {
      window.flashMessages.addMessage({
        text: I18n.t('post.cannot_reserve_own_post'),
        type: 'error',
      })
      return
    }

    const reservationParams = getReservationParams(
      localCartItems,
      props.post,
      props.user,
      couponCodes,
      storeCouponCode,
      deliveryFee
    )
    setCartItems(localCartItems)
    props.setReservationParams(reservationParams)
    props.changePage('reservation')
    document.scrollingElement.scrollTo(0, 0)
  }

  React.useEffect(() => {
    let isMounted = true

    setSettlementPriceTotal(
      deliveryDistance,
      deliveryFee,
      couponCodes,
      storeCouponCode,
      localCartItems
    )

    return () => {
      isMounted = false
    }
  }, [deliveryDistance, deliveryFee, couponCodes, storeCouponCode, localCartItems])

  React.useEffect(() => {
    let isMounted = true
    setOrdable(localCartItems.length > 0 && settlementPriceTotal >= props.post.display_min_price)

    return () => {
      isMounted = false
    }
  }, [settlementPriceTotal])

  React.useEffect(() => {
    let isMounted = true
    setAvailable(reservationErrors == null)

    return () => {
      isMounted = false
    }
  }, [reservationErrors])

  const setPriceTotalDisplay = priceTotalVal => {
    setPriceTotal(priceTotalVal)
  }

  const setTaxTotalDisplay = taxTotalVal => {
    setTaxTotal(taxTotalVal)
  }

  const setCouponValuesDisplay = couponVal => {
    setCouponValues(couponVal)
  }

  const setStoreCouponValueDisplay = storeCouponVal => {
    setStoreCouponValue(storeCouponVal)
  }

  const setSettlementPriceTotal = async (
    distance: number,
    delivery_fee: number,
    coupon_codes: string[],
    store_coupon_code: string,
    items: ICartItem[]
  ) => {
    if (items.length > 0) {
      const {
        response,
        price_total,
        tax_total,
        coupon_values,
        store_coupon_value,
      } = await reservationService.calcSettlementPriceTotal(
        distance,
        delivery_fee,
        coupon_codes,
        store_coupon_code,
        items
      )
      if (response instanceof Array) {
        setShowReservationError(response)
      } else {
        setSettlementPriceTotalWithDeliveryFee(response)
        setShowReservationError(null)
      }
      setPriceTotalDisplay(price_total)
      setTaxTotalDisplay(tax_total)
      setDeliveryFee(delivery_fee)
      setCouponValuesDisplay(coupon_values)
      setStoreCouponValueDisplay(store_coupon_value)
    }
  }

  const getCartItem = (item, amount, options) => {
    return {
      id: item.id,
      name: item.name,
      amount,
      price: item.price,
      retail_price: item.retail_price,
      settlement_price: item.settlement_price,
      cart_options: options,
      post_id: item.post_id,
    }
  }

  const updateCartItems = (newItem, amount, options, sidebar = false, found) => {
    setLocalCartItems(old => {
      const cartItem = getCartItem(newItem, amount, options)
      let newItems = old
        .map(alreadyItem => {
          if (isSameItemSet(alreadyItem, cartItem)) {
            // 既存のカートアイテムの個数を増やす
            // Modal で同一商品追加時は alreadyItem.amount + amount(※1) だが、
            // SideBar で増減させた場合は増減後の数量(※1)が amount に入ってくるため
            const plus_amount = sidebar ? amount : alreadyItem.amount + amount
            alreadyItem.amount = clamp(plus_amount, 0, 10)
          }
          return alreadyItem
        })
        .filter(item => item.amount > 0)

      // option の数を購入商品数にが合わせる
      if (cartItem.cart_options) {
        cartItem.cart_options.forEach(option => {
          option.cart_option_items.forEach(item => {
            if (item.amount > 0) {
              item.amount = cartItem.amount
            }
          })
        })
      }
      const isDuplicateItem = newItems.some(item => {
        return isSameItemSet(item, cartItem)
      })
      if (found === undefined || !isDuplicateItem) {
        newItems.push(cartItem)
      }
      newItems = newItems.filter(item => item.amount > 0) // Itemのamountが0の場合除外
      // Option に紐づく cart_option_item がない場合 option 自体を空にする
      newItems.forEach(item => {
        item.cart_options = item.cart_options.filter(
          option => option.cart_option_items.length !== 0
        )
      })
      // 全てのアイテムを取得
      const allItemsInStorage = JSON.parse(localStorage.getItem('cartItems')) || []
      // 該当店舗の商品を除いたアイテムのみを取得
      const itemsForOtherPosts = allItemsInStorage.filter(item => item.post_id !== props.post.id)
      // 更新したアイテムリストをローカルストレージに再保存
      localStorage.setItem('cartItems', JSON.stringify([...itemsForOtherPosts, ...newItems]))
      return newItems.filter(item => item.amount > 0)
    })
  }

  // オプションを含め同じ注文アイテムがあるかを判断
  const isSameItemSet = (alreadyItem, cartItem) => {
    // 該当の商品アイテムIDが違う時点で別アイテムセット
    if (alreadyItem.id !== cartItem.id) {
      return false
    }

    // カート内の比較アイテムのオプション種類数
    const is_already_length = alreadyItem.cart_options.length
    // カート追加のアイテムのオプション種類数
    const is_new_option_length = cartItem.cart_options.length
    // オプション種類数が同一でない場合は別アイテムセット
    if (is_new_option_length !== is_already_length) {
      return false
    }

    // カートに追加されるアイテムがオプション無しで、
    // 既存のカートに入っているアイテムもオプション無しの場合、
    // 同一アイテムセット
    if (is_new_option_length === 0 && is_already_length === 0) {
      return true
    }

    // 既存のカートに入っているアイテムのオプションアイテムIDを配列保持
    const already_cart_option_item_ids = alreadyItem.cart_options
      .map(option => option.cart_option_items && option.cart_option_items.map(item => item.id))
      .flat()
    // カートに追加されるアイテムのオプションアイテムIDを配列保持
    const new_cart_option_item_ids = cartItem.cart_options
      .map(option => option.cart_option_items && option.cart_option_items.map(item => item.id))
      .flat()

    // 互いのオプション種類数が同一時のみ、アイテムのIDを比較し同一アイテムセットかを判定
    const everyflg = new_cart_option_item_ids.every(id => {
      return already_cart_option_item_ids.includes(id)
    })

    return everyflg
  }

  const showCartDetailModal = newItem => {
    // カート内にアイテムがあるか確認
    const found = localCartItems.find(item => item.id === newItem.id)
    const ModalBody: React.FC<{}> = modalBodyProps => {
      const MIN = 0
      const MAX = 10
      const [isItemFavorite, setIsFavorite] = React.useState<boolean>(newItem.is_favorite ?? false)
      const [itemAmount, setItemAmount] = React.useState(found ? found.amount : 1)
      const [expandeds, setExpandeds] = React.useState<string[]>(
        newItem.post_options.length > 0 ? [String(newItem.post_options[0].id)] : ['']
      )
      const addItemAmount = val => {
        setItemAmount(val)
      }
      // オプションアイテムの個数管理(複数時)
      const [options, setOptions] = React.useState<ICartOption[]>([])
      // オプション単数時の選択項目
      const addSelectOptionItem = (e, item, cart_option, cart_option_item) => {
        // 該当のオプションIDがあれば単数なので削除
        const newState = [...options].filter(co => co.id !== cart_option.id)

        if (toBoolean(e.target.getAttribute('data-insert-before')) === false) {
          const optionRadios = document.getElementsByName(`option_${cart_option.id}`)
          optionRadios.forEach(radio => {
            radio.setAttribute('data-insert-before', 'false')
          })
          // OFF -> ON
          e.target.setAttribute('data-insert-before', 'true')

          // 追加対象のオプションデータを追加
          addOptions(newState, item, cart_option, cart_option_item, 1)
          e.target.checked = true
        } else {
          // ON -> OFF
          e.target.setAttribute('data-insert-before', false)
          addOptions(newState, item, cart_option, cart_option_item, 0)
          e.target.checked = false
        }
      }

      // オプションアイテムのアイテム紐付け処理
      const addCheckedOptions = (e, item, cart_option, cart_option_item, val) => {
        const newState = [...options]
        if (e.target.checked) {
          // check されたら追加
          addOptions(newState, item, cart_option, cart_option_item, 1)
        } else {
          // check はずしたら除外
          addOptions(newState, item, cart_option, cart_option_item, 0)
        }
      }

      const addOptions = (newState, item, cart_option, cart_option_item, val) => {
        // UX向上の為、オプション選択時次のオプション項目のアコーディオンを広げる
        setExpandeds(prev => {
          let newExpandeds = [...prev]
          if (cart_option.is_multiple) {
            // 複数選択時はそのまま
            return newExpandeds
          } else {
            // 次のアイテムのアコーディオンを広げるフラグ
            let next = false
            item.post_options.forEach(option => {
              if (option.id === cart_option.id) {
                // 同じオプションIDが来たので次のフラグを立てる
                next = true
              } else if (next) {
                // 開くアコーディオンのUUIDを指定
                newExpandeds = [String(option.id)]
                next = false
              }
            })
            // 変更がない場合、再レンダリングされないため
            if (newExpandeds.join() === prev.join()) {
              newExpandeds = []
            }
            return newExpandeds
          }
        })
        setOptions(prev => {
          const cartOption = newState.find(co => co.id === cart_option.id)
          // すでにカート内に入っているオプションか判断
          if (cartOption) {
            const optionItem = cartOption.cart_option_items.find(
              coi => coi.id === cart_option_item.id
            )
            // すでにカート内に入っているオプション項目か判断
            if (optionItem) {
              // 個数を変更
              optionItem.amount = val
            } else {
              // 新しいオプション項目としてカートに入れる
              cartOption.cart_option_items.push({
                id: cart_option_item.id,
                cart_option_id: cart_option.id,
                title: cart_option_item.title,
                price: cart_option_item.price,
                retail_price: cart_option_item.retail_price,
                settlement_price: cart_option_item.settlement_price,
                amount: val,
              })
            }
          } else {
            // 新しいオプションとしてカートに入れる
            newState.push({
              id: cart_option.id,
              cart_item_id: item.id,
              title: cart_option.title,
              cart_option_items: [
                {
                  id: cart_option_item.id,
                  cart_option_id: cart_option.id,
                  title: cart_option_item.title,
                  price: cart_option_item.price,
                  retail_price: cart_option_item.retail_price,
                  settlement_price: cart_option_item.settlement_price,
                  amount: val,
                },
              ],
            })
          }
          // amount 0 のオプションアイテムは除外する
          if (newState) {
            newState.forEach(
              option =>
                (option.cart_option_items = option.cart_option_items.filter(
                  option_item => !(option_item.amount === 0)
                ))
            )
          }
          return newState
        })
      }
      const optionSelectItemName = option => {
        const cart_option = options.find(co => co.id === option.id)
        if (!cart_option || !cart_option.cart_option_items) {
          return option.description
        }
        const optionNames = cart_option.cart_option_items.map(item => {
          return item.title
        })
        if (optionNames.length > 0) {
          return optionNames.join('、')
        } else {
          return option.description
        }
      }
      // モーダル内選択時金額計算
      const calcOptionItemsAmount = postItem => {
        // オプションアイテム配列を作成
        const optionItems: IOptionItem[] = postItem.post_options
          .map(post_option => {
            return post_option.option_items
          })
          .flat()
        // オプションアイテム合計金額
        let optionAmount = 0
        if (options) {
          options.map(option => {
            option.cart_option_items.map(option_item => {
              const optionItem = optionItems.find(item => item.id === option_item.id)
              optionAmount += option_item.amount * option_item.settlement_price
            })
          })
        }
        // アイテム単価
        const itemPrice = postItem.settlement_price + optionAmount
        // 商品小計金額
        const tmpTotalPrice = itemPrice * itemAmount
        return tmpTotalPrice
      }

      // 「カートに追加」ボタン金額計算
      const calcOptionItemsRetailAmount = postItem => {
        // オプションアイテム配列を作成
        const optionItems: IOptionItem[] = postItem.post_options
          .map(post_option => {
            return post_option.option_items
          })
          .flat()
        // オプションアイテム合計金額
        let optionAmount = 0
        if (options) {
          options.map(option => {
            option.cart_option_items.map(option_item => {
              const optionItem = optionItems.find(item => item.id === option_item.id)
              optionAmount += option_item.amount * option_item.retail_price
            })
          })
        }
        // アイテム単価
        const itemPrice = postItem.retail_price + optionAmount
        // 商品小計金額
        const tmpTotalPrice = itemPrice * itemAmount
        return tmpTotalPrice
      }

      const addItem = () => {
        updateCartItems(newItem, itemAmount, options, false, found)
        // GA商品カート追加イベント
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'add_to_cart',
            ecommerce: {
              items: [
                {
                  item_name: newItem.name || 'Unknown',
                  item_id: newItem.id || 'Unknown',
                  price: newItem.settlement_price || 0,
                  quantity: itemAmount,
                },
              ],
            },
          })
        }
        window.globalModal.closeModal()
      }

      // カートに追加制御
      const isAddCartAble = addingItem => {
        // 追加アイテムのすべての項目が True の時追加ボタンが押せる
        const result = addingItem.post_options.every(option => {
          // 紐づくオプションが必須項目
          if (option.is_required) {
            // 選択オプションが格納されている配列内の中から該当オプションを抽出
            const cart_option = options.find(adding_option => adding_option.id === option.id)
            // 選択オプションが存在し、アイテムがあるようなら０個のアイテムを除外する
            if (cart_option !== undefined && cart_option.cart_option_items) {
              cart_option.cart_option_items = cart_option.cart_option_items.filter(
                item => item.amount > 0
              )
            }
            // 選択オプションが存在し、０個の注文アイテムを除外した上でアイテムが選択されていれば True
            // ０個の注文アイテムについては選択後を選択を外すと格納される。
            return cart_option !== undefined && cart_option.cart_option_items.length > 0
            // 紐づくオプションが任意項目
          } else {
            return true
          }
        })
        return result
      }

      // カートに追加制御
      const isChecked = cartOptionItem => {
        // 選択済みのOptionがcartOptionItemが含まれるか
        return options.some(option =>
          option.cart_option_items.some(item => item.id === cartOptionItem.id)
        )
      }

      const allergyImages = []
      const allergies = newItem.allergy
        .filter(item => item !== '')
        .map(item => {
          let allergyName = ''
          switch (item) {
            case '1':
              allergyName = '卵'
              break
            case '2':
              allergyName = '乳成分'
              break
            case '3':
              allergyName = '小麦'
              break
            case '4':
              allergyName = 'そば'
              break
            case '5':
              allergyName = '落花生'
              break
            case '6':
              allergyName = 'かに'
              break
            case '7':
              allergyName = 'えび'
              break
            default:
              allergyName = 'その他'
              break
          }
          allergyImages.push({
            id: item,
            image_url: `/images/allergy-icon/allergy-${item}.png`,
            alt: `アレルギーアイコン: ${allergyName}`,
          })
        })
      const itemImages = []
      newItem.post_item_images.map(image =>
        itemImages.push({
          id: image.id,
          image_url: image.image_url,
        })
      )

      const FavoriteStarIcon = ({ isFavorite }) => (
        <div
          style={{
            position: 'absolute',
            top: '60px',
            right: '60px',
            color: '#ffcc33',
            fontSize: '36px',
            zIndex: 1000,
          }}
        >
          <FontAwesomeIcon icon={isFavorite ? solidStar : regularStar} />
        </div>
      )

      return (
        <S.CartModal>
          <S.Close onClick={window.globalModal.closeModal}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </S.Close>
          {itemImages.length === 0 ? (
            <S.ItemNoImageWrap>
              <>
                {props.user && <FavoriteStarIcon isFavorite={isItemFavorite} />}
                <S.NoImage>No Image</S.NoImage>
              </>
            </S.ItemNoImageWrap>
          ) : (
            <>
              {props.user && <FavoriteStarIcon isFavorite={isItemFavorite} />}
              <PostGallery postImages={itemImages} />
            </>
          )}
          <S.CartItemName>{newItem.name}</S.CartItemName>
          <S.Price>
            {formatPrice(newItem.retail_price)}
            {/* {formatPrice(newItem.price)}（税込価格 {formatPrice(newItem.retail_price)}） */}
          </S.Price>
          <S.ModalItemDescription>{parse(newItem.description)}</S.ModalItemDescription>
          {newItem.jan && <S.ModalItemJan>商品番号 : {newItem.jan}</S.ModalItemJan>}
          {/* アレルギーアイコン */}
          {allergies.length > 0 && (
            <S.AllergyImageWrap>
              {allergyImages.map((allergy, index) => {
                console.log('allergy', allergy)
                return (
                  <S.ModalAllergyImage key={index}>
                    <img
                      src={allergy.image_url}
                      alt={allergy.alt}
                      loading="lazy"
                      decoding="async"
                    />
                  </S.ModalAllergyImage>
                )
              })}
            </S.AllergyImageWrap>
          )}
          {/* オプション用アコーディオン */}
          <Accordion key={expandeds.join()} preExpanded={expandeds} allowZeroExpanded={true}>
            {newItem.post_options.map((option, index) => {
              return (
                <AccordionItem key={index} uuid={String(option.id)}>
                  <S.AccordionHead>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <p>{option.title}</p>
                        <p>{optionSelectItemName(option)}</p>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                  </S.AccordionHead>
                  {option.option_items.map((option_item, option_index) => {
                    return (
                      <AccordionItemPanel key={option_index}>
                        {option.is_multiple ? (
                          /* 複数選択可能なオプションの場合 */
                          <S.AccordionItemPanel>
                            <>
                              {isOutOfOptionStock(option_item) ? (
                                <span className="itemTitle">{option_item.title} (SOLD OUT)</span>
                              ) : (
                                <>
                                  <CheckBox
                                    name="option"
                                    defaultChecked={isChecked(option_item)}
                                    onChangeHandler={e =>
                                      addCheckedOptions(e, newItem, option, option_item, 1)
                                    }
                                  />
                                  <span className="itemTitle">{option_item.title}</span>
                                  <span>
                                    {formatPrice(option_item.retail_price)}
                                    {/* {formatPrice(option_item.price)}（税込価格
                                    {formatPrice(option_item.retail_price)}） */}
                                  </span>
                                </>
                              )}
                            </>
                          </S.AccordionItemPanel>
                        ) : (
                          /* 単数のみ選択可能なオプションの場合 */
                          <>
                            <S.AccordionItemPanel>
                              {isOutOfOptionStock(option_item) ? (
                                <span className="itemTitle">{option_item.title} (SOLD OUT)</span>
                              ) : (
                                <>
                                  <S.Radio>
                                    <label>
                                      <input
                                        type="radio"
                                        name={`option_${option.id}`}
                                        value=""
                                        data-insert-before={false}
                                        onClick={e =>
                                          addSelectOptionItem(e, newItem, option, option_item)
                                        }
                                        defaultChecked={isChecked(option_item)}
                                      />
                                      <span />
                                    </label>
                                  </S.Radio>
                                  <span className="itemTitle">{option_item.title}</span>
                                  <span className="itemPrice">
                                    {formatPrice(option_item.retail_price)}
                                    {/* {formatPrice(option_item.price)}（税込価格{' '}
                                    {formatPrice(option_item.retail_price)}） */}
                                  </span>
                                </>
                              )}
                            </S.AccordionItemPanel>
                          </>
                        )}
                      </AccordionItemPanel>
                    )
                  })}
                </AccordionItem>
              )
            })}
          </Accordion>
          {/* オプション用アコーディオン */}
          <S.AddCartWrapper>
            <Counter initValue={1} min={MIN} max={MAX} handleChange={addItemAmount} />
            <S.ButtonWrapper>
              <Button handleClick={window.globalModal.closeModal}>キャンセル</Button>
              {!props.user ? (
                <Button
                  primary={true}
                  handleClick={() => {
                    location.href = '/users/sign_in'
                    return
                  }}
                  backgroundColor={COLORS.Primary}
                  disabled={false}
                >
                  ログイン
                </Button>
              ) : (
                <Button
                  primary={true}
                  handleClick={addItem}
                  backgroundColor={COLORS.Primary}
                  disabled={!isAddCartAble(newItem)}
                >
                  {/* カートに追加 */}
                  {formatPrice(calcOptionItemsRetailAmount(newItem)) + ' カートに追加'}
                </Button>
              )}
            </S.ButtonWrapper>
          </S.AddCartWrapper>
        </S.CartModal>
      )
    }
    window.globalModal.showModal({
      title: null,
      body: <ModalBody />,
      closeText: null,
      submitText: null,
      handleSubmit: null,
    })
  }

  const handleSetCartItem = item => {
    if (isNotPublishedPostItem(item)) {
      return
    }

    if (window.dataLayer) {
      // GA商品詳細閲覧イベント
      window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
          items: [
            {
              item_name: item.name || 'Unknown',
              item_id: item.id || 'Unknown',
              price: item.settlement_price || 0,
            },
          ],
        },
      })
    }
    showCartDetailModal(item)
  }

  const isNotPublishedPostItem = (item: IPostItem) => {
    return item.aasm_state !== 'published'
  }

  const isOutOfStock = (item: IPostItem) => {
    return item.is_quantity_limited === true ? (item.remaining_stock <= 0 ? true : false) : false
  }

  function toBoolean(item) {
    return item.length > 0 && item.toLowerCase() === 'true'
  }

  const isOutOfOptionStock = (item: IOptionItem) => {
    return toBoolean(item.is_quantity_limited) === true
      ? item.remaining_stock <= 0
        ? true
        : false
      : false
  }

  const previewSettlementPriceTotal = () => {
    if (localCartItems.length === 0) {
      return 'カートに商品がありません'
    }
    return settlementPriceTotal ? formatPrice(settlementPriceTotal) : ''
  }

  const onChangePageHandler = React.useCallback(page => {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('page')) {
      searchParams.set('page', page)
    } else {
      searchParams.append('page', page)
    }
    location.href = `${location.pathname}?${searchParams.toString()}`
  }, [])

  const handleSearchSubmit = React.useCallback(async (initialValues, values) => {
    location.href = `${location.pathname}?${new URLSearchParams(values).toString()}`
  }, [])

  const handleOrderChange = e => {
    const order = e.currentTarget.value
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('order')) {
      searchParams.set('order', order)
    } else {
      searchParams.append('order', order)
    }
    location.href = `${location.pathname}?${searchParams.toString()}`
  }

  const FavoriteStar = ({ isFavorite }) => (
    <div
      style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        color: '#ffcc33',
        fontSize: '24px',
      }}
    >
      <FontAwesomeIcon icon={isFavorite ? solidStar : regularStar} />
    </div>
  )

  const toggleFavorite = async item => {
    let isMounted = true

    try {
      const csrfToken = document.querySelector<HTMLMetaElement>('meta[name="csrf-token"]')?.content
      const config = {
        headers: {
          'X-CSRF-Token': csrfToken,
          'Content-Type': 'application/json',
        },
      }

      let response
      if (item.is_favorite) {
        response = await axios.delete('/api/favorites', {
          data: {
            favorite: {
              user_id: props?.user?.id,
              post_item_id: item.id,
            },
          },
          ...config,
        })
      } else {
        response = await axios.post(
          '/api/favorites',
          {
            favorite: {
              user_id: props?.user?.id,
              post_item_id: item.id,
            },
          },
          config
        )
      }
      if (response.data.success) {
        const updatedItems = postItems.map(it => {
          if (it.id === item.id) {
            return { ...it, is_favorite: !it.is_favorite }
          }
          return it
        })
        setPostItems(updatedItems)
      }

      window.flashMessages.addMessage({
        text: response.data.flash.message,
        type: response.data.flash.type,
      })
    } catch (error) {
      console.error(error)
      const errorMessage =
        error.response && error.response.data ? error.response.data.error : 'エラーが発生しました'
      window.flashMessages.addMessage({
        text: errorMessage,
        type: 'error',
      })
    }

    return () => {
      isMounted = false
    }
  }

  return (
    <S.PostShow ref={scrollContainerRef}>
      {props.editable && (
        <S.Owner>
          <p>{I18n.t('post.it_is_your_post')}</p>
          <a href={`/posts/${props.post.slug}/edit`}>{I18n.t('generic.edit')}</a>
        </S.Owner>
      )}
      <S.Main>
        <S.Content>
          <S.Search>
            <Form fields={SEARCH_FIELDS} handleSubmit={handleSearchSubmit}>
              <S.SearchForm>
                <InputText
                  required={false}
                  name="keyword"
                  defaultValue={props.search_params.keyword || ''}
                  label=""
                  placeholder="キーワード"
                  width={300}
                />
                <input
                  type="hidden"
                  name="category_id"
                  value={props.search_params.category_id || ''}
                />
                <S.ButtonSearch>
                  <Button primary={true} small={true}>
                    検索
                  </Button>
                </S.ButtonSearch>
              </S.SearchForm>
            </Form>
            <CategorySlider large_categories_list={displayCategories} post={props.post} />
            <S.OrderForm>
              <S.SearchInfo>{searchCategory && <>カテゴリ: {searchCategory}</>}</S.SearchInfo>
              <S.SearchInfo>
                {props.search_params.keyword && <>キーワード: {props.search_params.keyword}</>}
              </S.SearchInfo>

              <S.SelectOrder>
                <label
                  htmlFor="order"
                  style={{
                    position: 'absolute',
                    width: '1px',
                    height: '1px',
                    padding: '0',
                    margin: '-1px',
                    overflow: 'hidden',
                    clip: 'rect(0, 0, 0, 0)',
                    border: '0',
                  }}
                >
                  並び替え
                </label>
                <Select
                  required={false}
                  name="order"
                  options={orderOptions()}
                  defaultValue={props.search_params.order || 'newest'}
                  onChangeHandler={handleOrderChange}
                />
              </S.SelectOrder>
            </S.OrderForm>
          </S.Search>
          <S.Section>
            {postItems.length > 0 ? (
              <S.Items>
                {postItems.map(item => {
                  return (
                    <S.Item key={item.id} className={isOutOfStock(item) && 'disabled'}>
                      <S.ItemImage onClick={() => handleSetCartItem(item)}>
                        {props.user && <FavoriteStar isFavorite={item.is_favorite ?? false} />}
                        {item.post_item_images.length === 0 ? (
                          <S.NoImage>No Image</S.NoImage>
                        ) : (
                          <img
                            src={item.post_item_images[0].image_url}
                            alt={item.name || '商品画像'}
                            loading="lazy"
                            decoding="async"
                          />
                        )}
                      </S.ItemImage>
                      <S.ItemInfo>
                        <S.ItemCategoryName>{item.item_category_name}</S.ItemCategoryName>
                        <S.ItemName>{item.name}</S.ItemName>
                        <S.ItemDescription>{parse(item.description)}</S.ItemDescription>
                        <S.ItemPrice>
                          {formatPrice(item.retail_price)}
                          {/* {formatPrice(item.price)}
                          <S.RetailPrice>税込価格 {formatPrice(item.retail_price)}</S.RetailPrice> */}
                        </S.ItemPrice>
                        <Button
                          primary={true}
                          disabled={!canOrderState}
                          handleClick={() => {
                            handleSetCartItem(item)
                          }}
                        >
                          {isOutOfStock(item)
                            ? 'SOLD OUT'
                            : item.aasm_state === 'published'
                            ? '商品詳細'
                            : '注文停止中'}
                        </Button>
                        {props.user && (
                          <S.FavoriteButton>
                            <Button
                              primary={false}
                              disabled={!canOrderState}
                              handleClick={() => {
                                toggleFavorite(item)
                              }}
                            >
                              {item.is_favorite ? 'お気に入り解除' : 'お気に入り追加'}
                            </Button>
                          </S.FavoriteButton>
                        )}
                      </S.ItemInfo>
                      {isNotPublishedPostItem(item) && <S.ItemDisabled />}
                    </S.Item>
                  )
                })}
              </S.Items>
            ) : (
              <div>お探しの商品はありません。</div>
            )}
            {pagination && (
              <Pagination
                onChangePageHandler={onChangePageHandler}
                currentPage={pagination.current_page}
                prevPage={pagination.prev_page}
                nextPage={pagination.next_page}
                totalPages={pagination.total_pages}
                totalCount={pagination.total_count}
              />
            )}
          </S.Section>
        </S.Content>
        <SideBar
          post={props.post}
          showReservationPanel={showReservationPanel}
          setShowReservationPanel={setShowReservationPanel}
          reservationErrors={reservationErrors}
          isSignedIn={props.isSignedIn}
          isRegisteredCard={props.isRegisteredCard}
          hasDeliveryAddress={props.hasDeliveryAddress}
          cartItems={localCartItems}
          ordable={ordable}
          available={available}
          settlementPriceTotal={settlementPriceTotal}
          priceTotal={priceTotal}
          taxTotal={taxTotal}
          updateCartItems={updateCartItems}
          createReservation={createReservation}
          couponCodes={couponCodes}
          setCouponCodes={setCouponCodes}
          setStoreCouponCode={setStoreCouponCode}
          couponValues={couponValues}
          storeCouponValue={storeCouponValue}
          user_already_used_coupon={false}
        />
      </S.Main>
      <S.Footer className={canOrderState ? '' : 'hidden'}>
        {/* {localCartItems.length > 0 && !ordable && (
          <S.FooterAlert>
            最低注文金額は{formatPrice(props.post.display_min_price)}円です
          </S.FooterAlert>
        )} */}
        {localCartItems.length > 0 && reservationErrors && !available && (
          <ul>
            {formatReservationError(reservationErrors).map((availabilityError, index) => {
              return (
                <S.FooterAlert key={index}>
                  {availabilityError.name}は残り在庫{availabilityError.in_stock}です
                </S.FooterAlert>
              )
            })}
          </ul>
        )}
        <S.FooterButtonBlock>
          {localCartItems.length > 0 && <S.FooterPrice>{localCartItems.length}商品</S.FooterPrice>}
          <S.FooterPrice>{previewSettlementPriceTotal()}</S.FooterPrice>
          <S.FooterButton>
            <Button
              disabled={!canOrderState}
              primary={true}
              handleClick={() => setShowReservationPanel(true)}
            >
              カートを見る
            </Button>
          </S.FooterButton>
        </S.FooterButtonBlock>
      </S.Footer>
    </S.PostShow>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.PostShow = styled.div``

S.CartModal = styled.div`
  min-height: 50%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  > p {
    width: 100%;
  }
`
S.CartItemName = styled.h3`
  flex-grow: 0;
  flex-basis: auto;
  font-size: 20px;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    font-size: 18px;
  }
`

S.AllergyImageWrap = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center:
`
S.ModalAllergyImage = styled.div`
  width: 65px;
  margin: 4px;
  flex-grow: 0;
  flex-basis: auto;
  > img {
    height: 60px;
    width: 60px;
    object-fit: contain;
  }
`

S.ModalItemImage = styled.div`
  display: flex;
  width: 100%;
  min-height: 240px;
  margin-bottom: 16px;
  flex-grow: 0;
  flex-basis: auto;
  > img {
    max-height: 320px;
    min-height: 240px;
    width: 100%;
    object-fit: contain;
    border-radius: 4px;

    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      max-height: 240px;
    }
  }
`

S.ModalItemDescription = styled.p`
  margin-bottom: 16px;
  flex-grow: 1;
  flex-basis: 88px;
  overflow-y: auto;
  font-size: 14px;
  color: #333333;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    font-size: 12px;
  }
`

S.ModalItemJan = styled.p`
  margin-bottom: 16px;
  font-size: 14px;
  color: #333333;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    font-size: 12px;
  }
`

S.AddCartWrapper = styled.div`
  flex-grow: 0;
  flex-basis: auto;
`
S.Price = styled.div`
  font-size: 18px;
  margin-bottom: 16px;
`

S.ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 58px;
  > button {
    height: 100%;
    font-size: 18px;
    margin: 0 12px;
    padding: 8px 30px;
  }
  > button:disabled {
    background-color: #f3f3f3;
    color: #fff;
  }
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    height: 40px;
    > button {
      font-size: 12px;
      padding: 8px;
    }
  }
`

S.FavoriteButton = styled.div`
  width: 100%;
  margin-top: 16px;
  margin-left: 0px;

  button {
    width: 100%;
  }
`

S.Owner = styled.div`
  display: flex;
  height: 48px;
  padding: 0 24px;
  align-items: center;
  background-color: #ff9d33;
  color: #fff;
  font-weight: bold;

  > a {
    color: #fff;
    text-decoration: underline;
    margin-left: 12px;
  }
`

S.Note = styled.div`
  white-space: pre-wrap;
  overflow: auto;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    max-height: 10em;
  }
`

S.Main = styled.div`
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
  padding: 32px 24px 0;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    padding: 0 24px 96px;
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    flex-wrap: wrap;
    padding: 0 12px 96px;
  }
`

S.Header = styled.div`
  display: flex;
  padding-bottom: 16px;
`

S.Content = styled.div`
  flex: 1;
  margin-right: 16px;
  line-height: 1.5;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    flex: initial;
    margin-right: 0;
  }
`

S.Section = styled.section`
  border-top: solid 1px ${COLORS.Border};
  padding: 32px 0 48px;

  > h3 {
    font-size: 20px;
  }
`

S.Footer = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 72px;
  background-color: #fff;
  border-top: solid 1px ${COLORS.Border};
  z-index: 100;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    display: block;
    height: fit-content;
    padding: 16px;
    &.hidden {
      display: none;
    }
  }
`

S.FooterAlert = styled.p`
  font-size: 14px;
  color: red;
  margin-bottom: 8px;
`

S.FooterPrice = styled.p`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 4px;
`

S.FooterButtonBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
S.FooterButton = styled.div`
  text-align: right;
`

S.Tags = styled.div`
  margin-bottom: 32px;

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
    overflow: hidden;
  }

  li {
    position: relative;
    display: flex;
    align-items: center;
    margin: 5px;
    padding: 8px 12px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    color: var(${THEME_COLOR_VARIABLE_NAME});
    border-radius: 3px;
    overflow: hidden;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      padding: 6px 8px;
      font-size: 12px;

      > i {
        font-size: 10px;
        margin-right: 4px;
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(${THEME_COLOR_VARIABLE_NAME});
      opacity: 0.12;
    }

    > i {
      font-size: 16px;
      margin-right: 8px;
    }
  }
`

S.Description = styled.div`
  white-space: pre-wrap;
  margin-bottom: 8px;
`

S.NotOpen = styled.div`
  display: flex;
  flex-shrink: 0;
  color: #888;
  margin-bottom: 16px;
  > b {
    font-weight: bold;
  }
`
S.Times = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    display: inline-flex;
    align-items: center;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      margin: 8px 0;
    }
  }
`
S.DeliveryTime = styled.div`
  flex-shrink: 0;
  margin-right: 16px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    margin-right: 8px;
  }
  > b {
    font-weight: bold;
  }
`

S.OpenTime = styled.div`
  flex-shrink: 0;
  margin-right: 16px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    margin-right: 8px;
  }
  > b {
    font-weight: bold;
  }
  p {
    font-size: 0.9rem;
  }
`
S.Phone = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`

S.Items = styled.section`
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
`
S.Item = styled.div`
  position: relative;
  width: 33.33333%;
  margin-bottom: 48px;
  overflow: hidden;
  padding: 0 15px;
  display: table;

  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    width: calc(50% - 5px);
    padding: 0 5px;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`
S.ItemDisabled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff99;
`

S.ItemInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 0;

  > button {
    width: 100%;
  }
`
S.ItemName = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  width: 100%;
  height: 40px;
  margin-top: 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`

S.ItemCategoryName = styled.p`
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  height: 40px;
  margin-top: 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: #333333;
  overflow: hidden;
`

S.ItemDescription = styled.p`
  width: 100%;
  font-size: 14px;
  height: 66px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  color: #333333;
  margin-top: 8px;
`
S.ItemPrice = styled.p`
  width: 100%;
  text-align: right;
  font-size: 22px;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 8px;
`
S.RetailPrice = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
`
S.ItemImage = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 160px;
  max-height: 250px;
  cursor: pointer;

  > img {
    width: 100%;
    height: 240px;
    object-fit: cover;
    border-radius: 4px;
  }

  &:hover {
    opacity: 0.75;
  }
`
S.Images = styled.div`
  position: relative;
`
S.ItemNoImageWrap = styled.div`
  height: 240px;
`

S.Image = styled.div`
  height: 520px;
  margin-bottom: 24px;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    height: 400px;
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    height: 320px;
  }

  > img {
    width: 100%;
    height: inherit;
    object-fit: cover;
  }
`

S.NoImage = styled.div`
  width: 100%;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
`

S.HeaderAside = styled.div`
  flex: 1;
`

S.Title = styled.h2`
  font-weight: bold;
  font-size: 24px;
`

S.HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 15px;
`

S.Place = styled.div`
  font-size: 16px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    font-size: 14px;
  }
`

S.Map = styled.div`
  margin-top: 16px;
  height: 440px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    height: 320px;
  }
`

S.ReviewScore = styled.div`
  margin: 24px 0 48px;
`

S.Host = styled.div`
  margin-top: 32px;

  > p {
    margin-top: 32px;
  }

  > .Button {
    margin-top: 24px;
  }
`

S.Avatar = styled.div`
  display: flex;
  align-items: center;
`

S.AvatarImage = styled.a`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 24px;
  overflow: hidden;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    width: 48px;
    height: 48px;
    margin-right: 12px;
  }

  > img {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
`

S.AvatarInfo = styled.div`
  flex: 1;

  h4 {
    font-size: 16px;
    margin-bottom: 4px;
  }

  > span {
    display: block;
  }
`

S.AccordionHead = styled.div`
  .accordion__heading {
    background-color: var(${THEME_COLOR_VARIABLE_NAME});
    color: white;
    text-align: center;
    padding: 8px;
    border-bottom: 1px solid #fff;
    .accordion__button {
      padding: 0px 16px;
      display: flex;
      justify-content: space-between;
      p {
        text-align: left;
      }
      @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
        padding: 0px 8px;
      }
    }
  }
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    font-size: 12px;
  }
`

S.AccordionItemPanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  border-top: 0;
  padding: 16px 32px;
  span {
    font-size: 16px;
    @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
      font-size: 12px;
    }
  }
  label {
    width: 30px;
  }
  span.itemTitle {
    margin: 0 auto 0 0;
  }
  span.itemPrice {
    text-align: right;
  }
`

S.Radio = styled.div`
  span {
    font-size: 16px;
  }
  > label {
    display: block;
    position: relative;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }

    input {
      opacity: 0;
      height: 0;
      width: 0;
    }

    input + span {
      font-size: 16px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }

      &::before {
        width: 18px;
        height: 18px;
        border: solid 2px #888;
        border-radius: 50%;
      }
    }

    input:checked + span {
      &::before {
        width: 18px;
        height: 18px;
        border: solid 2px #888;
        border-radius: 50%;
      }

      &::after {
        left: 4px;
        width: 10px;
        height: 10px;
        background-color: var(${THEME_COLOR_VARIABLE_NAME});
        border-radius: 50%;
      }
    }
  }

  > label + label {
    margin-top: 16px;
  }
`

S.Search = styled.div`
  margin-bottom: 20px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    margin-top: 20px;
  }
`

S.SearchForm = styled.div`
  display: flex;
  align-items: center;

  input {
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      width: auto;
    }
  }
`

S.ButtonSearch = styled.div`
  text-align: center;
  margin-left: 10px;
`

S.OrderForm = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    display: block;
  }
`

S.SearchInfo = styled.div`
  margin-right: 24px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    width: 100%;
    margin-bottom: 8px;
  }
`

S.SelectOrder = styled.div`
  margin-left: auto;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    width: 100%;
  }
`

S.Close = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`

export default PostShow
