import * as moment from 'moment'
import * as React from 'react'
import I18n from '../../../../core/i18n'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { adminService } from '../../../../core/services'
import { EditTable } from '../../../organisms'
import AdminLayoutIndex from '../_layouts/'

interface IProps {
  contacts: {
    contacts: IJsonResponse
  }
}

const COLUMNS = [
  {
    name: 'ID',
    field: 'id',
  },
  {
    name: '名前',
    field: 'name',
  },
  {
    name: 'メールアドレス',
    field: 'email',
  },
  {
    name: '電話番号',
    field: 'user_phone',
  },
  {
    name: '件名',
    field: 'subject',
  },
  {
    name: 'お問い合わせ内容',
    field: 'body',
  },
  {
    name: 'ユーザーID',
    field: 'user_id',
  },
  {
    name: 'お問い合わせ日時',
    field: record => (
      <span>{moment(record.created_at).format(I18n.t('reservation.date_format'))}</span>
    ),
  },
]

const AdminContactIndex: React.FC<IProps> = props => {
  const { data } = adminService.getDataFromJson(props.contacts.contacts)

  return (
    <AdminLayoutIndex
      model="contact"
      heading={I18n.t('activerecord.models.contact')}
      main={
        <EditTable
          editable={false}
          columns={COLUMNS}
          records={data}
          pagination={props.contacts.contacts.meta}
          getDetailLink={id => `/admin/contacts/${id}`}
        />
      }
    />
  )
}

export default AdminContactIndex
