import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../core/i18n'
import { IPager, IPost } from '../../core/interfaces'
import { postService } from '../../core/services'
import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_TABLET_LARGE,
  BREAKPOINT_TABLET_MOBILE,
  BREAKPOINT_TABLET_SMALL,
  COLORS,
  PAYMENT_REQUIRED,
  THEME_COLOR_VARIABLE_NAME,
} from '../../static/constants'
import Pagination from '../atoms/Pagination'
import Score from '../atoms/Score'
import Spinner from '../atoms/Spinner'

interface IPostListProps {
  loading?: boolean
  heading: string
  isSignedIn: boolean
  posts: IPost[]
  meta: IPager
  getPostsByPage?(page: number): void
}

const PostList: React.FC<IPostListProps> = ({
  loading,
  heading,
  isSignedIn,
  posts,
  meta,
  getPostsByPage,
}) => {
  return (
    <S.PostList className="PostList">
      <S.Main>
        {loading ? (
          <Spinner />
        ) : (
          <S.Inner>
            <S.Header>
              <S.Count>
                {!meta || !meta.total_count ? (
                  <span>{I18n.t('post.no_search_result')}</span>
                ) : (
                  <span>{heading}</span>
                )}
              </S.Count>
            </S.Header>
            <ul>
              {posts.map((post, index) => (
                <S.ListItem key={index}>
                  <a href={`/posts/${post.slug}`} target="_blank">
                    <S.ListItemHeader>
                      <S.ListItemImage>
                        {post.post_image && post.post_image.image_url ? (
                          <img
                            src={post.post_image.image_url}
                            alt=""
                            loading="lazy"
                            decoding="async"
                          />
                        ) : (
                          <S.ListItemNoImage>No Image</S.ListItemNoImage>
                        )}
                      </S.ListItemImage>
                      <S.ListItemInfo>
                        <S.ListItemInfoHeader>
                          <div>
                            <S.ListItemTitle>{post.name}</S.ListItemTitle>
                            {post.tags && (
                              <S.Tags>
                                {post.tags.map((tag, tagIndex) => (
                                  <S.Tag key={tagIndex}>
                                    <i className="material-icons">local_offer</i>
                                    {tag.name}
                                  </S.Tag>
                                ))}
                              </S.Tags>
                            )}
                          </div>
                        </S.ListItemInfoHeader>
                        <S.ListItemFooter>
                          {PAYMENT_REQUIRED && <S.Price>¥{post.price}</S.Price>}
                        </S.ListItemFooter>
                      </S.ListItemInfo>
                    </S.ListItemHeader>
                  </a>
                </S.ListItem>
              ))}
            </ul>
            {!meta || meta.total_pages <= 1 ? null : (
              <Pagination
                onChangePageHandler={getPostsByPage}
                currentPage={meta.current_page}
                prevPage={meta.prev_page}
                nextPage={meta.next_page}
                totalPages={meta.total_pages}
                totalCount={meta.total_count}
              />
            )}
          </S.Inner>
        )}
      </S.Main>
    </S.PostList>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.ListItem = styled.li`
  position: relative;
  margin-top: 16px;
  width: 20%;
  @media (max-width: 1280px) {
    width: 25%;
  }
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    width: 50%;
  }
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
  }

  > a {
    display: block;
    min-height: 200px;
    padding: 8px;
    overflow: hidden;
    color: ${COLORS.Text};
    border-radius: 4px;
    background-color: #fff;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      flex-direction: column;
    }
  }
`

S.ListItemHeader = styled.div`
  display: block;
  width: 100%;
`

S.ListItemDescription = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  border-top: solid 1px ${COLORS.Border};

  > p {
    font-size: 14px;
  }
`

S.ListItemImage = styled.div`
  position: relative;
  height: 200px;
  background-color: #f4f4f4;
  border-radius: 3px;
  overflow: hidden;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    width: 100%;
    height: 200px;
  }

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
`

S.ListItemNoImage = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
`

S.ListItemTitle = styled.h2`
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  font-size: 15px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    font-size: 14px;
  }
`

S.ListItemHeader = styled.div`
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  font-size: 15px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    font-size: 14px;
  }
`

S.ListItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  margin-top: 12px;
`

S.ListItemInfoHeader = styled.div`
  flex: 1;
`

S.Count = styled.div`
  font-size: 18px;
`

S.ListItemFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 16px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    margin-top: 12px;
  }
`

S.Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px -5px;
  color: var(${THEME_COLOR_VARIABLE_NAME});
  font-size: 13px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    font-size: 12px;
  }
`

S.Tag = styled.div`
  display: flex;
  align-items: center;
  margin: 0 5px;

  .material-icons {
    font-size: 14px;
    margin-right: 4px;
  }
`

S.Like = styled.div`
  position: absolute;
  top: 16px;
  right: 12px;
  width: 24px;
  height: 24px;
  margin-left: 12px;
  cursor: pointer;

  .material-icons {
    position: absolute;
    color: #fff;

    &.background {
      color: rgba(0, 0, 0, 0.35);
    }
  }
`

S.Price = styled.div`
  font-size: 14px;
  font-weight: bold;
`

S.PostList = styled.div`
  position: relative;
  display: flex;
  @media (max-width: ${BREAKPOINT_DESKTOP}px) {
    display: block;
  }

  .Pagination {
    height: 48px;
    border-top: solid 1px ${COLORS.Border};
  }
`

S.Main = styled.div`
  display: block;
  width: 100%;
  padding: 0 24px 12px;
  @media (max-width: ${BREAKPOINT_DESKTOP}px) {
    width: 100%;
    padding-bottom: 72px;
    position: relative;
    z-index: 900;
    display: block;
    background-color: #fff;
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    padding: 0 12px 72px;
  }
`

S.Inner = styled.div`
  max-width: 1520px;
  margin: auto;

  > ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    overflow: hidden;
  }
`

S.Header = styled.div`
  display: flex;
  margin-top: 12px;
`

export default PostList
