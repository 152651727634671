import { Button, Select } from 'components/atoms'
import { IRole } from 'core/interfaces'
import { postService } from 'core/services'
import moment, { Moment } from 'moment'
import * as React from 'react'
import styled from 'styled-components'
import {
  collapseTextChangeRangesAcrossMultipleVersions,
  createNoSubstitutionTemplateLiteral,
} from 'typescript'
import downloadCSV from 'utils/csv'
import { formatPrice } from 'utils/number'
import { BREAKPOINT_TABLET_SMALL, COLORS, HEADER_HEIGHT } from '../../../../static/constants'
import { Panel } from '../../../atoms'
import { formatSelectOptions } from '../../payments/index'
import Nav, { ActiveTypes } from '../Nav'

interface IProps {
  role?: IRole
  model: ActiveTypes

  posts: any
  payments: {
    ordered_count: any
    amount_per_day: any
    tax_per_day: any
    post_transfer_per_day: any
    price_without_tax_per_day: any
    admin_fees_per_day: any
    delivery_fees_per_day: any
    amounts_total: number
    post_transfer_amount_total: number
    price_without_tax_total: number
    admin_fees_total: number
    taxes_total: number
    delivery_fees_total: any
    coupons_count_used_per_day?: any
    coupons_total_value_per_day?: number
  }
}

const getCreatedAtQuery = (date: string) => {
  return `/admin/payments/index_by_date?created_at=${date}`
}

const TARGETS = [
  {
    label: '詳細',
    value: 'all',
  },
  {
    label: '期間内合計',
    value: 'total',
  },
]

const getFormattedMonth = (m: moment.Moment) => {
  return m.locale('ja').format('YYYY-MM')
}

const params = new URLSearchParams(location.search.substring(1))
const isShowAll = params.get('all') || false
const currentPostID = params.get('post_id') || ''
const currentMonth =
  params.get('month') ||
  moment()
    .locale('ja')
    .format('YYYY-MM')

const PaymentPage: React.FC<IProps> = props => {
  const [showTarget, setShowTarget] = React.useState<'all' | 'total'>('total')
  const [payments, setPatments] = React.useState(props.payments)

  const initPosts = postService.getPostsFromJson(props.posts.posts)
  const [posts, setPosts] = React.useState(formatSelectOptions(initPosts.posts))
  const [postId, setPostId] = React.useState<string>(currentPostID)
  const currentMoment = moment(currentMonth)
  const showTotal = showTarget === 'total'

  const keys = Object.keys(props.payments.ordered_count)

  const getQuery = (month: string | null = null) => {
    const byMonth = month ? `?month=${month}` : '?all=true'
    const byPost = postId ? `&post_id=${postId}` : ''
    return byMonth + byPost
  }
  const doFilter = (month: string | null = null) => {
    location.href = getQuery(month)
  }

  const showPrevMonth = () => {
    doFilter(getFormattedMonth(currentMoment.subtract(1, 'M')))
  }
  const showCurrentMonth = () => {
    doFilter(getFormattedMonth(moment()))
  }
  const showNextMonth = () => {
    doFilter(getFormattedMonth(currentMoment.add(1, 'M')))
  }
  const showAllMonth = () => {
    doFilter(null)
  }
  const getDataArray = (dateLabel): string[] => {
    return [
      dateLabel,
      payments.ordered_count[dateLabel]?.toString() || '',
      formatPrice(payments.amount_per_day[dateLabel] || 0),
      formatPrice(payments.post_transfer_per_day[dateLabel] || 0),
      formatPrice(payments.price_without_tax_per_day[dateLabel] || 0),
      formatPrice(payments.tax_per_day[dateLabel] || 0),
      formatPrice(payments.delivery_fees_per_day[dateLabel] || 0),
      // formatPrice(payments.admin_fees_per_day[dateLabel] || 0),
      payments.coupons_count_used_per_day[dateLabel]
        ? `${payments.coupons_count_used_per_day[dateLabel]}`
        : '0',
      formatPrice(
        payments.coupons_total_value_per_day[dateLabel]
          ? payments.coupons_total_value_per_day[dateLabel]
          : 0
      ),
    ]
  }

  const getBaseArray = (dateLabels): string[][] => {
    const labels = [
      '日付',
      '注文数',
      '売上合計',
      '商品代金合計(税込)',
      '商品代金合計(税抜)',
      '商品消費税合計',
      '配送料合計',
      // '決済手数料合計',
      'クーポン使用数',
      'クーポン割引額',
    ]
    return [labels, ...dateLabels.map(dateLabel => getDataArray(dateLabel))]
  }

  const getTotalBaseArray = (paymentsData): string[][] => {
    const labels = [
      '売上合計',
      '商品代金合計(税込)',
      '商品代金合計(税抜)',
      '商品消費税合計',
      '配送料合計',
      // '決済手数料合計',
    ]
    const data = [
      formatPrice(paymentsData.amounts_total),
      formatPrice(paymentsData.post_transfer_amount_total),
      formatPrice(paymentsData.price_without_tax_total),
      formatPrice(paymentsData.taxes_total),
      formatPrice(paymentsData.delivery_fees_total),
      // formatPrice(paymentsData.admin_fees_total),
    ]
    return [labels, data]
  }

  const getTotalName = () => {
    return '売上合計' + (isShowAll ? '全期間' : currentMoment.format('YYYY/MM'))
  }

  const getName = () => {
    return '売上一覧' + (isShowAll ? '全期間' : currentMoment.format('YYYY/MM'))
  }
  const isCurrentMonth = getFormattedMonth(currentMoment) === getFormattedMonth(moment())
  return (
    <LayoutIndex>
      <Nav active="payments" role={props.role} />
      <Wrapper>
        <Panel title="売上一覧" fit={true}>
          <div className="Wrapper_Header">
            <h2>{isShowAll ? '全期間' : currentMoment.format('YYYY/MM')}</h2>
          </div>
          <div className="Wrapper_Main">
            <div className="filters">
              <h3>期間</h3>
              <div className="buttons">
                <Button small={true} handleClick={showPrevMonth}>
                  前月
                </Button>
                <Button small={true} handleClick={showCurrentMonth}>
                  今月
                </Button>
                <Button small={true} handleClick={showNextMonth} disabled={isCurrentMonth}>
                  次月
                </Button>
                <Button small={true} handleClick={showAllMonth}>
                  全期間
                </Button>
              </div>
            </div>
            <h2>期間内合計</h2>
            <table>
              <thead>
                <tr>
                  <th>売上合計</th>
                  <th>商品代金合計(税込)</th>
                  <th>商品代金合計(税抜)</th>
                  <th>商品消費税合計</th>
                  <th>配送料合計</th>
                  {/* <th>決済手数料合計</th> */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{formatPrice(payments.amounts_total)}</td>
                  <td>{formatPrice(payments.post_transfer_amount_total)}</td>
                  <td>{formatPrice(payments.price_without_tax_total)}</td>
                  <td>{formatPrice(payments.taxes_total)}</td>
                  <td>{formatPrice(payments.delivery_fees_total)}</td>
                  {/* <td>{formatPrice(payments.admin_fees_total)}</td> */}
                </tr>
              </tbody>
            </table>
            <div className="buttons">
              <Button
                small={true}
                handleClick={() => downloadCSV(getTotalBaseArray(payments), getTotalName())}
              >
                CSVで保存
              </Button>
            </div>
            <h2>詳細</h2>
            <table>
              <thead>
                <tr>
                  <th>日付</th>
                  <th>注文数</th>
                  <th>売上合計</th>
                  <th>商品代金合計(税込)</th>
                  <th>商品代金合計(税抜)</th>
                  <th>商品消費税合計</th>
                  <th>配送料合計</th>
                  {/* <th>決済手数料合計</th> */}
                  <th>クーポン使用数</th>
                  <th>クーポン割引額</th>
                </tr>
              </thead>
              <tbody>
                {keys.map(key => (
                  <tr key={key}>
                    <td>
                      <a href={getCreatedAtQuery(key)}>{key}</a>
                    </td>
                    <td>{payments.ordered_count[key]}</td>
                    <td>{formatPrice(payments.amount_per_day[key] || 0)}</td>
                    <td>{formatPrice(payments.post_transfer_per_day[key] || 0)}</td>
                    <td>{formatPrice(payments.price_without_tax_per_day[key] || 0)}</td>
                    <td>{formatPrice(payments.tax_per_day[key] || 0)}</td>
                    <td>{formatPrice(payments.delivery_fees_per_day[key] || 0)}</td>
                    {/* <td>{formatPrice(payments.admin_fees_per_day[key] || 0)}</td> */}
                    <td>
                      {payments.coupons_count_used_per_day[key]
                        ? `${payments.coupons_count_used_per_day[key]}`
                        : '0'}
                    </td>
                    <td>
                      {formatPrice(
                        payments.coupons_total_value_per_day[key]
                          ? payments.coupons_total_value_per_day[key]
                          : 0
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="buttons">
              <Button
                small={true}
                disabled={keys.length === 0}
                handleClick={() => downloadCSV(getBaseArray(keys), getName())}
              >
                CSVで保存
              </Button>
            </div>
          </div>
        </Panel>
      </Wrapper>
    </LayoutIndex>
  )
}

const LayoutIndex = styled.div`
  display: flex;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  margin: 0 auto;
  background-color: #f6f8f9;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    display: block;
  }
`

const Wrapper = styled.div`
  flex: 1;
  padding: 16px;
  margin: 0 auto;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    font-size: 14px;
  }
  .Wrapper_Header {
    display: flex;
    align-items: center;
    height: 48px;
    border-bottom: solid 1px ${COLORS.Border};
  }

  .Header_Download {
    > button {
      margin: 8px;
    }
  }

  .select-label {
    margin-left: 16px;
  }

  .Wrapper_Main {
    overflow: scroll;
  }

  .filters {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 0;
    border-bottom: solid 1px ${COLORS.Border};

    h3 {
      width: 100%;
    }
  }

  .filter {
    padding: 8px 16px;
    max-width: 420px;
    display: flex;

    p {
      width: 140px;
    }
  }

  .buttons {
    padding: 8px;
    display: flex;
  }

  table {
    margin: 16px 0;
    text-align: center;
    border-bottom: solid 1px ${COLORS.Border};
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      font-size: 12px;
    }

    tr {
      th,
      td {
        padding: 4px 8px;
      }
    }
  }
`

export default PaymentPage
