import React from 'react'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { userService } from '../../../../core/services'
import { COLORS, THEME_COLOR_VARIABLE_NAME } from '../../../../static/constants'
import { Button, InputText } from '../../../atoms'
import { Form } from '../../../molecules'

const FIELDS = {
  email: 'email',
}

interface IState {
  email: string
  errors: any
}

class UserConfirmationsNew extends React.Component<{}, IState> {
  constructor(props) {
    super(props)
    this.state = {
      email: null,
      errors: {},
    }
  }

  public handleUpdateForm = (errors, hasNoError) => {
    this.setState({
      errors: {
        ...this.state.errors,
        ...errors,
      },
    })
  }

  public handleSubmit = async (initialValues, values) => {
    const { redirectUrl } = await userService.createConfirmation({ user: values })

    location.href = redirectUrl
  }

  public render() {
    return (
      <UserConfirmationsNewWrapper>
        <Panel>
          <Form
            fields={FIELDS}
            handleSubmit={this.handleSubmit}
            handleUpdateForm={this.handleUpdateForm}
          >
            <FormItem>
              <InputText
                required={true}
                name="email"
                defaultValue=""
                label={I18n.t('generic.email')}
                placeholder="Email"
                error={this.state.errors.email}
              />
            </FormItem>
            <Buttons>
              {this.state.errors.login && <span className="error">{this.state.errors.login}</span>}
              <Button primary={true} backgroundColor={'#FF923E'}>
                {I18n.t('generic.send')}
              </Button>
              {/* <p>
                <a href="/users/sign_up">{I18n.t('generic.signup')}</a>
              </p> */}
            </Buttons>
          </Form>
        </Panel>
      </UserConfirmationsNewWrapper>
    )
  }
}

const UserConfirmationsNewWrapper = styled.main`
  padding-top: 36px;

  .error {
    color: ${COLORS.Danger};
    display: block;
    margin-bottom: 12px;
  }
`

const Buttons = styled.div`
  margin-top: 24px;
  text-align: center;

  > p {
    display: block;
    margin: 16px auto 0;

    a {
      color: var(${THEME_COLOR_VARIABLE_NAME});
      text-decoration: underline;
    }
  }

  .Button {
    display: block;
    margin: 0 auto;
    width: 160px;
    height: 40px;
    font-weight: bold;
    font-size: 16px;
  }
`

const Panel = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
  max-width: 600px;
  margin: 0 auto;
  padding: 24px;
`

const FormItem = styled.div`
  & + & {
    margin-top: 16px;
  }
`

export default UserConfirmationsNew
