import moment from 'moment'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../../core/i18n'
import { IReservation, IWindow } from '../../../../../core/interfaces'
import { reservationService } from '../../../../../core/services'
import { DAYS, HOURS, MINUTES } from '../../../../../static/constants'
import { Button, InputText, Select } from '../../../../atoms'
import { InputDateTime } from '../../../../molecules'
import { Form } from '../../../../molecules'

declare var window: IWindow

interface IDeliveryDate {
  DD: string
  HH: string
  mm: string
}
interface IProps {
  newStatus: string
  reservationId: number

  timeSpecifiedFlag?: false | boolean
  deliveryTime: number
  specifiedDeliveryTime: string
  workFlow?: null | string
  setReservation(reservaton: any): void
}

const APPROVE_FIELDS = {
  cooking_time: 'cooking_time',
}

const ApproveModal: React.FC<IProps> = props => {
  const [newStatus, setNewStatus] = React.useState<string>(props.newStatus)
  const [modalErrors, setModalErrors] = React.useState<{ [key: string]: null | string }>({
    reason: null,
  })
  const [isProcessing, setIsProcessing] = React.useState(false)
  const [submittable, setSubmittable] = React.useState(false)
  const [selectedPickUpTime, setSelectedPickUpTime] = React.useState<moment.Moment | null>(
    moment(props.specifiedDeliveryTime)
  )

  const formatSelectOptions = options => {
    return options.map((option: { id: number; name: string }) => ({
      value: option.id,
      label: option.name,
    }))
  }

  const handleSubmit = async (initialValues, value) => {
    if (newStatus === 'approved') {
      const params = {
        reservation_approve: value,
      }
      setNewStatus('approved')
      const { reservation, flush } = await reservationService.approveReservation(
        props.reservationId,
        params
      )
      props.setReservation(reservation)
      window.globalModal.closeModal()
      window.flashMessages.addMessage({ text: flush.message, type: flush.type })
    }
  }

  const getOptionsData = name => {
    switch (name) {
      case 'cooking_time':
        return MINUTES
    }
  }
  const formatOptions = name => {
    const optionsData = getOptionsData(name)
    return optionsData.map(data => {
      return { value: data, label: `${data}` }
    })
  }

  return (
    <S.ModalWrapper>
      <>
        <Form
          fields={APPROVE_FIELDS}
          handleUpdateForm={updatedErrors => {
            setModalErrors(updatedErrors)
          }}
          handleSubmit={handleSubmit}
        >
          <S.Footer>
            {!submittable && (
              <Button
                primary={false}
                disabled={isProcessing}
                handleClick={() => setSubmittable(true)}
              >
                {newStatus === 'approved' && I18n.t('reservation.approved')}
              </Button>
            )}
            {submittable && (
              <>
                <p>
                  {newStatus === 'approved' && '受注完了'}
                  してよろしいですか？
                </p>
                <Button primary={true} disabled={isProcessing}>
                  {newStatus === 'approved' && I18n.t('reservation.approved')}
                </Button>
              </>
            )}
          </S.Footer>
        </Form>
      </>
    </S.ModalWrapper>
  )
}
const S: { [key: string]: AnyStyledComponent } = {}
S.ModalWrapper = styled.div``

S.Footer = styled.div`
  text-align: center;
  margin-top: 20px;
`
export default ApproveModal
