import * as moment from 'moment'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { adminService } from '../../../../core/services'
import { BREAKPOINT_TABLET_SMALL } from '../../../../static/constants'
import { Button } from '../../../atoms'
import AdminLayoutIndex from '../_layouts/'

interface IProps {
  contact: IJsonResponse
}

const AdminContactShow: React.FC<IProps> = props => {
  const { data: contact } = adminService.getDataFromJson(props.contact)

  return (
    <AdminLayoutIndex
      model="contact"
      heading={I18n.t('activerecord.models.contact')}
      main={
        <S.Settings>
          <dl>
            <dt>ID</dt>
            <dd>{contact.id}</dd>
            <dt>お問い合わせ日時</dt>
            <dd>{moment(contact.created_at).format(I18n.t('reservation.date_format'))}</dd>
            <dt>名前</dt>
            <dd>{contact.name}</dd>
            <dt>メールアドレス</dt>
            <dd>{contact.email}</dd>
            <dt>電話番号</dt>
            <dd>{contact.user_phone}</dd>
            <dt>件名</dt>
            <dd>{contact.subject}</dd>
            <dt>お問い合わせ内容</dt>
            <dd dangerouslySetInnerHTML={{ __html: contact.body.replace(/\n/g, '<br />') }} />
          </dl>
          <S.Buttons>
            <Button>
              <a href="/admin/contacts">
                <span className="material-icons">keyboard_arrow_left</span>
                {I18n.t('generic.back_to_index')}
              </a>
            </Button>
          </S.Buttons>
        </S.Settings>
      }
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Settings = styled.div`
  dl {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      flex-direction: column;
    }
  }

  dt {
    width: 240px;
    margin-top: 24px;
    font-weight: bold;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      width: 100%;
      margin-top: 12px;
    }
  }

  dd {
    width: calc(100% - 240px);
    margin-top: 24px;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      width: 100%;
      margin-top: 12px;
    }
  }
`

S.Buttons = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 48px;

  .Button[type='submit'] {
    width: 160px;
  }
`

export default AdminContactShow
