import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { IPager, IWindow } from '../../../../core/interfaces'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { getMergedFilterParams, getMergedFilterQueryString } from '../../../../core/queryString'
import { postService } from '../../../../core/services'
import AreaSelect from '../../../organisms/AreaSelect'
import PostListWithoutMap from '../../../organisms/PostListWithoutMap'
import Header from './Header'

declare var window: IWindow

interface IProps {
  isSignedIn: boolean
  favorite: boolean
  posts: any
  meta: IPager
  // tags: IJsonResponse
}

const urlParams = new URLSearchParams(window.location.search)
const FILTER_FIELDS = ['area', 'min_price', 'max_price', 'tag_ids']

const searchPost = async baseParams => {
  const keywords = urlParams.get('keywords')
  const params = { ...baseParams }

  if (keywords) {
    params.keywords = keywords
  }

  return postService.search(params)
}

const usePosts = ({ initialPosts = [], initialPagination = null }) => {
  const keyword = urlParams.get('keywords')
  const [loading, setLoading] = React.useState(false)
  const [posts, setPosts] = React.useState(initialPosts)
  const [pagination, setPagination] = React.useState(initialPagination)

  const getPostsByPage = async page => {
    setLoading(true)
    const result = await searchPost({ page })

    setPosts(result.posts)
    setPagination(result.pagination)
    setLoading(false)
  }

  const handleOnFilterSubmit = async filterValue => {
    setLoading(true)

    const filterParams = getMergedFilterParams(FILTER_FIELDS, filterValue)
    const filterQueryString = getMergedFilterQueryString(FILTER_FIELDS, filterParams)
    const result = await searchPost({ ...filterParams })

    setPosts(result.posts)
    setPagination(result.pagination)
    setLoading(false)
    history.replaceState(null, null, `?${filterQueryString}`)
  }

  return {
    loading,
    setLoading,
    posts,
    getPostsByPage,
    pagination,
    handleOnFilterSubmit,
  }
}

const PostSearchWithoutMap: React.FC<IProps> = props => {
  // show area select panels
  if (!urlParams.get('area') && !urlParams.get('keywords') && !props.favorite) {
    location.href = 'https://todoxi.jp/posts/search?area=%E6%9C%AD%E5%B9%8C'
    // return <AreaSelect />
  } else {
    return <PostSearchIndex {...props} />
  }
}

export const PostSearchIndex: React.FC<IProps> = props => {
  const { posts: initialPosts, pagination: initialPagination } = React.useMemo(
    () => postService.getPostsFromJson(props.posts.posts),
    []
  )
  const defaultKeyword = React.useMemo(() => urlParams.get('keywords'), [])
  const { loading, posts, getPostsByPage, pagination, handleOnFilterSubmit } = usePosts({
    initialPosts,
    initialPagination,
  })

  return (
    <S.Wrapper>
      {/* {!props.favorite && <Header handleOnFilterSubmit={handleOnFilterSubmit} tags={props.tags} />} */}
      <PostListWithoutMap
        loading={loading}
        isSignedIn={props.isSignedIn}
        posts={posts}
        meta={pagination}
        getPostsByPage={getPostsByPage}
        heading={'検索結果'}
      />
    </S.Wrapper>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Wrapper = styled.div`
  margin: 0 auto;
`

export default PostSearchWithoutMap
