import * as React from 'react'
import Select from 'react-select'
import styled, { AnyStyledComponent } from 'styled-components'
import { COLORS } from '../../static/constants'
import FormItem from './_layouts/FormItem'

interface IProps {
  name: string
  options: any[]
  defaultValue?: any
  initialText?: string
  label?: null | string
  error?: null | string
  required?: boolean
  selectRef?: any
  onChangeHandler?(event): any
  onBlurHandler?(event): any
}

const SelectWithSearch: React.FC<IProps> = ({
  name,
  options,
  defaultValue,
  initialText,
  label,
  error,
  required,
  selectRef,
  onChangeHandler,
  onBlurHandler,
}) => {
  return (
    <FormItem label={label} required={required} error={error}>
      <Select name={name} options={options} defaultValue={defaultValue} />
    </FormItem>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Select = styled.select<{ width?: number }>`
  appearance: none;
  margin: 0;
  padding: 0;
  background: none transparent;
  vertical-align: middle;
  font-size: inherit;
  color: inherit;
  outline: none;

  width: ${({ width }) => (width ? `${width}px` : '100%')};
  border: solid 1px ${COLORS.Border};
  border-radius: 4px;
  padding: 8px 12px;
`

export default SelectWithSearch
