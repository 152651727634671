import * as React from 'react'
import Slider from 'react-slick'
import styled, { AnyStyledComponent } from 'styled-components'
import {
  BREAKPOINT_TABLET_LARGE,
  BREAKPOINT_TABLET_MOBILE,
  THEME_COLOR_VARIABLE_NAME,
} from '../../../../static/constants'

interface IPostGalleryProps {
  postImages: any[]
}

const PostGallery: React.FC<IPostGalleryProps> = props => {
  const [currenImage, setCurrentImage] = React.useState(0)
  const main = React.useRef(null)
  const nav = React.useRef(null)
  const mainSettings = {
    arrows: false,
    dots: false,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentImage(newIndex)
      nav.current.slickGoTo(newIndex)
    },
  }
  const subSettings = {
    arrows: false,
    dots: false,
    centerMode: true,
    focusOnSelect: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentImage(newIndex)
      main.current.slickGoTo(newIndex)
    },
  }

  return (
    <S.Gallery>
      <div>
        <Slider {...mainSettings} ref={main}>
          {props.postImages.map(image => (
            <S.Main key={image.id}>
              <img
                src={image.image_url}
                alt={image.description || 'サムネイル画像'}
                loading="lazy"
                decoding="async"
              />
              {image.description && <S.Description>{image.description}</S.Description>}
            </S.Main>
          ))}
        </Slider>
      </div>
      <S.Nav>
        <Slider {...subSettings} ref={nav}>
          {props.postImages.map((image, index) => (
            <S.NavImage key={image.id} active={index === currenImage}>
              <div>
                <img
                  src={image.image_url}
                  alt={image.description || 'サムネイル画像'}
                  loading="lazy"
                  decoding="async"
                />
              </div>
            </S.NavImage>
          ))}
        </Slider>
      </S.Nav>
    </S.Gallery>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Gallery = styled.div`
  height: 624px;
  margin: 0 0 48px;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    height: 524px;
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    height: 424px;
  }
`

S.Main = styled.div`
  position: relative;
  width: 100%;
  height: 480px;
  outline: none;
  background-color: #fafafa;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    height: 400px;
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    height: 320px;
  }

  img {
    width: 100%;
    height: inherit;
    object-fit: contain;
  }
`

S.Nav = styled.div`
  margin-top: 12px;
  height: 120px;
  overflow: hidden;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    height: 100px;
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    height: 60px;
  }
`

S.NavImage = styled.div<{ active: boolean }>`
  width: 100%;
  height: 120px;
  padding: 0 6px;
  outline: none;
  cursor: pointer;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    height: 100px;
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    height: 60px;
  }

  > div {
    position: relative;
    height: inherit;
    border-radius: 4px;
    overflow: hidden;
    opacity: 0.65;
  }

  img {
    width: 100%;
    height: 120px;
    object-fit: contain;
    @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
      height: 100px;
    }
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      height: 60px;
    }
  }

  ${({ active }) =>
    active &&
    `
    > div {
      opacity: 1;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        border: solid 2px var(${THEME_COLOR_VARIABLE_NAME});
      }
    }
  `}
`

S.Description = styled.p`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 8px 12px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.65);
`

export default PostGallery
