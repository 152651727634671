import * as React from 'react'
import I18n from '../../../../core/i18n'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { adminService } from '../../../../core/services'
import { InputText, Spacer } from '../../../atoms'
import AdminLayoutEdit from '../_layouts/edit'

interface IProps {
  tag: IJsonResponse
}

const FIELDS = {
  id: 'id',
  name: 'name',
  position: 'position',
}

const title = I18n.t('admin.edit', {
  model: I18n.t('tag', { scope: 'activerecord.models' }),
})

const AdminTagEdit: React.FC<IProps> = props => {
  const { data } = adminService.getDataFromJson(props.tag)
  const handleSubmit = React.useCallback(async (initialValues, values) => {
    const { updatedTag, flush } = await adminService.updateTag(values)
    // TODO 遷移先でのFlush
    location.href = '/admin/tags'
  }, [])

  return (
    <AdminLayoutEdit
      model="tag"
      title={title}
      fields={FIELDS}
      indexLink="/admin/tags"
      formItems={
        <>
          <InputText readonly={true} name="id" defaultValue={data.id} label="ID" />
          <Spacer />
          <InputText required={true} name="name" defaultValue={data.name} label="タグ名" />
          <Spacer />
          <InputText required={true} name="position" defaultValue={data.position} label="表示順" />
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

export default AdminTagEdit
