import * as React from 'react'
import Modal from 'react-modal'
import styled, { AnyStyledComponent } from 'styled-components'
import {
  BREAKPOINT_TABLET_LARGE,
  BREAKPOINT_TABLET_MOBILE,
  BREAKPOINT_TABLET_SMALL,
  COLORS,
  THEME_COLOR_VARIABLE_NAME,
} from '../../static/constants'
import Spinner from '../atoms/Spinner'

interface IProps {
  post_id: string
  image_url: string
}

const LimitedPostModal: React.FC<IProps> = props => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState(null)

  const handleLinkClick = e => {
    e.preventDefault()
    setModalIsOpen(true)
  }

  const handlePasswordChange = e => {
    setPassword(e.target.value)
  }

  const handlePasswordSubmit = async () => {
    setLoading(true)
    setError(null)
    const response = await fetch(
      `/api/check_limited_post_password?post_id=${props.post_id}&password=${password}`
    )
    const data = await response.json()
    console.log(data)
    if (data.success) {
      setLoading(false)
      setModalIsOpen(false)
      window.location.href = data.url
    } else {
      setLoading(false)
      setError('パスワードが違います')
    }
  }

  return (
    <div>
      <a href="#" aria-label="店舗ページ" className="limited-access-link" onClick={handleLinkClick}>
        <div style={{ position: 'relative' }}>
          <img src={props.image_url} alt="" loading="lazy" />
          <span
            style={{
              position: 'absolute',
              top: '16px',
              right: '16px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              padding: '8px',
              borderRadius: '4px',
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            限定公開
          </span>
        </div>
      </a>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          },
          content: {
            position: 'static',
          },
        }}
      >
        {loading ? (
          <Spinner />
        ) : (
          <S.LimitedPostForm>
            <div className="LimitedPost_Label">
              <label>限定公開です。</label>
              <label>パスワードを入力してください。</label>
            </div>
            <div className="LimitedPost_Input">
              <input placeholder="パスワード" value={password} onChange={handlePasswordChange} />
            </div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div className="LimitedPost_Button">
              <S.Buttons>
                <button className="Button" onClick={handlePasswordSubmit}>
                  確認
                </button>
                <button className="Button" onClick={() => setModalIsOpen(false)}>
                  キャンセル
                </button>
              </S.Buttons>
            </div>
          </S.LimitedPostForm>
        )}
      </Modal>
    </div>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.LimitedPostForm = styled.div`
  width: 100%;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    max-width: 280px;
  }
  .LimitedPost_Label {
    width: 100%;
    margin-bottom: 24px;
  }

  .LimitedPost_Input {
    width: 100%;
    margin-bottom: 24px;
    input {
      display: block;
      width: 100%;
      padding: 8px 12px;
      border: solid 1px #eaedef;
      border-radius: 4px;
      font-size: 15px;
      -webkit-transition: border 0.2s ease;
      transition: border 0.2s ease;
      outline: none;
      background: #fff;
    }
  }

  .LimitedPost_Button {
    width: 100%;
  }
`

S.Buttons = styled.div`
  text-align: center;

  > p {
    display: block;
    margin: 16px auto 0;

    a {
      color: var(${THEME_COLOR_VARIABLE_NAME});
      text-decoration: underline;
    }
  }

  .Button {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 35px;
    font-weight: bold;
    font-size: 16px;
    padding: 0 16px;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    line-height: 1;
    background-color: var(--theme-color);
    color: #fff;
    border: none;
    margin-top: 16px;
  }
`

export default LimitedPostModal
