import React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import * as constants from '../../static/constants'

interface IProps {
  primary?: boolean
  updateFile(file: any): void
}

const FileSelectUploader: React.FC<IProps> = props => {
  return (
    <S.FileSelectUploaderWrapper>
      <label htmlFor="fileSelectUploader">
        画像を変更
        <input
          id="fileSelectUploader"
          type="file"
          accept="image/png, image/jpeg, image/jpg, image/webp"
          onChange={event => {
            props.updateFile(event.currentTarget.files[0])
          }}
        />
      </label>
    </S.FileSelectUploaderWrapper>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.FileSelectUploaderWrapper = styled.div<IProps>`
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  margin-bottom: 12px;
  background-color: var(${constants.THEME_COLOR_VARIABLE_NAME});
  color: #fff;
  border: none;

  > a {
    color: #fff;
  }

  input {
    display: none;
  }
`

export default FileSelectUploader
