import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class AdminService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getDataFromJson(JsonData) {
    const data = this.serializer.parseResourceData(JsonData, JsonData.data)
    const pagination = { ...JsonData.meta }

    return { data, pagination }
  }

  public async createCategory(category, image, icon) {
    const formData = new FormData()
    if (image?.file) {
      formData.append('category[category_image_attributes][image]', image.file)
    }
    if (icon?.file) {
      formData.append('category[category_icon_attributes][image]', icon.file)
    }
    Object.keys(category).forEach(key => {
      formData.append(`category[${key}]`, category[key])
    })

    const { data } = await this.httpClient.post(`/admin/api/categories`, formData)
    const createdCategory = this.serializer.parseResourceData(data.category, data.category.data)

    return { createdCategory, flush: data.flush }
  }

  public async updateCategory(category, image, icon) {
    const formData = new FormData()
    if (image?.file) {
      formData.append('category[category_image_attributes][image]', image.file)
    }
    if (icon?.file) {
      formData.append('category[category_icon_attributes][image]', icon.file)
    }
    Object.keys(category).forEach(key => {
      formData.append(`category[${key}]`, category[key])
    })

    const { data } = await this.httpClient.patch(`/admin/api/categories/${category.id}`, formData)
    const updatedCategory = this.serializer.parseResourceData(data.category, data.category.data)

    return { updatedCategory, flush: data.flush }
  }

  public async deleteCategory(id) {
    const { data } = await this.httpClient.delete(`/admin/api/categories/${id}`)

    return { flush: data.flush }
  }

  public async createTag(tag) {
    const { data } = await this.httpClient.post('/admin/api/tags/', { tag })
    const createdTag = this.serializer.parseResourceData(data.tag, data.tag.data)

    return { createdTag, flush: data.flush }
  }

  public async updateTag(tag) {
    const { data } = await this.httpClient.patch(`/admin/api/tags/${tag.id}`, { tag })
    const updatedTag = this.serializer.parseResourceData(data.tag, data.tag.data)

    return { updatedTag, flush: data.flush }
  }

  public async deleteTag(id) {
    return this.httpClient.delete(`/admin/api/tags/${id}`)
  }

  public async createPost(post) {
    const { data } = await this.httpClient.post(`/admin/api/posts`, post)
    const createdPost = this.serializer.parseResourceData(data.post, data.post.data)

    return { createdPost, flush: data.flush }
  }

  public async updatePost(post) {
    const { data } = await this.httpClient.patch(`/admin/api/posts/${post.id}`, { post })
    const updatedPost = this.serializer.parseResourceData(data.post, data.post.data)

    return { updatedPost, flush: data.flush }
  }

  public async deletePost(id) {
    return await this.httpClient.delete(`/admin/api/posts/${id}`)
  }

  public async createUser(user) {
    const { data } = await this.httpClient.post('/admin/api/users', user)
    const createdUser = this.serializer.parseResourceData(data.user, data.user.data)

    return { createdUser, flush: data.flush }
  }

  public async updateUser(user) {
    const { data } = await this.httpClient.patch(`/admin/api/users/${user.user.id}`, user)
    const updatedUser = this.serializer.parseResourceData(data.user, data.user.data)

    return { updatedUser, flush: data.flush }
  }

  public async deleteUser(id) {
    const {
      data: { flush },
    } = await this.httpClient.delete(`/admin/api/users/${id}`)

    return { flush }
  }

  public async becomeUser(id) {
    const { data } = await this.httpClient.post(`/admin/api/users/${id}/become`)

    const becameUser = this.serializer.parseResourceData(data.user, data.user.data)

    return { becameUser, flush: data.flush }
  }

  public async createCoupon(coupon) {
    const { data } = await this.httpClient.post(`/admin/api/coupons`, { coupon })
    const createdCoupon = this.serializer.parseResourceData(data.coupon, data.coupon.data)

    return { createdCoupon, flush: data.flush }
  }

  public async updateCoupon(coupon) {
    const { data } = await this.httpClient.patch(`/admin/api/coupons/${coupon.id}`, {
      coupon,
    })
    const updatedCoupon = this.serializer.parseResourceData(data.coupon, data.coupon.data)

    return { updatedCoupon, flush: data.flush }
  }

  public async deleteCoupon(id) {
    const { data } = await this.httpClient.delete(`/admin/api/coupons/${id}`)

    return { flush: data.flush }
  }

  public async createInfo(info, image) {
    const formData = new FormData()
    if (image?.file) {
      formData.append('info[info_image_attributes][image]', image.file)
    }
    Object.keys(info).forEach(key => {
      formData.append(`info[${key}]`, info[key])
    })

    const { data } = await this.httpClient.post(`/admin/api/infos`, formData)
    const createdInfo = this.serializer.parseResourceData(data.info, data.info.data)

    return { createdInfo, flush: data.flush }
  }

  public async updateInfo(info, image) {
    const formData = new FormData()
    if (image?.file) {
      formData.append('info[info_image_attributes][image]', image.file)
    }
    Object.keys(info).forEach(key => {
      formData.append(`info[${key}]`, info[key])
    })
    const { data } = await this.httpClient.patch(`/admin/api/infos/${info.id}`, formData)
    const updatedInfo = this.serializer.parseResourceData(data.info, data.info.data)
    return { updatedInfo, flush: data.flush }
  }

  public async deleteInfo(id) {
    const { data } = await this.httpClient.delete(`/admin/api/infos/${id}`)

    return { flush: data.flush }
  }

  public async getPosts() {
    const { data } = await this.httpClient.get('/admin/api/posts')
    const postList = this.serializer.parseResourceData(data.posts, data.posts.data)

    return { postList }
  }

  public async createItemCategory(item_category, image) {
    const formData = new FormData()
    if (image?.file) {
      formData.append('item_category[item_category_image_attributes][image]', image.file)
    }

    Object.keys(item_category).forEach(key => {
      formData.append(`item_category[${key}]`, item_category[key])
    })

    const { data } = await this.httpClient.post(`/admin/api/item_categories`, formData)
    const createdItemCategory = this.serializer.parseResourceData(
      data.item_category,
      data.item_category.data
    )

    return { createdItemCategory, flush: data.flush }
  }

  public async updateItemCategory(item_category, image) {
    const formData = new FormData()
    if (image?.file) {
      formData.append('item_category[item_category_image_attributes][image]', image.file)
    }

    Object.keys(item_category).forEach(key => {
      formData.append(`item_category[${key}]`, item_category[key])
    })

    const { data } = await this.httpClient.patch(
      `/admin/api/item_categories/${item_category.id}`,
      formData
    )
    const updatedItemCategory = this.serializer.parseResourceData(
      data.item_category,
      data.item_category.data
    )

    return { updatedItemCategory, flush: data.flush }
  }

  public async deleteItemCategory(id) {
    const { data } = await this.httpClient.delete(`/admin/api/item_categories/${id}`)

    return { flush: data.flush }
  }

  public async createEvent(event, image) {
    const formData = new FormData()
    if (image?.file) {
      formData.append('event[event_image_attributes][image]', image.file)
    }
    Object.keys(event).forEach(key => {
      formData.append(`event[${key}]`, event[key])
    })

    const { data } = await this.httpClient.post(`/admin/api/events`, formData)
    const createdEvent = this.serializer.parseResourceData(data.event, data.event.data)

    return { createdEvent, flush: data.flush }
  }

  public async updateEvent(event, image) {
    const formData = new FormData()
    if (image?.file) {
      formData.append('event[event_image_attributes][image]', image.file)
    }
    Object.keys(event).forEach(key => {
      formData.append(`event[${key}]`, event[key])
    })
    const { data } = await this.httpClient.patch(`/admin/api/events/${event.id}`, formData)
    const updatedEvent = this.serializer.parseResourceData(data.event, data.event.data)
    return { updatedEvent, flush: data.flush }
  }

  public async deleteEvent(id) {
    const { data } = await this.httpClient.delete(`/admin/api/events/${id}`)

    return { flush: data.flush }
  }
}
export default AdminService
