import * as React from 'react'
import I18n from '../../../../core/i18n'
import { adminService } from '../../../../core/services'
import { InputText, Spacer } from '../../../atoms'
import AdminLayoutNew from '../_layouts/new'

const FIELDS = {
  name: 'name',
  position: 'position',
}

const title = I18n.t('admin.create_new', {
  model: I18n.t('tag', { scope: 'activerecord.models' }),
})

const AdminTagNew: React.FC<{}> = props => {
  const handleSubmit = React.useCallback(async (initialValues, values) => {
    const { createdTag, flush } = await adminService.createTag(values)
    // TODO 遷移先でのFlush
    location.href = '/admin/tags'
  }, [])

  return (
    <AdminLayoutNew
      model="tag"
      title={title}
      fields={FIELDS}
      indexLink="/admin/tags"
      formItems={
        <>
          <InputText required={true} name="name" defaultValue="" label="タグ名" />
          <Spacer />
          <InputText required={true} name="position" defaultValue="" label="表示順" />
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

export default AdminTagNew
