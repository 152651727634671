import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { BREAKPOINT_TABLET_SMALL, COLORS } from '../../../../static/constants'
import { IMessage } from './'

interface IProps {
  messages: IMessage[]
}

type MessageType = 'host' | 'guest'

const Thread: React.FC<IProps> = ({ messages }) => (
  <S.Thread>
    <div>
      {messages.map(message => (
        <S.Message key={message.id} type={message.other ? 'guest' : 'host'}>
          <S.Avator>
            <img src={message.sender.avatar_url} alt="" loading="lazy" decoding="async" />
          </S.Avator>
          <S.Balloon>
            <div>
              {message.file_attached && (
                <S.Image>
                  <img src={message.file_url} alt="" loading="lazy" decoding="async" />
                </S.Image>
              )}
              <div>{message.body}</div>
            </div>
            <S.Time>{message.formatted_sent_time}</S.Time>
          </S.Balloon>
        </S.Message>
      ))}
    </div>
  </S.Thread>
)

const S: { [key: string]: AnyStyledComponent } = {}

S.Thread = styled.div`
  flex: 1;
`

S.Avator = styled.div`
  flex: none;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 40px;
    height: 40px;
  }

  > img {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
`

S.Balloon = styled.div`
  padding: 8px 16px;
  border: solid 1px ${COLORS.Border};
  border-radius: 12px;
`

S.Image = styled.div`
  max-width: 360px;

  > img {
    width: 100%;
    height: auto;
  }
`

S.Time = styled.div`
  font-size: 12px;
  margin-top: 4px;
`

S.Message = styled.div<{ type: MessageType }>`
  display: flex;

  ${({ type }) =>
    type === 'host' &&
    `
    ${S.Balloon} {
      border-top-left-radius: 0 !important;
      margin-left: 12px !important;
      margin-right: 60px !important;
    }
  `}

  ${({ type }) =>
    type === 'guest' &&
    `
    flex-direction: row-reverse;

    ${S.Balloon} {
      border-top-right-radius: 0;
      margin-right: 12px;
      margin-left: 60px;
      text-align: right;
    }
  `}

  & + & {
    margin-top: 12px;
  }
`

export default Thread
