import * as React from 'react'
import I18n from '../../../../core/i18n'
import { IWindow } from '../../../../core/interfaces'
import { adminService } from '../../../../core/services'
import { CheckBox, InputText, Select, Spacer } from '../../../atoms'
import { InputDateTime } from '../../../molecules'
import AdminLayoutNew from '../_layouts/new'

const FIELDS = {
  // store_limited_flag: 'store_limited_flag',
  // once_a_day_flag: 'once_a_day_flag',
  // target_post_id: 'target_post_id',
  code: 'code',
  value: 'value',
  start_at: 'start_at',
  end_at: 'end_at',
  quantity: 'quantity',
  minimum_order_amount: 'minimum_order_amount',
}

declare var window: IWindow

const title = I18n.t('admin.create_new', {
  model: I18n.t('coupon', { scope: 'activerecord.models' }),
})

const AdminCouponNew: React.FC<{}> = props => {
  const [posts, setPosts] = React.useState([])
  const [storeLimitedChecked, setStoreLimitedChecked] = React.useState(false)
  const [onceADayChecked, setOnceADayChecked] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const handleSubmit = React.useCallback(async (initialValues, values) => {
    setIsSubmitting(true)
    try {
      const { createdCoupon, flush } = await adminService.createCoupon(values)
      location.href = '/admin/coupons'
      window.flashMessages.addMessage({ text: flush.message, type: flush.type })
    } catch (error) {
      console.error(error)
    } finally {
      // 画面遷移するまでtrueのままにしておく
      setIsSubmitting(true)
    }
  }, [])

  const formatSelectOptions = options => {
    return options.map((option: { id: number; name: string }) => ({
      value: option.id,
      label: option.name,
    }))
  }

  const getPosts = async () => {
    const { postList } = await adminService.getPosts()
    setPosts([{ value: '', label: '-' }, ...formatSelectOptions(postList)])
  }
  React.useEffect(() => {
    getPosts()
  }, [])

  return (
    <AdminLayoutNew
      model="coupon"
      title={title}
      fields={FIELDS}
      indexLink="/admin/coupons"
      formItems={
        <>
          {/* <CheckBox
            name="store_limited_flag"
            defaultChecked={false}
            onChangeHandler={e => setStoreLimitedChecked(e.target.checked)}
            label={I18n.t('generic.store_limited_flag')}
          />
          <Spacer /> */}
          {/* {storeLimitedChecked ? (
            <>
              <Select
                required={true}
                name="target_post_id"
                label={I18n.t('generic.target_post_id')}
                options={posts}
                defaultValue={''}
              />
              <Spacer />
            </>
          ) : (
            <input name="target_post_id" type="hidden" />
          )} */}
          {/* <CheckBox
            name="once_a_day_flag"
            defaultChecked={false}
            onChangeHandler={e => setOnceADayChecked(e.target.checked)}
            label={I18n.t('generic.once_a_day_flag')}
          />
          <Spacer /> */}
          <InputText required={true} name="code" defaultValue="" label={I18n.t('generic.code')} />
          <Spacer />
          <InputText
            required={true}
            name="value"
            defaultValue=""
            label={I18n.t('generic.value')}
            inputType="number"
          />
          <Spacer />
          <InputText
            required={false}
            inputType="number"
            name="minimum_order_amount"
            defaultValue=""
            label={I18n.t('activerecord.attributes.coupon.minimum_order_amount')}
          />
          <Spacer />
          <InputDateTime
            required={true}
            name="start_at"
            defaultValue=""
            label={I18n.t('generic.start_at')}
          />
          <Spacer />
          <InputDateTime
            required={true}
            name="end_at"
            defaultValue=""
            label={I18n.t('generic.end_at')}
          />
          <Spacer />
          <InputText
            required={true}
            inputType="number"
            name="quantity"
            defaultValue=""
            label={I18n.t('generic.quantity')}
          />
        </>
      }
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
    />
  )
}

// export const S: { [key: string]: AnyStyledComponent } = {}

// S.FormItem = styled.div`
//   & + & {
//     margin-top: 16px;
//   }
// `

export default AdminCouponNew
