export const APP_NAME = 'MARUMITSU DELIVERY'
export const THEME_COLOR_VARIABLE_NAME = '--theme-color'
export const HEADER_HEIGHT = 120
export const HEADER_HEIGHT_MOBILE = 60
export const FOOTER_HEIGHT = 213
export const BREAKPOINT_DESKTOP = 1088
export const BREAKPOINT_TABLET_LARGE = 960
export const BREAKPOINT_TABLET_SMALL = 720
export const BREAKPOINT_TABLET_MOBILE = 600
export const MAX_TEXT_COUNT = 100
export const MAX_TEXTAREA_COUNT = 5000
export const DEFAULT_PRICE = 5000
export const UPLOAD_FILE_MAX_SIZE = 20000000
export const DATE_FORMAT = 'YYYY/MM/DD'
export const DATE_DISPLAY_FORMAT = 'YYYY/M/D'
export const DAYS_OF_THE_WEEK = ['日', '月', '火', '水', '木', '金', '土']
// DAYS_OF_THE_WEEKがカレンダーに使われているがカレンダーが削除になるぽい？のでそうであれば上記削除する
export const VALUES_OF_THE_WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']
export const PHONE_INPUT_PATTERN = '^0[789]0[0-9]{4}[0-9]{4}$'
export const LAND_LINE_PHONE_INPUT_PATTERN = '^0[0-9]{9,10}$'
export const TEL_INPUT_PATTERN = '[0-9]{2,4}[0-9]{3,4}[0-9]{3,4}'
const paymentRequiredEl = document.getElementById('payment-required')
export const PAYMENT_REQUIRED = paymentRequiredEl
  ? paymentRequiredEl.getAttribute('data').toLowerCase() === 'true'
  : false

export const COLORS = {
  Primary: '#661F25',
  PrimaryBlack: '#344351',
  Text: '#212529',
  TextLight: '#989AA1',
  TextPrimaryGray: '#989aa1',
  Border: '#EAEDEF',
  Danger: '#F66',
  Success: '#29C30E',
} as const

export const DAYS = [0, 1, 2, 3]

export const HOURS = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
]

export const MINUTES = [20, 25, 30, 40, 50, 60, 90, 120]

export const BUSINESS_TIMES_HOUR = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
  '24:00',
]

export const BUSINESS_TIMES = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
  '24:00',
]

export const BUSINESS_TIMES_BY_TEN = [
  '06:00',
  '06:10',
  '06:20',
  '06:30',
  '06:40',
  '06:50',
  '07:00',
  '07:10',
  '07:20',
  '07:30',
  '07:40',
  '07:50',
  '08:00',
  '08:10',
  '08:20',
  '08:30',
  '08:40',
  '08:50',
  '09:00',
  '09:10',
  '09:20',
  '09:30',
  '09:40',
  '09:50',
  '10:00',
  '10:10',
  '10:20',
  '10:30',
  '10:40',
  '10:50',
  '11:00',
  '11:10',
  '11:20',
  '11:30',
  '11:40',
  '11:50',
  '12:00',
  '12:10',
  '12:20',
  '12:30',
  '12:40',
  '12:50',
  '13:00',
  '13:10',
  '13:20',
  '13:30',
  '13:40',
  '13:50',
  '14:00',
  '14:10',
  '14:20',
  '14:30',
  '14:40',
  '14:50',
  '15:00',
  '15:10',
  '15:20',
  '15:30',
  '15:40',
  '15:50',
  '16:00',
  '16:10',
  '16:20',
  '16:30',
  '16:40',
  '16:50',
  '17:00',
  '17:10',
  '17:20',
  '17:30',
  '17:40',
  '17:50',
  '18:00',
  '18:10',
  '18:20',
  '18:30',
  '18:40',
  '18:50',
  '19:00',
  '19:10',
  '19:20',
  '19:30',
  '19:40',
  '19:50',
  '20:00',
  '20:10',
  '20:20',
  '20:30',
  '20:40',
  '20:50',
  '21:00',
  '21:10',
  '21:20',
  '21:30',
  '21:40',
  '21:50',
  '22:00',
  '22:10',
  '22:20',
  '22:30',
  '22:40',
  '22:50',
  '23:00',
  '23:10',
  '23:20',
  '23:30',
  '23:40',
  '23:50',
  '23:59',
]

// 変更時に書き換え バックエンドからもらえると安心かも
export const MIN_ORDER_PRICE = 1

export const ROLES = [
  { label: 'ゲスト', value: 1 },
  { label: '店舗ユーザー', value: 2 },
  { label: 'Admin(管理者)', value: 3 },
]
export const DELIVERY_AREA = [
  '0010000',
  '0010010',
  '0010011',
  '0010012',
  '0010013',
  '0010014',
  '0010015',
  '0010016',
  '0010017',
  '0010018',
  '0010019',
  '0010020',
  '0010021',
  '0010022',
  '0010023',
  '0010024',
  '0010025',
  '0010026',
  '0010027',
  '0010028',
  '0010029',
  '0010030',
  '0010031',
  '0010032',
  '0010033',
  '0010034',
  '0010035',
  '0010036',
  '0010037',
  '0010038',
  '0010039',
  '0010040',
  '0010045',
  '0010901',
  '0010902',
  '0010903',
  '0010904',
  '0010905',
  '0010906',
  '0010907',
  '0010908',
  '0010909',
  '0010910',
  '0010911',
  '0010912',
  '0010915',
  '0010921',
  '0010922',
  '0010923',
  '0010924',
  '0010925',
  '0010926',
  '0010927',
  '0010928',
  '0010930',
  '0010931',
  '0010932',
  '0010933',
  '0010934',
  '0010935',
  '0020851',
  '0020852',
  '0020853',
  '0020854',
  '0020855',
  '0020856',
  '0020857',
  '0020858',
  '0020859',
  '0020860',
  '0020861',
  '0020865',
  '0028001',
  '0028002',
  '0028003',
  '0028004',
  '0028005',
  '0028006',
  '0028007',
  '0028008',
  '0028009',
  '0028010',
  '0028011',
  '0028012',
  '0028021',
  '0028022',
  '0028023',
  '0028024',
  '0028025',
  '0028026',
  '0028027',
  '0028028',
  '0028029',
  '0028030',
  '0028031',
  '0028032',
  '0028033',
  '0028034',
  '0028035',
  '0028036',
  '0028037',
  '0028038',
  '0028041',
  '0028042',
  '0028043',
  '0028044',
  '0028045',
  '0028051',
  '0028052',
  '0028053',
  '0028054',
  '0028055',
  '0028061',
  '0028062',
  '0028063',
  '0028064',
  '0028065',
  '0028066',
  '0028067',
  '0028068',
  '0028071',
  '0028072',
  '0028073',
  '0028074',
  '0028075',
  '0028081',
  '0028082',
  '0028091',
  '0030000',
  '0030001',
  '0030002',
  '0030003',
  '0030004',
  '0030005',
  '0030006',
  '0030011',
  '0030012',
  '0030013',
  '0030021',
  '0030022',
  '0030023',
  '0030024',
  '0030025',
  '0030026',
  '0030027',
  '0030028',
  '0030029',
  '0030030',
  '0030801',
  '0030802',
  '0030803',
  '0030804',
  '0030805',
  '0030806',
  '0030807',
  '0030808',
  '0030809',
  '0030811',
  '0030812',
  '0030813',
  '0030814',
  '0030821',
  '0030822',
  '0030823',
  '0030824',
  '0030825',
  '0030826',
  '0030827',
  '0030828',
  '0030829',
  '0030830',
  '0030831',
  '0030832',
  '0030833',
  '0030834',
  '0030835',
  '0030836',
  '0030837',
  '0030838',
  '0030839',
  '0030840',
  '0030849',
  '0030851',
  '0030852',
  '0030853',
  '0030854',
  '0030855',
  '0030859',
  '0030861',
  '0030862',
  '0030863',
  '0030864',
  '0030865',
  '0030869',
  '0030871',
  '0030872',
  '0030873',
  '0030874',
  '0030875',
  '0030876',
  '0040000',
  '0040000',
  '0040001',
  '0040002',
  '0040003',
  '0040004',
  '0040005',
  '0040006',
  '0040007',
  '0040011',
  '0040012',
  '0040013',
  '0040014',
  '0040015',
  '0040021',
  '0040022',
  '0040031',
  '0040032',
  '0040033',
  '0040039',
  '0040041',
  '0040042',
  '0040051',
  '0040052',
  '0040053',
  '0040054',
  '0040055',
  '0040061',
  '0040062',
  '0040063',
  '0040064',
  '0040065',
  '0040068',
  '0040069',
  '0040071',
  '0040072',
  '0040073',
  '0040074',
  '0040075',
  '0040076',
  '0040801',
  '0040802',
  '0040803',
  '0040804',
  '0040805',
  '0040809',
  '0040811',
  '0040812',
  '0040813',
  '0040814',
  '0040815',
  '0040821',
  '0040831',
  '0040832',
  '0040833',
  '0040834',
  '0040835',
  '0040836',
  '0040839',
  '0040840',
  '0040841',
  '0040842',
  '0040843',
  '0040844',
  '0040845',
  '0040846',
  '0040847',
  '0040848',
  '0040849',
  '0040859',
  '0040861',
  '0040862',
  '0040863',
  '0040864',
  '0040865',
  '0040866',
  '0040867',
  '0040871',
  '0040872',
  '0040873',
  '0040874',
  '0040875',
  '0040876',
  '0040877',
  '0040878',
  '0040879',
  '0040880',
  '0040881',
  '0040882',
  '0040889',
  '0050000',
  '0050001',
  '0050002',
  '0050003',
  '0050004',
  '0050005',
  '0050006',
  '0050007',
  '0050008',
  '0050011',
  '0050012',
  '0050013',
  '0050014',
  '0050015',
  '0050016',
  '0050017',
  '0050018',
  '0050021',
  '0050022',
  '0050030',
  '0050031',
  '0050032',
  '0050033',
  '0050034',
  '0050035',
  '0050036',
  '0050037',
  '0050038',
  '0050039',
  '0050040',
  '0050041',
  '0050801',
  '0050802',
  '0050803',
  '0050804',
  '0050805',
  '0050806',
  '0050807',
  '0050808',
  '0050809',
  '0050810',
  '0050811',
  '0050812',
  '0050813',
  '0050814',
  '0050815',
  '0050816',
  '0050817',
  '0050818',
  '0050821',
  '0050822',
  '0050823',
  '0050824',
  '0050825',
  '0050826',
  '0050827',
  '0050830',
  '0050831',
  '0050832',
  '0050840',
  '0050841',
  '0050842',
  '0050843',
  '0050844',
  '0050849',
  '0050850',
  '0050851',
  '0050852',
  '0050853',
  '0050854',
  '0050855',
  '0050856',
  '0050861',
  '0050862',
  '0050863',
  '0050864',
  '0050865',
  '0060000',
  '0060001',
  '0060002',
  '0060003',
  '0060004',
  '0060005',
  '0060006',
  '0060009',
  '0060011',
  '0060012',
  '0060013',
  '0060014',
  '0060015',
  '0060016',
  '0060019',
  '0060021',
  '0060022',
  '0060023',
  '0060024',
  '0060025',
  '0060026',
  '0060029',
  '0060031',
  '0060032',
  '0060033',
  '0060034',
  '0060035',
  '0060039',
  '0060041',
  '0060042',
  '0060043',
  '0060049',
  '0060050',
  '0060801',
  '0060802',
  '0060803',
  '0060804',
  '0060805',
  '0060806',
  '0060807',
  '0060811',
  '0060812',
  '0060813',
  '0060814',
  '0060815',
  '0060816',
  '0060817',
  '0060818',
  '0060819',
  '0060820',
  '0060821',
  '0060822',
  '0060823',
  '0060829',
  '0060831',
  '0060832',
  '0060833',
  '0060834',
  '0060835',
  '0060836',
  '0060837',
  '0060838',
  '0060839',
  '0060840',
  '0060841',
  '0060842',
  '0060851',
  '0060852',
  '0060853',
  '0060859',
  '0060860',
  '0060861',
  '0070001',
  '0070002',
  '0070003',
  '0070004',
  '0070005',
  '0070006',
  '0070011',
  '0070030',
  '0070031',
  '0070032',
  '0070033',
  '0070034',
  '0070801',
  '0070802',
  '0070803',
  '0070804',
  '0070805',
  '0070806',
  '0070807',
  '0070808',
  '0070809',
  '0070810',
  '0070811',
  '0070812',
  '0070813',
  '0070814',
  '0070815',
  '0070819',
  '0070820',
  '0070821',
  '0070822',
  '0070823',
  '0070824',
  '0070825',
  '0070826',
  '0070827',
  '0070828',
  '0070829',
  '0070834',
  '0070835',
  '0070836',
  '0070837',
  '0070838',
  '0070839',
  '0070840',
  '0070841',
  '0070842',
  '0070843',
  '0070844',
  '0070845',
  '0070846',
  '0070847',
  '0070848',
  '0070849',
  '0070850',
  '0070851',
  '0070852',
  '0070861',
  '0070862',
  '0070863',
  '0070864',
  '0070865',
  '0070866',
  '0070867',
  '0070868',
  '0070869',
  '0070870',
  '0070871',
  '0070872',
  '0070873',
  '0070874',
  '0070880',
  '0070881',
  '0070882',
  '0070883',
  '0070884',
  '0070885',
  '0070886',
  '0070890',
  '0070891',
  '0070892',
  '0070893',
  '0070894',
  '0070895',
  '0600000',
  '0600001',
  '0600002',
  '0600003',
  '0600004',
  '0600005',
  '0600006',
  '0600007',
  '0600008',
  '0600009',
  '0600010',
  '0600011',
  '0600012',
  '0600013',
  '0600014',
  '0600015',
  '0600016',
  '0600017',
  '0600018',
  '0600020',
  '0600021',
  '0600022',
  '0600031',
  '0600032',
  '0600033',
  '0600034',
  '0600035',
  '0600041',
  '0600042',
  '0600051',
  '0600052',
  '0600053',
  '0600054',
  '0600055',
  '0600056',
  '0600057',
  '0600061',
  '0600062',
  '0600063',
  '0600806',
  '0600807',
  '0600808',
  '0600809',
  '0600810',
  '0600811',
  '0600812',
  '0600813',
  '0600814',
  '0600815',
  '0600816',
  '0600817',
  '0600818',
  '0600819',
  '0600820',
  '0600905',
  '0600906',
  '0600907',
  '0600908',
  '0600909',
  '0612271',
  '0612275',
  '0612276',
  '0612281',
  '0612282',
  '0612283',
  '0612284',
  '0612285',
  '0612286',
  '0620000',
  '0620001',
  '0620002',
  '0620003',
  '0620004',
  '0620005',
  '0620006',
  '0620007',
  '0620008',
  '0620009',
  '0620010',
  '0620011',
  '0620012',
  '0620020',
  '0620021',
  '0620022',
  '0620023',
  '0620024',
  '0620025',
  '0620031',
  '0620032',
  '0620033',
  '0620034',
  '0620035',
  '0620039',
  '0620041',
  '0620042',
  '0620043',
  '0620045',
  '0620051',
  '0620052',
  '0620053',
  '0620054',
  '0620055',
  '0620901',
  '0620902',
  '0620903',
  '0620904',
  '0620905',
  '0620906',
  '0620907',
  '0620908',
  '0620909',
  '0620911',
  '0620912',
  '0620921',
  '0620922',
  '0620931',
  '0620932',
  '0620933',
  '0620934',
  '0620935',
  '0620936',
  '0620937',
  '0620938',
  '0630000',
  '0630001',
  '0630002',
  '0630003',
  '0630004',
  '0630005',
  '0630006',
  '0630007',
  '0630009',
  '0630011',
  '0630012',
  '0630021',
  '0630022',
  '0630023',
  '0630029',
  '0630031',
  '0630032',
  '0630033',
  '0630034',
  '0630035',
  '0630036',
  '0630037',
  '0630038',
  '0630039',
  '0630040',
  '0630041',
  '0630042',
  '0630043',
  '0630044',
  '0630049',
  '0630051',
  '0630052',
  '0630053',
  '0630054',
  '0630059',
  '0630061',
  '0630062',
  '0630801',
  '0630802',
  '0630803',
  '0630804',
  '0630811',
  '0630812',
  '0630813',
  '0630814',
  '0630821',
  '0630822',
  '0630823',
  '0630824',
  '0630825',
  '0630826',
  '0630827',
  '0630828',
  '0630829',
  '0630830',
  '0630831',
  '0630832',
  '0630833',
  '0630834',
  '0630835',
  '0630836',
  '0630837',
  '0630841',
  '0630842',
  '0630843',
  '0630844',
  '0630845',
  '0630846',
  '0630847',
  '0630848',
  '0630849',
  '0630850',
  '0630861',
  '0630862',
  '0630863',
  '0630864',
  '0630865',
  '0630866',
  '0630867',
  '0630868',
  '0630869',
  '0630870',
  '0640801',
  '0640802',
  '0640803',
  '0640804',
  '0640805',
  '0640806',
  '0640807',
  '0640808',
  '0640809',
  '0640810',
  '0640811',
  '0640820',
  '0640821',
  '0640822',
  '0640823',
  '0640824',
  '0640825',
  '0640826',
  '0640912',
  '0640913',
  '0640914',
  '0640915',
  '0640916',
  '0640917',
  '0640918',
  '0640919',
  '0640920',
  '0640921',
  '0640922',
  '0640923',
  '0640924',
  '0640925',
  '0640926',
  '0640927',
  '0640928',
  '0640929',
  '0640930',
  '0640931',
  '0640941',
  '0640942',
  '0640943',
  '0640944',
  '0640946',
  '0640951',
  '0640952',
  '0640953',
  '0640954',
  '0640958',
  '0640959',
  '0650000',
  '0650004',
  '0650005',
  '0650006',
  '0650007',
  '0650008',
  '0650009',
  '0650010',
  '0650011',
  '0650012',
  '0650013',
  '0650014',
  '0650015',
  '0650016',
  '0650017',
  '0650018',
  '0650019',
  '0650020',
  '0650021',
  '0650022',
  '0650023',
  '0650024',
  '0650025',
  '0650026',
  '0650027',
  '0650028',
  '0650030',
  '0650031',
  '0650032',
  '0650033',
  '0650041',
  '0650042',
  '0650043',
]
