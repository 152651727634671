import * as React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../../core/i18n'
import {
  IItemCategory,
  IPager,
  IPost,
  IPostItem,
  IPostOption,
  IWindow,
} from '../../../../../core/interfaces'
import { postItemService, postService, utilService } from '../../../../../core/services'
import { COLORS } from '../../../../../static/constants'
import { BREAKPOINT_TABLET_SMALL } from '../../../../../static/constants'
import { Button, InputText, Pagination, Select, Spacer, Spinner } from '../../../../atoms'
import { Form } from '../../../../molecules'
import { FlashMessage } from '../../../../organisms'
import PostItemEdit from './edit'

declare var window: IWindow

interface IProps {
  post: IPost
  itemCategories: IItemCategory[]
  searchParams?: any
}

const STATUS_OPTIONS = [
  {
    label: I18n.t('generic.publish'),
    value: 'published',
  },
  {
    label: I18n.t('generic.closed'),
    value: 'closed',
  },
  {
    label: I18n.t('generic.delete'),
    value: 'delete',
  },
]

const SEARCH_FIELDS = {
  keyword: 'keyword',
  status: 'status',
  large_category_id: 'large_category_id',
  medium_category_id: 'medium_category_id',
  small_category_id: 'small_category_id',
}

const statusOptions = () => {
  return [
    { label: '全て', value: 'all' },
    { label: '公開', value: 'published' },
    { label: '非公開', value: 'closed' },
  ]
}

const perPageOptions = () => {
  return [
    { label: '30件表示', value: 30 },
    { label: '50件表示', value: 50 },
    { label: '100件表示', value: 100 },
  ]
}

export const PostContext = React.createContext(
  {} as {
    items: IPostItem[]
    setItems: React.Dispatch<React.SetStateAction<IPostItem[]>>
  }
)

const PostItemIndex: React.FC<IProps> = props => {
  const [editItem, setEditItem] = React.useState<IPostItem | null>(null)
  // // 初期値
  const [items, setItems] = React.useState<IPostItem[]>([])
  const [loading, setLoading] = React.useState(true)
  const [pagination, setPagination] = React.useState<IPager>()
  const [pageNumber, setPageNumber] = React.useState(1)
  const [categoryId, setCategoryId] = React.useState(null)
  const [keyword, setKeyword] = React.useState('')
  const [status, setStatus] = React.useState('all')
  const [perPage, setPerPage] = React.useState(30)

  const fetchData = async () => {
    setLoading(true)
    const response = await postService.getPostItems(props.post, {
      page: pageNumber,
      category_id: categoryId,
      keyword,
      status,
      per_page: perPage,
    })
    setItems(response.post_items)
    setPagination(response.pagination)
    setLoading(false)
  }

  React.useEffect(() => {
    fetchData()
  }, [pageNumber, categoryId, keyword, status, perPage])

  const context = {
    items,
    setItems,
  }
  const [largeCategories, setLargeCategories] = React.useState<IItemCategory[]>(
    props.itemCategories.filter(item => item.parent_id == null)
  )

  const [mediumCategories, setMediumCategories] = React.useState<IItemCategory[]>(
    props.searchParams.category_id
      ? props.itemCategories.filter(
          item => item.parent_id === Number(props.searchParams.category_id)
        )
      : []
  )
  const [smallCategories, setSmallCategories] = React.useState<IItemCategory[]>([])

  const openHTML = () => {
    const url = '/posts/' + props.post.id + '/menus'
    const win = open(url, '_blank')
    win.focus()
  }

  const handleCreateItem = (id?: string) => {
    const newItem = {
      aasm_state: '',
      aasm_state_i18n: '',
      description: '',
      id: undefined,
      name: '',
      price: undefined,
      retail_price: undefined,
      settlement_price: undefined,
      tax_rate: undefined,
      wholesale_price: undefined,
      published: true,
      quantity: undefined,
      is_quantity_limited: false,
      quantity_limited_status: '',
      post_options: [],
      item_category_id: '',
      item_category_name: '',
    }
    setEditItem(newItem)
    setItems(prev => {
      const newState = [...prev]
      newState.push(newItem)
      return newState
    })
  }
  const handleEditItem = (itemId: number) => {
    setEditItem(items.find((item: IPostItem) => item.id === itemId))
  }

  const updateAndShowFlashMessage = (
    itemId: number,
    post_item: object,
    flush: { message: string; type: string },
    deleted?: boolean
  ) => {
    window.flashMessages.addMessage({ text: flush.message, type: flush.type })

    const { data: updatedPostItem } = utilService.getDataFromJson(post_item)
    setItems(
      deleted
        ? items.filter((item: IPostItem) => item.id !== itemId)
        : items.map((item: IPostItem) => {
            return item.id !== itemId ? item : updatedPostItem
          })
    )
  }

  const handleDelete = async (itemId: number) => {
    const { flush, post_item } = await postItemService.delete(itemId, props.post.id)
    updateAndShowFlashMessage(itemId, post_item, flush, true)
    window.globalModal.closeModal()
  }
  const showDeleteModal = (itemId: number) => {
    window.globalModal.showModal({
      title: '本当に商品を削除しますか？',
      closeText: I18n.t('generic.cancel'),
      submitText: I18n.t('generic.delete'),
      submitDanger: true,
      handleSubmit: () => handleDelete(itemId),
    })
  }
  const handlers = {
    published: async (itemId: number) => {
      const { flush, post_item } = await postItemService.publish(itemId, props.post.id)
      updateAndShowFlashMessage(itemId, post_item, flush)
    },
    suspended: async (itemId: number) => {
      const { flush, post_item } = await postItemService.suspend(itemId, props.post.id)
      updateAndShowFlashMessage(itemId, post_item, flush)
    },
    closed: async (itemId: number) => {
      const { flush, post_item } = await postItemService.close(itemId, props.post.id)
      updateAndShowFlashMessage(itemId, post_item, flush)
    },
    error: () => {
      window.flashMessages.addMessage({ text: '変更に失敗しました', type: 'error' })
    },
  }

  const EditButtons: React.FC<{ id: number; aasm_state: string }> = ({ id, aasm_state }) => {
    const [targetState, setTargetState] = React.useState<string>(aasm_state)
    const onChangeHandle = event => {
      setTargetState(event.target.value)
    }

    const handleClickByTargetState = (state: string) => {
      if (targetState === aasm_state) {
        return
      }
      if (handlers[state]) {
        handlers[state](id)
      } else {
        handlers.error()
      }
    }
    return (
      <S.Buttons>
        <Button handleClick={() => handleEditItem(id)}>{I18n.t('generic.edit')}</Button>
        <S.StateChange>
          <h4>公開状態の変更</h4>
          <Select
            name="status"
            options={STATUS_OPTIONS}
            defaultValue={aasm_state}
            onChangeHandler={onChangeHandle}
          />

          {targetState === 'delete' && (
            <Button
              primary={true}
              backgroundColor={COLORS.Danger}
              handleClick={() => showDeleteModal(id)}
            >
              削除する
            </Button>
          )}
          {targetState !== 'delete' && (
            <Button primary={true} handleClick={() => handleClickByTargetState(targetState)}>
              {I18n.t('generic.update')}
            </Button>
          )}
        </S.StateChange>
      </S.Buttons>
    )
  }
  const handleUpdateItem = (newItem: IPostItem) => {
    const newItemArray = []
    items.forEach(item => {
      if (item.id === newItem.id) {
        newItemArray.push(newItem)
      } else {
        newItemArray.push(item)
      }
    })
    setItems(newItemArray)
  }
  const handleBack = () => {
    setEditItem(null)
  }

  const newItems = React.useRef(items)
  React.useEffect(() => {
    newItems.current = items
  }, [items])

  const saveOrder = React.useCallback(async () => {
    const itemIds = []
    newItems.current.forEach(item => {
      itemIds.unshift(item.id)
    })
    const { flush, post } = await postItemService.update_order(itemIds, props.post.id)
    updateAndShowFlashMessage(null, null, flush)
  }, [])

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    let reordered_items = []
    reordered_items = reorder(items, result.source.index, result.destination.index)
    setItems(reordered_items)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? 'lightgreen' : 'grey',

    ...draggableStyle,
  })

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#eee' : 'white',
  })

  const onChangePageHandler = React.useCallback(page => {
    setPageNumber(page)
  }, [])

  const handleSearchSubmit = React.useCallback(async (initialValues, values) => {
    if (values.small_category_id) {
      setCategoryId(values.small_category_id)
    } else if (values.medium_category_id) {
      setCategoryId(values.medium_category_id)
    } else {
      setCategoryId(values.large_category_id)
    }
    setKeyword(values.keyword)
    setStatus(values.status)
    setPageNumber(1)
    setPerPage(30)
  }, [])

  const handleLargeCategoryChange = e => {
    const largeCategoryId = e.currentTarget.value
    setMediumCategories(
      props.itemCategories.filter(item => item.parent_id === Number(largeCategoryId))
    )
    setSmallCategories([])
  }

  const handleMediumCategoryChange = e => {
    const mediumCategoryId = e.currentTarget.value
    setSmallCategories(
      props.itemCategories.filter(item => item.parent_id === Number(mediumCategoryId))
    )
  }

  const handlePerPageChange = e => {
    const perPageSelect = e.currentTarget.value
    setPerPage(Number(perPageSelect))
  }

  return (
    <PostContext.Provider value={context}>
      {editItem === null ? (
        <div>
          <S.Search>
            <Form fields={SEARCH_FIELDS} handleSubmit={handleSearchSubmit}>
              <InputText
                required={false}
                name="keyword"
                defaultValue={props.searchParams.keyword || ''}
                label="キーワード"
                width={300}
              />
              <Spacer />
              <Select
                required={false}
                name="large_category_id"
                label={I18n.t('meta.post.large_category')}
                options={largeCategories.map(item => {
                  return { label: item.name, value: item.id }
                })}
                initialText={I18n.t('meta.post.set_large_category')}
                defaultValue={props.searchParams.category_id || ''}
                width={300}
                onChangeHandler={handleLargeCategoryChange}
              />
              {mediumCategories.length > 0 && (
                <>
                  <Spacer />
                  <Select
                    required={false}
                    name="medium_category_id"
                    label={I18n.t('meta.post.medium_category')}
                    options={mediumCategories.map(item => {
                      return { label: item.name, value: item.id }
                    })}
                    initialText={I18n.t('meta.post.set_medium_category')}
                    defaultValue={props.searchParams.medium_category_id || ''}
                    width={300}
                    onChangeHandler={handleMediumCategoryChange}
                  />
                </>
              )}
              {smallCategories.length > 0 && (
                <>
                  <Spacer />
                  <Select
                    required={false}
                    name="small_category_id"
                    label={I18n.t('meta.post.small_category')}
                    options={smallCategories.map(item => {
                      return { label: item.name, value: item.id }
                    })}
                    initialText={I18n.t('meta.post.set_small_category')}
                    defaultValue={props.searchParams.small_category_id || ''}
                    width={300}
                  />
                </>
              )}
              <Spacer />
              <Select
                required={false}
                name="status"
                label="公開状態"
                options={statusOptions()}
                defaultValue={props.searchParams.status || 'all'}
                width={300}
              />
              <Spacer />
              <S.ButtonSearch>
                <Button primary={true} small={true}>
                  検索
                </Button>
              </S.ButtonSearch>
            </Form>
          </S.Search>
          <S.Header>
            <S.HeaderButton>
              <Button primary={true} handleClick={() => handleCreateItem()}>
                {I18n.t('meta.post.create_menu')}
              </Button>

              <Button primary={false} handleClick={() => saveOrder()}>
                {I18n.t('meta.post.save_order')}
              </Button>
            </S.HeaderButton>
            <Select
              required={false}
              name="per_page"
              options={perPageOptions()}
              width={100}
              onChangeHandler={handlePerPageChange}
            />
          </S.Header>
          {loading ? (
            <Spinner />
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provideded, snapshoted) => (
                  <ul
                    {...provideded.droppableProps}
                    ref={provideded.innerRef}
                    style={getListStyle(snapshoted.isDraggingOver)}
                  >
                    {items?.length === 0 ? (
                      <p>商品がありません</p>
                    ) : (
                      <>
                        {items?.map((item: IPostItem, index: number) => (
                          <Draggable key={item.id} draggableId={'item_' + item.id} index={index}>
                            {(provided, snapshot) => (
                              <li
                                key={index}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <S.PostItem>
                                  <S.ItemImage>
                                    {item.post_item_images.length === 0 ? (
                                      <S.NoImage>No Image</S.NoImage>
                                    ) : (
                                      <img
                                        src={item.post_item_images[0].image_url}
                                        alt=""
                                        loading="lazy"
                                        decoding="async"
                                      />
                                    )}
                                  </S.ItemImage>
                                  <S.ItemInfo>
                                    <S.ItemHeader>
                                      <div className="PostItemListTitle">{item.name}</div>
                                      <div>{item.item_category_name}</div>
                                      <div>{item.aasm_state_i18n}</div>
                                    </S.ItemHeader>
                                    <S.ItemFooter>
                                      <div>表示価格：¥{item.retail_price.toLocaleString()}</div>
                                      <div>決済価格：¥{item.settlement_price.toLocaleString()}</div>
                                      <div>税抜価格：¥{item.price.toLocaleString()}</div>
                                      {item.is_quantity_limited && (
                                        <div>
                                          {I18n.t('post.remaining_stock')} :{' '}
                                          {item.remaining_stock ?? '0'}
                                        </div>
                                      )}
                                    </S.ItemFooter>
                                  </S.ItemInfo>
                                  <S.ItemButtons>
                                    <EditButtons {...item} />
                                  </S.ItemButtons>
                                </S.PostItem>
                              </li>
                            )}
                          </Draggable>
                        ))}
                        {provideded.placeholder}
                      </>
                    )}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          )}
          {pagination && (
            <Pagination
              onChangePageHandler={onChangePageHandler}
              currentPage={pagination.current_page}
              prevPage={pagination.prev_page}
              nextPage={pagination.next_page}
              totalPages={pagination.total_pages}
              totalCount={pagination.total_count}
            />
          )}
        </div>
      ) : (
        <PostItemEdit
          item={editItem}
          postId={props.post.id}
          handleBack={handleBack}
          updateItem={handleUpdateItem}
          itemCategories={props.itemCategories}
        />
      )}
    </PostContext.Provider>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.PostItem = styled.div`
  display: flex;
  width: 100%;
  color: rgb(33, 37, 41);
  background-color: rgb(255, 255, 255);
  padding: 16px;
  overflow: hidden;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(234, 237, 239);
  border-image: initial;
  margin-bottom: 10px;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    display: block;
  }
`
S.Header = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
`

S.ItemImage = styled.div`
  position: relative;
  width: 200px;
  height: 120px;
  background-color: rgb(244, 244, 244);
  border-radius: 3px;
  overflow: hidden;
  > img {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
    height: 200px;
  }
`
S.NoImage = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
`
S.ItemInfo = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex: 1 1 0%;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    padding-left: 0px;
  }
`
S.Buttons = styled.div`
  max-width: 200px;
`
S.StateChange = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  h4 {
    width: 100%;
    margin-bottom: 16px;
  }
`

S.ItemHeader = styled.div`
  .PostItemListTitle {
    font-size: 18px;
  }
  .PostItemListDescription {
    color: rgb(153, 153, 153);
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
  }
`
S.ItemButtons = styled.div`
  display: flex;
  align-items: center;
`
S.ItemFooter = styled.div``

S.Search = styled.div``

S.ButtonSearch = styled.div`
  margin-bottom: 16px;
  text-align: center;
`

S.HeaderButton = styled.div``

export default PostItemIndex
