import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class EventService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getDataFromJson(JsonData) {
    const data = this.serializer.parseResourceData(JsonData, JsonData.data)
    const pagination = { ...JsonData.meta }

    return { data, pagination }
  }

  public getEventFromJson(data) {
    const event = this.serializer.parseResourceData(data, data.data)

    return { event }
  }

  public getEventsFromJson(data) {
    const events = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { events, pagination }
  }

  public async getEvents() {
    const { data } = await this.httpClient.get('/api/events')
    const events = this.serializer.parseResourceData(data.events.events, data.events.events.data)
    return { events }
  }
}

export default EventService
