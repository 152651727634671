import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { COLORS, THEME_COLOR_VARIABLE_NAME } from '../../static/constants'
import FormItem from './_layouts/FormItem'

interface IProps {
  name: string
  defaultValue: string
  readonly?: boolean
  label?: null | string
  placeholder?: null | string
  error?: null | string
  required?: boolean
  inputRef?: any
  pattern?: string
  inputType?: 'text' | 'number' | 'password'
  width?: null | number
  onChangeHandler?(event: any): void | Promise<void>
  onBlurHandler?(event: any): void | Promise<void>
}

const InputText: React.FC<IProps> = ({
  name,
  defaultValue,
  readonly,
  label,
  placeholder,
  error,
  required,
  inputRef,
  pattern,
  onChangeHandler,
  onBlurHandler,
  width,
  inputType = 'text',
}) => {
  let resolvedType: string
  switch (inputType) {
    case 'number':
      resolvedType = 'number'
      break
    case 'password':
      resolvedType = 'password'
      break
    default:
      resolvedType = 'text'
  }
  return (
    <FormItem label={label} required={required} error={error}>
      <S.Input
        id={name}
        type={resolvedType}
        name={name}
        placeholder={placeholder}
        required={required ? true : false}
        defaultValue={defaultValue}
        pattern={pattern}
        width={width}
        onChange={event => {
          if (typeof onChangeHandler === 'function') {
            onChangeHandler(event)
          }
        }}
        onBlur={event => {
          if (typeof onBlurHandler === 'function') {
            onBlurHandler(event)
          }
        }}
        ref={inputRef || null}
        readOnly={readonly || false}
        min={resolvedType === 'number' ? 0 : undefined}
      />
    </FormItem>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Input = styled.input<{ width?: number }>`
  display: block;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  padding: 8px 12px;
  border: solid 1px ${COLORS.Border};
  border-radius: 4px;
  font-size: 15px;
  transition: border 0.2s ease;
  outline: none;
  background: none;

  &:not([readonly]):focus {
    border: solid 1px var(${THEME_COLOR_VARIABLE_NAME});
  }

  &[readonly] {
    background-color: ${COLORS.Border};
    color: #888;
  }
`

export default InputText
