import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { adminService } from '../../../../core/services'
import { BREAKPOINT_TABLET_SMALL, THEME_COLOR_VARIABLE_NAME } from '../../../../static/constants'
import { DropZoneUploader, InputText, Select, Spacer } from '../../../atoms'
import AdminLayoutEdit from '../_layouts/edit'

interface IProps {
  item_category: IJsonResponse
  large_categories_list: any[]
  medium_categories_list: any[]
}

interface IImage {
  image_url: string
  file?: any
}

const FIELDS = {
  id: 'id',
  name: 'name',
  large_category_id: 'large_category_id',
  medium_category_id: 'medium_category_id',
  code: 'code',
  position: 'position',
}

const title = I18n.t('admin.edit', {
  model: I18n.t('item_category', { scope: 'activerecord.models' }),
})

const AdminItemCategoryEdit: React.FC<IProps> = props => {
  const { data: item_category } = adminService.getDataFromJson(props.item_category)
  const [largeCategoryList, setLargeCategoryList] = React.useState<any[]>(
    props.large_categories_list
  )
  const largeCategoryListWithPlaceholder = [{ id: '', name: '-' }, ...largeCategoryList]
  const [mediumCategoryList, setMediumCategoryList] = React.useState<any[]>([])
  const mediumCategoryListWithPlaceholder = [{ id: '', name: '-' }, ...mediumCategoryList]

  const [image, setImage] = React.useState<IImage>(
    item_category.item_category_image && item_category.item_category_image.image_url
      ? {
          image_url: item_category.item_category_image.image_url,
        }
      : null
  )
  const ancestry = item_category.ancestry ? item_category.ancestry.split('/') : []
  const [initialMount, setInitialMount] = React.useState(true)

  const handleLargeCategoryChange = e => {
    const largeCategoryId = e.currentTarget.value
    setMediumCategoryList(
      props.medium_categories_list.filter(item => item.parent_id === Number(largeCategoryId))
    )
  }

  React.useEffect(() => {
    if (initialMount && ancestry.length === 2) {
      handleLargeCategoryChange({ currentTarget: { value: ancestry[0] } })
      setInitialMount(false)
    } else if (initialMount && ancestry.length === 1) {
      handleLargeCategoryChange({ currentTarget: { value: ancestry[0] } })
      setInitialMount(false)
    }
  }, [ancestry])

  const handleSubmit = React.useCallback(async (initialValues, values) => {
    const parentId = values.medium_category_id
      ? values.medium_category_id
      : values.large_category_id
    const payload = { ...values, parent_id: parentId || '' }
    const { updatedItemCategory, flush } = await adminService.updateItemCategory(
      payload,
      imageToUpload.current
    )

    // TODO 遷移先でのFlush
    location.href = '/admin/item_categories'
  }, [])

  const handleFileSelect = event => {
    const newImage = event.target.files[0]
    const fileReader = new FileReader()
    fileReader.onloadend = (eventRoader: any) => {
      setImage({
        file: newImage,
        image_url: eventRoader.target.result,
      })
    }
    fileReader.readAsDataURL(newImage)
  }

  const onDropHandler = addedImages => {
    addedImages.forEach(addedImage => {
      const fileReader = new FileReader()
      fileReader.onloadend = (event: any) => {
        setImage({
          file: addedImage,
          image_url: event.target.result,
        })
      }
      fileReader.readAsDataURL(addedImage)
    })
  }

  const imageToUpload = React.useRef(image)
  React.useEffect(() => {
    imageToUpload.current = image
  }, [image])

  return (
    <AdminLayoutEdit
      model="item_category"
      title={title}
      fields={FIELDS}
      indexLink="/admin/item_categories"
      formItems={
        <>
          <InputText readonly={true} name="id" defaultValue={item_category.id} label="ID" />
          <Spacer />
          <Select
            required={false}
            name="large_category_id"
            label={I18n.t('meta.post.large_category')}
            options={largeCategoryListWithPlaceholder.map(largeCategory => {
              return { label: largeCategory.name, value: largeCategory.id }
            })}
            defaultValue={ancestry.length === 0 ? item_category.parent_id : ancestry[0]}
            onChangeHandler={handleLargeCategoryChange}
          />
          {mediumCategoryList.length > 0 && (
            <>
              <Spacer />
              <Select
                required={false}
                name="medium_category_id"
                label={I18n.t('meta.post.medium_category')}
                options={mediumCategoryListWithPlaceholder.map(mediumCategory => {
                  return { label: mediumCategory.name, value: mediumCategory.id }
                })}
                defaultValue={
                  item_category.parent_id && ancestry.length === 2 ? item_category.parent_id : ''
                }
              />
            </>
          )}

          <Spacer />
          <InputText
            required={true}
            name="name"
            defaultValue={item_category.name}
            label="商品カテゴリ名"
          />
          <Spacer />
          <InputText
            required={true}
            name="code"
            defaultValue={item_category.code ? item_category.code : ''}
            label="コード"
          />
          <Spacer />
          <InputText
            required={true}
            name="position"
            defaultValue={item_category.position ? item_category.position : ''}
            label="表示順"
          />
          <Spacer />
          {/* <S.FormItem>
            <S.ImageWrapper>
              <S.FormItemTitle className="required-tag">
                {I18n.t('generic.category_image')}
              </S.FormItemTitle>
              {image && (
                <S.PreviewImage>
                  {image.image_url === '/images/no-avatar.svg' ? (
                    <p>画像がありません</p>
                  ) : (
                    <S.ItemImage>
                      <img src={image.image_url} loading="lazy" decoding="async"/>
                    </S.ItemImage>
                  )}
                  <S.ImageButtons>
                    <S.FileSelectWrapper>
                      <label htmlFor="fileSelectUploader">
                        {I18n.t('generic.change')}
                        <input id="fileSelectUploader" type="file" onChange={handleFileSelect} />
                      </label>
                    </S.FileSelectWrapper>
                  </S.ImageButtons>
                </S.PreviewImage>
              )}
              <S.DropZoneWrapper hasImage={image ? true : false}>
                <DropZoneUploader onDrop={onDropHandler} />
              </S.DropZoneWrapper>
            </S.ImageWrapper>
          </S.FormItem> */}
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.FormItem = styled.div`
  display: block;
  & + & {
    margin-top: 16px;
  }
  .required-tag::after {
    margin-left: 8px;
    content: '必須';
    border-radius: 3px;
    color: rgb(255, 255, 255);
    background-color: rgb(255, 102, 102);
    font-size: 11px;
    padding: 4px 6px;
    font-weight: normal;
  }
`

S.FormItemTitle = styled.p`
  width: 240px;
  margin-top: 8px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;

  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
    margin-bottom: 12px;
  }
`

S.DropZoneWrapper = styled.div<{ hasImage: boolean }>`
  display: block;
  width: 100%;
  flex: 1;
  ${({ hasImage }) =>
    hasImage &&
    `
    display: none;
  `}
`

S.ImageWrapper = styled.div<{ hasNoImage: boolean }>`
  display: flex;
  .DropZoneUploader {
    width: 100%;
    flex: 1;
    margin: 0;
  }

  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    flex-direction: column;
  }
`

S.ItemImage = styled.div`
  display: block;
  position: relative;
  width: 240px;
  height: inherit;
  background-color: rgb(244, 244, 244);
  border-radius: 3px;
  overflow: hidden;
  > img {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 200px;
  }
`
S.NoImage = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
`
S.PreviewImage = styled.div`
  display: flex;
  flex: 1;
`
S.ImageButtons = styled.div`
  display: flex;
  flex: 1;
  max-width: 100px;
  text-align: center;
  margin-left: 50px;
  align-items: center;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    margin-left: 20px;
  }
`

S.FileSelectWrapper = styled.div`
  cursor: pointer;
  margin-right: 12px;
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 16px;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    padding: 0 11px;
  }
  border-radius: 4px;
  outline: none;
  background-color: var(${THEME_COLOR_VARIABLE_NAME});
  color: #fff;
  border: none;

  &:hover {
    opacity: 0.6;
  }
  > a {
    color: #fff;
  }

  input {
    width: 0;
  }
`

export default AdminItemCategoryEdit
