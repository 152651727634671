import { range } from 'lodash'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import * as constants from '../../static/constants'

interface IProps {
  currentPage: number
  prevPage: number
  nextPage: number
  totalPages: number
  totalCount: number
  onChangePageHandler?(page: number): void
}

const PAGE_LIMIT = 6

const Pagination: React.FC<IProps> = ({
  currentPage,
  prevPage,
  nextPage,
  totalPages,
  totalCount,
  onChangePageHandler,
}) => {
  const pages = range(currentPage - 1, currentPage - 1 + PAGE_LIMIT).filter(
    page => page > 1 && page < totalPages
  )

  if (totalPages <= 1) {
    return null
  }

  return (
    <S.Pagination className="Pagination">
      {currentPage === 1 || prevPage === null ? (
        <S.Item disabled={true}>
          <i className="material-icons">keyboard_arrow_left</i>
        </S.Item>
      ) : (
        <S.Item onClick={() => onChangePageHandler(currentPage - 1)}>
          <i className="material-icons">keyboard_arrow_left</i>
        </S.Item>
      )}
      <S.Item active={currentPage === 1} onClick={() => onChangePageHandler(1)}>
        {1}
      </S.Item>
      {pages[0] > 2 && <span>...</span>}
      {pages.map(page => (
        <S.Item key={page} active={page === currentPage} onClick={() => onChangePageHandler(page)}>
          {page}
        </S.Item>
      ))}
      {pages[pages.length - 1] + 1 < totalPages && <span>...</span>}
      <S.Item active={currentPage === totalPages} onClick={() => onChangePageHandler(totalPages)}>
        {totalPages}
      </S.Item>
      {currentPage === totalPages || nextPage === null ? (
        <S.Item disabled={true}>
          <i className="material-icons">keyboard_arrow_right</i>
        </S.Item>
      ) : (
        <S.Item onClick={() => onChangePageHandler(currentPage + 1)}>
          <i className="material-icons">keyboard_arrow_right</i>
        </S.Item>
      )}
    </S.Pagination>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Pagination = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content center;
  margin-top: 16px;

  > span,
  > a {
    display: block;
    margin: 0 12px;
  }

  > span {
    color: #bbb;
  }
`

S.Item = styled.div<{ disabled?: boolean; active?: boolean }>`
  display: block;
  margin: 0 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(${constants.THEME_COLOR_VARIABLE_NAME});
  cursor: pointer;

  > i {
    display: block;
  }

  &:hover:not(.disabled) {
    background-color: #f6f8f9;
  }

  ${({ active }) =>
    active &&
    `
    color: #fff;
    background-color: var(${constants.THEME_COLOR_VARIABLE_NAME});
  `}

  ${({ disabled }) =>
    disabled &&
    `
    color: #bbb;
    cursor: default;
  `}
`

export default Pagination
