import { post } from 'fixtures'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IMasterItem, IPost, ITag } from '../../../../core/interfaces'
import {
  BREAKPOINT_TABLET_SMALL,
  BUSINESS_TIMES,
  DAYS_OF_THE_WEEK,
  MIN_ORDER_PRICE,
  VALUES_OF_THE_WEEK_DAYS,
} from '../../../../static/constants'
import { Button, CheckBox, InputText, InputTextArea, InputTextV2, Select } from '../../../atoms'
import FormItem from '../../../atoms/_layouts/FormItem'
import { Form, InputDateTime } from '../../../molecules'

const BASIC_INFO_FIELDS = {
  name: 'name',
  description: 'description',
  email: 'email',
  note: 'note',
  min_order_price: 'min_order_price',
  delivery_date: 'delivery_date',
  delivery_time_frame: 'delivery_time_frame',
  limited_time_flag: 'limited＿time_flag',
  delivery_end_date: 'delivery_end_date',
  publication_start_at: 'publication_start_at',
  publication_end_at: 'publication_end_at',
  limited_access_flag: 'limited_access_flag',
  limited_access_password: 'limited_access_password',
}

interface IErrors {
  [key: string]: string | null
}

interface IProps {
  post: IPost
  isNew: boolean
  isAdmin: boolean
  handleFormSubmit(initialValues: any, values: any): void
  setIsNew(isNew: boolean): void
}

const TIME_FRAMES = [
  { value: '11:00', label: '11:00-13:00' },
  { value: '15:00', label: '15:00-18:00' },
  { value: '19:00', label: '19:00-20:00' },
]

const BasicInfo: React.FC<IProps> = props => {
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false)
  const [changeMinOrderPrice, setChangeMinOrderPrice] = React.useState(false)
  const [timeFrames, setTimeFrames] = React.useState<string[]>(props.post.delivery_time_frame || [])
  const [limitedTimeFlag, setLimitedTimeFlag] = React.useState(props.post.limited_time_flag)
  const [limitedAccessFlag, setLimitedAccessFlag] = React.useState(props.post.limited_access_flag)
  const [limitedAccessPassword, setLimitedAccessPassword] = React.useState(
    props.post.limited_access_password || ''
  )
  const [errors, setErrors] = React.useState<IErrors>({})
  const handleUpdateForm = React.useCallback((updatedErrors, updatedIsSubmitEnabled, values) => {
    setErrors(updatedErrors)
    setIsSubmitEnabled(updatedIsSubmitEnabled)
  }, [])

  const setTimeFrame = e => {
    let newTimeFrames = timeFrames
    const value = e.target.value
    if (e.target.checked === true) {
      newTimeFrames.push(value)
    } else {
      newTimeFrames = newTimeFrames.filter(element => {
        return element !== value
      })
    }
    setTimeFrames(newTimeFrames)
  }

  const handleLimitedAccessFlagChange = e => {
    const checked = e.target.checked
    setLimitedAccessFlag(checked)
  }

  React.useEffect(() => {
    setIsSubmitEnabled(timeFrames.length > 0)
  }, [timeFrames])

  React.useEffect(() => {
    if (!limitedAccessFlag) {
      setLimitedAccessPassword('')
    }
  }, [limitedAccessFlag])

  return (
    <Form
      fields={BASIC_INFO_FIELDS}
      handleUpdateForm={handleUpdateForm}
      handleSubmit={props.handleFormSubmit}
    >
      <ul>
        <S.FormItem>
          <InputText
            required={true}
            name="name"
            label={I18n.t('post.post_name')}
            placeholder={I18n.t('post.post_name')}
            defaultValue={props.post.name}
            error={errors.name}
          />
          <S.FormComment>*最大100文字</S.FormComment>
        </S.FormItem>
        <S.FormItem>
          <InputText
            required={false}
            name="email"
            label={I18n.t('post.email')}
            placeholder={I18n.t('post.email')}
            defaultValue={props.post.email}
            error={errors.email}
          />
        </S.FormItem>
        <S.FormItem>
          <InputTextArea
            required={true}
            name="description"
            label={I18n.t('post.post_description')}
            placeholder={I18n.t('post.post_description')}
            defaultValue={props.post.description}
            error={errors.description}
            maxLength={500}
          />
          <S.FormComment>*最大500文字</S.FormComment>
          <S.FormComment>
            *TOPページに表示される説明文です。最短お届け日時などを記入してください。
          </S.FormComment>
        </S.FormItem>
        <S.FormItem>
          <InputTextArea
            required={false}
            name="note"
            label={I18n.t('post.note')}
            placeholder={I18n.t('post.note')}
            defaultValue={props.post.note}
            error={errors.note}
            maxLength={500}
          />
          <S.FormComment>*最大500文字</S.FormComment>
        </S.FormItem>
        <S.FormItem>
          <InputText
            readonly={true}
            name="min_order_price"
            label={I18n.t('post.min_order_price')}
            placeholder={I18n.t('post.min_order_price')}
            defaultValue={`${MIN_ORDER_PRICE}`}
            error={errors.min_order_price}
          />
        </S.FormItem>
        <S.FormItem>
          <S.FormCheckBox>
            <S.FormItemTitle>{I18n.t('post.limited_time_flag')}</S.FormItemTitle>
            <CheckBox
              name="limited_time_flag"
              defaultChecked={props.post.limited_time_flag}
              showLabelInline={true}
              onChangeHandler={e => setLimitedTimeFlag(e.target.checked)}
            />
          </S.FormCheckBox>
        </S.FormItem>
        <S.FormItem hidden={!limitedTimeFlag}>
          <InputDateTime
            required={limitedTimeFlag ? true : false}
            name="publication_start_at"
            defaultValue={props.post.publication_start_at}
            label={I18n.t('post.publication_start_at')}
            error={errors.publication_start_at}
          />
        </S.FormItem>
        <S.FormItem hidden={!limitedTimeFlag}>
          <InputDateTime
            required={limitedTimeFlag ? true : false}
            name="publication_end_at"
            defaultValue={props.post.publication_end_at}
            label={I18n.t('post.publication_end_at')}
            error={errors.publication_end_at}
          />
        </S.FormItem>
        <S.FormItem>
          <InputText
            required={true}
            inputType="number"
            name="delivery_date"
            label={I18n.t('post.delivery_date')}
            error={errors.delivery_date}
            defaultValue={props.post.delivery_date}
          />
        </S.FormItem>
        <S.FormItem hidden={!limitedTimeFlag}>
          <InputText
            required={limitedTimeFlag ? true : false}
            inputType="number"
            name="delivery_end_date"
            label={I18n.t('post.delivery_end_date')}
            error={errors.delivery_end_date}
            defaultValue={props.post.delivery_end_date}
          />
        </S.FormItem>
        <S.FormItem>
          <S.FormCheckBox>
            <S.FormItemTitle className="required-tag">
              {I18n.t('post.delivery_time_frame')}
            </S.FormItemTitle>
            <S.CheckBoxList>
              {TIME_FRAMES.map((timeFrame, index) => (
                <div key={index}>
                  <CheckBox
                    label={timeFrame.label}
                    name="delivery_time_frame"
                    defaultChecked={props.post.delivery_time_frame?.includes(
                      timeFrame.value.toString()
                    )}
                    showLabelInline={true}
                    onChangeHandler={setTimeFrame}
                    value={timeFrame.value}
                  />
                </div>
              ))}
            </S.CheckBoxList>
          </S.FormCheckBox>
        </S.FormItem>
        <S.FormItem>
          <S.FormCheckBox>
            <S.FormItemTitle>{I18n.t('post.limited_access_flag')}</S.FormItemTitle>
            <CheckBox
              name="limited_access_flag"
              defaultChecked={props.post.limited_access_flag}
              showLabelInline={true}
              onChangeHandler={handleLimitedAccessFlagChange}
            />
          </S.FormCheckBox>
        </S.FormItem>
        <S.FormItem hidden={!limitedAccessFlag}>
          <InputTextV2
            required={limitedAccessFlag ? true : false}
            name="limited_access_password"
            label={I18n.t('post.limited_access_password')}
            placeholder={I18n.t('post.limited_access_password')}
            value={limitedAccessPassword}
            error={errors.limited_access_password}
            onChangeHandler={e => setLimitedAccessPassword(e.target.value)}
          />
        </S.FormItem>
      </ul>
      <S.Footer className="Editor_Footer">
        <Button primary={true} disabled={!isSubmitEnabled}>
          {props.isNew ? I18n.t('generic.create') : I18n.t('generic.update')}
        </Button>
      </S.Footer>
    </Form>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.FormItem = styled.div`
  & + & {
    margin-top: 16px;
  }

  .required-tag::after {
    margin-left: 8px;
    content: '必須';
    border-radius: 3px;
    color: rgb(255, 255, 255);
    background-color: rgb(255, 102, 102);
    font-size: 11px;
    padding: 4px 6px;
    font-weight: normal;
  }
`
S.FormComment = styled.div`
  font-size: 12px;
  padding-bottom: 16px;
  text-align: right;
  color: #777;
`
S.FormItemTitle = styled.p`
  width: 240px;
  margin-top: 8px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;

  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
    margin-bottom: 12px;
  }
`

S.FormDisplayValue = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
S.FormLabel = styled.p`
  display: flex;
  align-items: center;
  width: 240px;
  margin-top: 8px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;
`

S.FormValue = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  button {
    margin-left: 16px;
  }
`
S.FormCheckBox = styled.div`
  display: flex;
`
S.CheckBoxList = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`
S.BusinessDays = styled.div`
  display: flex;
`
S.Footer = styled.div`
  margin-top: 16px;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    margin-top: 20px;
  }
`

export default BasicInfo
