import { isEqual } from 'lodash'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IAddress } from '../../../../core/interfaces'
import { adminService } from '../../../../core/services'
import * as constants from '../../../../static/constants'
import injectGoogleMaps from '../../../../utils/injectGoogleMaps'
import { InputText, Select, Spacer } from '../../../atoms'
import AddressForm, { ADDRESS_FIELDS, emptyToNull } from '../../../organisms/AddressForm'
import AdminLayoutEdit from '../_layouts/edit'

const FIELDS = {
  id: 'id',
  username: 'username',
  phone: 'phone',
  email: 'email',
  role_id: 'role_id',
  ...ADDRESS_FIELDS,
}

const title = I18n.t('admin.edit', {
  model: I18n.t('user', { scope: 'activerecord.models' }),
})

const AdminUserEdit: React.FC<{ user: any }> = props => {
  const { data } = adminService.getDataFromJson(props.user)
  const [userType, setUserType] = React.useState<string>(String(data.role.id))
  const handleSubmit = React.useCallback(async (initialValues, values) => {
    const param: {
      user: Partial<typeof values>
      user_role: Partial<typeof values>
      address?: Partial<IAddress>
    } = {
      user: {
        id: values.id,
      },
      user_role: {
        role_id: values.role_id,
      },
    }

    // 変更があったフィールドだけを含める
    if (values && initialValues) {
      Object.keys(values).forEach(key => {
        if (key !== 'id' && !isEqual(values[key], initialValues[key])) {
          param.user[key] = values[key]
        }
      })
    }

    if (values.role_id === String(constants.ROLES[0].value)) {
      // 住所関連の値
      param.address = {}
      Object.keys(ADDRESS_FIELDS).forEach(key => {
        if (!isEqual(values[key], initialValues[key])) {
          param.address[key] = emptyToNull(key, values[key])
        }
      })
    }
    await adminService.updateUser(param)
    // TODO 遷移先でのFlush
    location.href = '/admin/users'
  }, [])

  return (
    <AdminLayoutEdit
      model="user"
      title={title}
      fields={FIELDS}
      indexLink="/admin/users"
      formItems={
        <>
          <InputText readonly={true} name="id" defaultValue={data.id} label="ID" />
          <Spacer />
          <InputText
            required={true}
            name="username"
            defaultValue={data.username}
            label={
              userType === String(constants.ROLES[0].value)
                ? I18n.t('generic.username_kana')
                : I18n.t('generic.username')
            }
            pattern={userType === String(constants.ROLES[0].value) ? '^[ァ-ヶ ー　]+$' : null}
          />
          <Spacer />
          <InputText
            required={true}
            name="email"
            defaultValue={data.email}
            label={I18n.t('generic.email')}
          />
          <Spacer />
          <Select
            required={true}
            name="role_id"
            label={I18n.t('admin.type_of_user')}
            options={constants.ROLES}
            defaultValue={data.role.id}
            onChangeHandler={event => {
              setUserType(event.currentTarget.value)
            }}
          />
          <Spacer />
          <S.Address isGuest={userType === String(constants.ROLES[0].value)}>
            <AddressForm
              required={userType === String(constants.ROLES[0].value)}
              phoneRequired={userType === String(constants.ROLES[0].value)}
              defaultAddress={data.address}
              isGuest={userType === String(constants.ROLES[0].value)}
            />
          </S.Address>
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}
const S: { [key: string]: AnyStyledComponent } = {}
S.Address = styled.div<{ isGuest: boolean }>`
  display: ${({ isGuest }) => (isGuest ? 'block' : 'none')};
`
S.HiddenItem = styled.div`
  display: none;
`
S.Map = styled.div`
  height: 300px;
  margin-top: 32px;
  margin-bottom: 32px;

  #map {
    height: 100%;
    width: 100%;
  }
`

export default injectGoogleMaps(AdminUserEdit)
