import { Button, Select } from 'components/atoms'
import { IRole } from 'core/interfaces'
import { utilService } from 'core/services'
import { postService } from 'core/services'
import moment from 'moment'
import * as React from 'react'
import styled from 'styled-components'
import downloadCSV from 'utils/csv'
import { formatPrice } from 'utils/number'
import {
  BREAKPOINT_TABLET_MOBILE,
  BREAKPOINT_TABLET_SMALL,
  COLORS,
  HEADER_HEIGHT,
} from '../../../../static/constants'
import { Panel } from '../../../atoms'
import Nav, { ActiveTypes } from '../Nav'
interface IProps {
  role?: IRole
  model: ActiveTypes

  posts?: any
  payments: {
    payments: any
  }
}

export const formatSelectOptions = options => {
  if (options.length === 0) {
    return []
  }
  return [
    { value: '', label: '全て' },
    ...options.map((option: { id: number; name: string }) => ({
      value: option.id.toString(),
      label: option.name,
    })),
  ]
}

const getFormattedDate = (created_at: string) =>
  moment(created_at, 'YYYYMMDD HHmmss').format('YYYY/MM/DD')
const params = new URLSearchParams(location.search.substring(1))
const filterDateLabel = params.get('created_at')
const currentPostID = params.get('post_id') || ''

const PaymentPage: React.FC<IProps> = props => {
  const { data: payments } = utilService.getDataFromJson(props.payments.payments)

  const initPosts = props.posts ? postService.getPostsFromJson(props.posts.posts) : { posts: [] }
  const [posts, setPosts] = React.useState(formatSelectOptions(initPosts.posts))
  const [postId, setPostId] = React.useState<string>(currentPostID)
  const doFilter = () => {
    params.set('post_id', postId || '')
    location.href = `?${params.toString()}`
  }

  const getDataArray = (payment): string[] => {
    return [
      payment.reservation.id.toString(),
      payment.reservation.user.id.toString(),
      formatPrice(payment.amount),
      formatPrice(payment.post_transfer_amount),
      formatPrice(payment.price_without_tax),
      formatPrice(payment.tax),
      formatPrice(payment.fee_by_delivery),
      // formatPrice(payment.fee || 0),
      formatPrice(payment.reservation.used_coupon ? payment.reservation.used_coupon : 0),
    ]
  }

  const getBaseArray = (paymentsData): string[][] => {
    return [
      [
        '注文ID',
        '注文者ID',
        '売上',
        '商品代金(税込)',
        '商品代金(税抜)',
        '商品消費税',
        '配送料(税込)',
        // '決済手数料',
        'クーポン',
      ],
      ...paymentsData.map(payment => getDataArray(payment)),
    ]
  }
  const getName = () => {
    return '売上詳細一覧' + (filterDateLabel ? ` (${filterDateLabel})` : '')
  }
  return (
    <LayoutIndex>
      <Nav active="payments" role={props.role} />
      <Wrapper>
        <Panel title={getName()} fit={true}>
          <div className="Wrapper_Header">
            <Button small={true}>
              <a href="./">一覧へ戻る</a>
            </Button>
          </div>
          <div className="Wrapper_Main">
            <table>
              <thead>
                <tr>
                  <th>注文ID</th>
                  <th>売上</th>
                  <th>商品代金(税込)</th>
                  <th>商品代金(税抜)</th>
                  <th>商品消費税</th>
                  <th>配送料(税込)</th>
                  {/* <th>決済手数料</th> */}
                  <th>クーポン</th>
                </tr>
              </thead>
              <tbody>
                {payments.map(payment => (
                  <tr key={payment.id}>
                    <td>{payment.reservation.id}</td>
                    <td>{formatPrice(payment.amount)}</td>
                    <td>{formatPrice(payment.post_transfer_amount)}</td>
                    <td>{formatPrice(payment.price_without_tax)}</td>
                    <td>{formatPrice(payment.tax)}</td>
                    <td>{formatPrice(payment.fee_by_delivery)}</td>
                    {/* <td>{formatPrice(payment.fee)}</td> */}
                    <td>
                      {formatPrice(
                        payment.reservation.used_coupon ? payment.reservation.used_coupon : 0
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="buttons">
              <Button
                small={true}
                disabled={payments.length === 0}
                handleClick={() => downloadCSV(getBaseArray(payments), getName())}
              >
                CSVで保存
              </Button>
            </div>
          </div>
        </Panel>
      </Wrapper>
    </LayoutIndex>
  )
}

const LayoutIndex = styled.div`
  display: flex;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  margin: 0 auto;
  background-color: #f6f8f9;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    display: block;
  }
`
const Wrapper = styled.div`
  flex: 1;
  padding: 16px;
  margin: 0 auto;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    font-size: 14px;
  }
  .Wrapper_Header {
    display: flex;
    align-items: center;
    height: 48px;
    border-bottom: solid 1px ${COLORS.Border};
  }

  .Wrapper_Main {
    overflow: scroll;
  }

  .filters {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 0;
    border-bottom: solid 1px ${COLORS.Border};

    h3 {
      width: 100%;
    }
  }
  .filter {
    padding: 8px 16px;
    max-width: 420px;
    display: flex;

    p {
      width: 140px;
    }
  }

  .buttons {
    padding: 8px;
    display: flex;
  }

  table {
    text-align: center;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      font-size: 12px;
    }

    tr {
      th,
      td {
        padding: 4px 8px;
      }
    }
  }
`

export default PaymentPage
