import React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../core/i18n'
import {
  BREAKPOINT_TABLET_LARGE,
  COLORS,
  THEME_COLOR_VARIABLE_NAME,
} from '../../../static/constants'
import LocaleSelect from '../../atoms/LocaleSelect'

export type ActiveTypes = 'account' | 'profile' | 'card' | 'payments' | 'favorites'

interface IProps {
  active: ActiveTypes
  lang?: any
}

const SettingsNav: React.FC<IProps> = ({ active, lang }) => (
  <S.Nav>
    <S.List>
      <S.ListItem active={active === 'account'}>
        <a href="/settings">{I18n.t('generic.accounts')}</a>
      </S.ListItem>
      <S.ListItem active={active === 'profile'}>
        <a href="/settings/profile">{I18n.t('generic.profile')}</a>
      </S.ListItem>
      <S.ListItem active={active === 'card'}>
        <a href="/settings/card">{I18n.t('generic.card')}</a>
      </S.ListItem>
      <S.ListItem active={active === 'payments'}>
        <a href="/settings/payments">{I18n.t('payment_history.history')}</a>
      </S.ListItem>
      <S.ListItem active={active === 'favorites'}>
        <a href="/settings/favorites">{I18n.t('generic.favorites')}</a>
      </S.ListItem>
    </S.List>
    {lang && <LocaleSelect />}
  </S.Nav>
)

const S: { [key: string]: AnyStyledComponent } = {}

S.Nav = styled.div`
  width: 240px;
  height: 100%;
  border-bottom: solid 1px ${COLORS.Border};
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.1);
  background-color: #fff;
  z-index: 500;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    width: 100%;
    height: 40px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }
`

S.List = styled.ul`
  padding: 16px 0;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    height: inherit;
    display: -webkit-box;
    padding: 0px;
  }
`

S.ListItem = styled.li<{ active: ActiveTypes; disabled?: boolean }>`
  > a {
    display: block;
    padding: 8px 20px;
    cursor: pointer;
    font-size: 14px;
    color: ${COLORS.Text};

    &:hover {
      color: var(${THEME_COLOR_VARIABLE_NAME});
    }

    @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
      display: inline-block;
      height: inherit;

    }
  }

  ${({ active }) =>
    active &&
    `
    border-right: solid 3px var(${THEME_COLOR_VARIABLE_NAME});
    font-weight: bold;

    > a {
      color: var(${THEME_COLOR_VARIABLE_NAME});
    }

    @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
      border-right: none;
      border-bottom: solid 2px var(${THEME_COLOR_VARIABLE_NAME});
    }
  `}

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.6;
    cursor: not-allowed;
  `}

  ${({ active, disabled }) =>
    !active &&
    !disabled &&
    `
    :hover {
      color: var(${THEME_COLOR_VARIABLE_NAME});
    }
  `}
`

export default SettingsNav
