import axios from 'axios'
import parse from 'html-react-parser'
import * as React from 'react'
import Flickity from 'react-flickity-component'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../core/i18n'
import { BREAKPOINT_TABLET_LARGE, BREAKPOINT_TABLET_SMALL } from '../../static/constants'

interface IContents {
  createdAt: string
  id: string
  keyImage: {
    url: string
  }
  publishedAt: string
  title: string
  body: string
  HTML_page_code: string
  updatedAt: string
  viewDate: string
  visible: boolean
}

interface IProps {
  api_url: string
}

const API_KEY = '01363300-9dab-49d3-8d0b-a55e2e32158f'

const Restaurants: React.FC<IProps> = props => {
  const API_URL = props.api_url
  const [contents, setContents] = React.useState<IContents[]>([])
  const getData = async () => {
    const { data } = await axios.get(API_URL, { headers: { 'X-API-KEY': API_KEY } })

    if (data.contents) {
      setContents(data.contents)
    }
  }

  const flickityOptions = {
    initialIndex: 0,
    cellAlign: 'left',
    pageDots: false,
    contain: true,
  }

  const flickityOptionsNoButtons = {
    initialIndex: 0,
    cellAlign: 'left',
    contain: true,
    pageDots: false,
    prevNextButtons: false,
  }

  React.useEffect(() => {
    getData()
  }, [])

  const getOptions = (content: any) => {
    const shopCount = (content.match(/gallery\-cell/g) || []).length
    return shopCount > 4 ? flickityOptions : flickityOptionsNoButtons
  }

  return (
    <S.Restaurants>
      {contents.length > 0}
      <S.Contents>
        {contents.length > 0 ? (
          contents.map(
            content =>
              content &&
              content.visible && (
                <S.Content key={content.id}>
                  <S.Title>{content.title}</S.Title>
                  <S.ContentMain>
                    <Flickity
                      className={'carousel_' + content.id}
                      elementType={'div'}
                      options={getOptions(content.HTML_page_code)}
                      disableImagesLoaded={false}
                      reloadOnUpdate={true}
                      static={true}
                    >
                      {parse(content.HTML_page_code)}
                    </Flickity>
                  </S.ContentMain>
                </S.Content>
              )
          )
        ) : (
          <S.NoContents />
        )}
      </S.Contents>
    </S.Restaurants>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Restaurants = styled.section`
  width: 100%;
  max-width: 1360px;
  margin: 24px auto;
  padding: 0 16px;
`

S.Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
`

S.NoContents = styled.h2`
  font-size: 18px;
  font-weight: normal;
  margin: 24px 0;
  padding: 0 20px;
`

S.Contents = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin: 0 -20px;
`

S.Content = styled.div`
  width: 100%;
  padding: 10px 20px;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
  }

  > img {
    width: 160px;
    height: 120px;
    object-fit: cover;
  }
`

S.ContentMain = styled.div`
  width: 100%;
  a {
    color: rgb(51, 51, 51);
  }

  img {
    width: 260px;
    height: 160px;
    object-fit: cover;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      width: 100%;
      height: 116px;
    }
  }
`

export default Restaurants
