import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import {
  IItemCategory,
  IJsonResponse,
  IPager,
  IPost,
  IUser,
  IWindow,
} from '../../../../core/interfaces'
import { adminService, postService, userService, utilService } from '../../../../core/services'
import {
  BREAKPOINT_TABLET_LARGE,
  BREAKPOINT_TABLET_SMALL,
  COLORS,
  HEADER_HEIGHT,
  HEADER_HEIGHT_MOBILE,
  MIN_ORDER_PRICE,
  THEME_COLOR_VARIABLE_NAME,
} from '../../../../static/constants'
import { Button, Spinner } from '../../../atoms'
import { FlashMessage } from '../../../organisms'
import Address from './Address'
import BasicInfo from './BasicInfo'
import HostUser from './HostUser'
import Images from './Images'
import PostItemIndex from './items'
import StateChangeModal from './StateChangeModal'

declare var window: IWindow

interface IProps {
  post: any
  isNew: boolean
  item_categories: IItemCategory[]
  search_params?: any
}
export interface IState {
  id: string
  label: string
  value: string
}

const PostNew: React.FC<IProps> = props => {
  const { post: parsedPost } = postService.getPostFromJson(props.post)
  const { data: itemCategories } = adminService.getDataFromJson(props.item_categories)

  const currentImages = parsedPost.post_images || []
  const initialImages = currentImages.map((image: any) => ({
    id: image.id,
    url: image.image_url,
    description: image.description,
  }))

  const [images, setImages] = React.useState(initialImages)
  const [page, setPage] = React.useState('info')
  const [post, setPost] = React.useState(parsedPost || {})
  const [isNew, setIsNew] = React.useState(props.isNew)
  const InitState = {
    id: parsedPost.id,
    label: parsedPost.aasm_state_i18n,
    value: parsedPost.aasm_state,
  }
  const [publishState, setPublishState] = React.useState<IState>(InitState)
  // const { data: tags } = postService.getDataFromJson(props.tags)
  const [currentUser, setCurrentUser] = React.useState<IUser>(null)

  React.useEffect(() => {
    userService.getUserMe().then(({ data }) => {
      if (data && data.user) {
        const { data: user } = utilService.getDataFromJson(data.user)
        setCurrentUser(user)
      }
    })
  }, [])

  const changePage = changedPage => {
    setPage(changedPage)
    history.pushState(null, null, `?active=${changedPage}`)
  }

  const changePageFromParam = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const active = urlParams.get('active')
    if (active) {
      setPage(active)
    }
  }

  React.useEffect(() => {
    changePageFromParam()
    window.onpopstate = () => {
      changePageFromParam()
    }
  }, [])

  const handleFormSubmit = async (initialValues, values) => {
    const params = { post: { ...values } }

    if (params.post.min_order_price) {
      const min_order_price =
        params.post.min_order_price && parseInt(params.post.min_order_price, 10)
      if (isNaN(min_order_price) || min_order_price < MIN_ORDER_PRICE) {
        params.post.min_order_price = null
      } else {
        params.post.min_order_price = min_order_price
      }
    }

    const currentValues = {}
    Object.keys(values).forEach(key => {
      currentValues[key] = String(props.post[key])
    })

    if (parsedPost) {
      params.post.id = parsedPost.id
    }
    if (isNew) {
      const { edit_post_path } = await postService.create(params)
      location.href = edit_post_path
    } else {
      const { post: updatedPost, flush } = await postService.update(params)
      setPost(updatedPost)
      window.flashMessages.addMessage({ text: flush.message, type: flush.type })
    }
  }

  const handleAddressSubmit = async params => {
    const { post: updatedPost, flush } = await postService.update(params)
    setPost(updatedPost)
    window.flashMessages.addMessage({ text: flush.message, type: flush.type })
  }

  const getInputStatus = currentPage => {
    const status = { valid: false, message: '' }

    switch (currentPage) {
      case 'photo':
        ;(status.valid = images.length > 0),
          (status.message = images.length
            ? I18n.t('post.image_has_uploaded')
            : I18n.t('post.please_upload_image'))
        break
      case 'address':
        const requiredField = 'postal_code1'
        const requiredFieldsIsFilled = post.address && post.address?.[requiredField] !== null
        status.valid = requiredFieldsIsFilled
        status.message = requiredFieldsIsFilled
          ? I18n.t('post.address_has_entered')
          : I18n.t('post.please_input_address')
        break
    }

    return status
  }
  const showStateChangeModal = () => {
    window.globalModal.showModal({
      title: I18n.t('reservation.update_publish'),
      body: (
        <StateChangeModal
          post={parsedPost}
          currentStatus={publishState.value}
          setPublishState={setPublishState}
        />
      ),
    })
  }
  return (
    <S.Index>
      <S.Nav>
        <S.NavList>
          {!props.isNew && (
            <>
              <S.NavListItem className="not_a_link status_button_large_screen">
                <h4>{parsedPost.name}</h4>
              </S.NavListItem>
              <S.NavListItem
                style={{ paddingTop: '0' }}
                className="not_a_link status_button_large_screen"
              >
                <p>最短配送日：{parsedPost.delivery_date} 日</p>
              </S.NavListItem>
              <S.NavListItem
                style={{ paddingTop: '0', paddingBottom: '24px' }}
                className="not_a_link status_button_large_screen"
              >
                <p>配送可能時間枠：{parsedPost.delivery_time_frame.join(',')}</p>
              </S.NavListItem>
            </>
          )}
          <S.NavListItem active={page === 'info'} onClick={() => changePage('info')}>
            {I18n.t('post.basic_info')}
          </S.NavListItem>
          <S.NavListItem
            active={page === 'address'}
            disabled={props.isNew}
            onClick={() => {
              if (!props.isNew) {
                changePage('address')
              }
            }}
          >
            <span>{I18n.t('generic.address')}</span>
            {!getInputStatus('address').valid && (
              <span className="note">{I18n.t('post.please_input_address')} </span>
            )}
          </S.NavListItem>
          <S.NavListItem
            active={page === 'photo'}
            disabled={props.isNew}
            onClick={() => {
              if (!props.isNew) {
                changePage('photo')
              }
            }}
          >
            <span>{I18n.t('generic.photo')}</span>
            {!getInputStatus('photo').valid && (
              <span className="note">{I18n.t('post.please_upload_image')}</span>
            )}
          </S.NavListItem>
          <S.NavListItem
            active={page === 'menu'}
            disabled={props.isNew}
            onClick={() => {
              if (!props.isNew) {
                changePage('menu')
              }
            }}
          >
            {I18n.t('meta.post.menu')}
          </S.NavListItem>
          <S.NavListItem className="not_a_link status_button_large_screen">
            <h3>公開ステータス</h3>
          </S.NavListItem>
          <S.NavListItem className="not_a_link status_button_large_screen">
            <h4>{publishState.label}</h4>
          </S.NavListItem>

          <S.NavListItem className="status_button_large_screen">
            <Button primary={true} handleClick={showStateChangeModal}>
              {I18n.t('reservation.update_publish')}
            </Button>
          </S.NavListItem>

          <S.NavListItem>
            {!props.isNew && (
              <Button primary={true}>
                <a href={`/posts/${parsedPost.slug}`}>{I18n.t('post.view_post')}</a>
              </Button>
            )}
          </S.NavListItem>
          <S.NavListItem>
            {!props.isNew && (
              <Button primary={true}>
                <a href={`/admin/posts`}>店舗一覧画面へ戻る</a>
              </Button>
            )}
          </S.NavListItem>
        </S.NavList>
      </S.Nav>
      <S.Nav className="status_button_small_screen">
        <S.NavList>
          <S.NavListItem className="not_a_link">
            <h3>公開ステータス</h3>
          </S.NavListItem>
          <S.NavListItem className="not_a_link">
            <h4>{publishState.label}</h4>
          </S.NavListItem>
          <S.NavListItem className="">
            <Button primary={true} handleClick={showStateChangeModal}>
              {I18n.t('reservation.update')}
            </Button>
          </S.NavListItem>
        </S.NavList>
      </S.Nav>
      <S.Main>
        <S.Panel active={page === 'info'}>
          <S.PanelTitle>{I18n.t('post.input_basic_info')}</S.PanelTitle>
          <S.PanelMain>
            <BasicInfo
              post={parsedPost}
              handleFormSubmit={handleFormSubmit}
              isNew={isNew}
              setIsNew={setIsNew}
              isAdmin={currentUser?.admin}
            />
          </S.PanelMain>
        </S.Panel>
        <S.Panel active={page === 'address'}>
          <S.PanelTitle>{I18n.t('post.input_address')}</S.PanelTitle>
          <S.PanelMain>
            <Address post={parsedPost} handleFormSubmit={handleAddressSubmit} />
          </S.PanelMain>
        </S.Panel>
        <S.Panel active={page === 'photo'}>
          <S.PanelTitle>{I18n.t('generic.photo')}</S.PanelTitle>
          <S.PanelMain>
            <Images post={parsedPost} images={initialImages} updateImages={setImages} />
          </S.PanelMain>
        </S.Panel>
        {!isNew && (
          <S.Panel className="item" active={page === 'menu'}>
            <S.PanelTitle>{I18n.t('meta.post.menu')}</S.PanelTitle>
            <S.PanelMain>
              <PostItemIndex
                post={parsedPost}
                itemCategories={itemCategories}
                searchParams={props.search_params}
              />
            </S.PanelMain>
          </S.Panel>
        )}
      </S.Main>
    </S.Index>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Index = styled.div`
  display: flex;
  margin-top: ${HEADER_HEIGHT}px;
  height: calc(100vh - ${HEADER_HEIGHT}px);

  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    flex-direction: column;
    margin-top: ${HEADER_HEIGHT_MOBILE}px;
    height: calc(100vh - ${HEADER_HEIGHT_MOBILE}px);
  }

  .PostNew_Map {
    height: 240px;
    margin-top: 24px;

    &.disabled {
      display: none;
    }
  }
`

S.Nav = styled.section`
  width: 280px;
  height: 100%;
  border-bottom: solid 1px ${COLORS.Border};
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.1);
  z-index: 1000;

  &.status_button_small_screen {
    display: none;
    @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
      display: flex;
    }
  }

  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    width: 100%;
    height: 40px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }
`

S.NavList = styled.ul`
  padding: 16px 0;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    height: inherit;
    padding: 0;
    display: -webkit-box;
  }
`

S.NavListItem = styled.li<{ active: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  cursor: pointer;
  font-size: 14px;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    padding: 0 16px;
    display: flex;
    align-items: center;
  }

  &.not_a_link {
    cursor: default;
  }
  :hover:not(.not_a_link) {
    color: var(${THEME_COLOR_VARIABLE_NAME});
  }

  .note {
    color: #999;
    font-size: 12px;
    font-weight: normal;
  }

  > .Button {
    width: 100%;
    text-align: center;

    @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
      height: 30px;
      padding: 0 12px;
    }

    a {
      justify-content: center;
    }
  }

  ${({ active }) =>
    active &&
    `
    border-right: solid 3px var(${THEME_COLOR_VARIABLE_NAME});
    color: var(${THEME_COLOR_VARIABLE_NAME});
    font-weight: bold;

    @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
      border-right: none;
      border-bottom: solid 2px var(${THEME_COLOR_VARIABLE_NAME});
    }
  `}

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.6;
    cursor: not-allowed;
    :hover {
      color: inherit;
    }
  `}

  
  &.status_button_large_screen {
    display: none;
    @media (min-width: ${BREAKPOINT_TABLET_LARGE}px) {
      display: flex;
    }
  }
`

S.Published = styled.div`
  width: 100%;
  color: var(${THEME_COLOR_VARIABLE_NAME});
  font-weight: bold;
  font-size: 14px;
  text-align: center;
`

S.Panel = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? 'block' : 'none')};
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
  max-width: 880px;
  margin: 0 auto;
`

S.PanelTitle = styled.h2`
  font-size: 18px;
  border-bottom: solid 1px #eaedef;
  padding: 16px 20px 8px;
`

S.Main = styled.section`
  flex: 1;
  padding: 16px;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #f6f8f9;
`

S.PanelMain = styled.div`
  padding: 20px;
`

export default PostNew
