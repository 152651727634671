import moment from 'moment'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IRole, IWindow } from '../../../../core/interfaces'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { adminService, reservationService } from '../../../../core/services'
import { Button, Select } from '../../../atoms'
import { Form, InputDateTimeOrderAdmin } from '../../../molecules'
import { EditTable } from '../../../organisms'
import AdminLayoutIndex from '../_layouts/'

interface IProps {
  role?: IRole
  reservations: {
    reservations: IJsonResponse
  }
  search_params?: any
}

const options = { scope: 'activerecord.attributes.reservation' }
const COLUMNS = [
  {
    name: I18n.t('id', options),
    field: 'id',
  },
  {
    name: I18n.t('user_id', options),
    field: record => <span>{record.user_id}</span>,
  },
  {
    name: I18n.t('user_name', options),
    field: record => <span>{record.username}</span>,
  },
  {
    name: I18n.t('reservation_date', options),
    field: 'ordered_at',
  },
  {
    name: I18n.t('delivery_time', options),
    field: 'display_specified_delivery_time_at',
  },
  {
    name: I18n.t('approve_status', options),
    field: record => {
      const label =
        record.workflow_state !== 'approved'
          ? I18n.t(`enums.reservation.workflow_state.${record.workflow_state}`)
          : I18n.t('enums.reservation.workflow_state.approved')
      return <span>{label}</span>
    },
  },
  {
    name: I18n.t('delivery_status', options),
    field: record => <span>{record.delivery_status_i18n}</span>,
  },
  {
    name: I18n.t('total_price', options),
    field: record => <span>{record.total_price.toLocaleString()}</span>,
    // field: 'total_price',
  },
  // {
  //   name: I18n.t('used_campaign', options),
  //   field: 'used_campaign',
  // },
  {
    name: I18n.t('used_coupon', options),
    field: 'used_coupon',
  },
  {
    name: I18n.t('coupon_code', options),
    field: 'coupon_code',
  },
  {
    name: I18n.t('paid_at', options),
    field: 'paid_at',
  },
  {
    name: I18n.t('canceled_at', options),
    field: 'canceled_at',
  },
  {
    name: I18n.t('authorized_at', options),
    field: 'authorized_at',
  },
]

const FIELDS = {
  specified_delivery_time_at: 'specified_delivery_time_at',
}

const getDefaultStart = () => {
  const today = moment().startOf('day')
  const defaultStartDate = today.format('YYYY-MM-DD 11:00:00')

  return defaultStartDate
}

const getFormattedDay = (d: moment.Moment) => {
  return d.locale('ja').format('YYYY-MM-DD')
}

const params = new URLSearchParams(location.search.substring(1))
const currentDay =
  params.get('day') ||
  moment()
    .locale('ja')
    .format('YYYY-MM-DD')
const currentMoment = moment(currentDay)
const getQuery = (day: string | null = null) => {
  const byDay = day ? `?day=${day}` : '?all=true'
  return byDay
}
const doFilter = (day: string | null = null) => {
  location.href = getQuery(day)
}
const showCurrentDay = () => {
  doFilter(getFormattedDay(moment()))
}
const showNextDay = () => {
  doFilter(getFormattedDay(currentMoment.add(1, 'days')))
}
const showAllDay = () => {
  doFilter(null)
}

const Index: React.FC<IProps> = props => {
  const { data, pagination } = adminService.getDataFromJson(props.reservations.reservations)
  return (
    <AdminLayoutIndex
      role={props.role}
      model="reservation"
      heading={
        <S.Heading>
          <span>{I18n.t('activerecord.models.reservation')}</span>
          <Button small={true}>
            <a href={`/admin/reservations/download.csv?${params.toString()}`}>CSVダウンロード</a>
          </Button>
        </S.Heading>
      }
      main={
        <EditTable
          columns={COLUMNS}
          records={data}
          pagination={pagination}
          getEditLink={id => `/host/reservations/${id}`}
          editable={true}
          searchButton={
            <div className="buttons">
              <Button small={true} handleClick={showAllDay}>
                すべて
              </Button>
              <Button small={true} handleClick={showCurrentDay}>
                本日配送
              </Button>
              <Button small={true} handleClick={showNextDay}>
                翌日配送
              </Button>
            </div>
          }
          formSearch={
            <>
              <InputDateTimeOrderAdmin
                required={false}
                name="specified_delivery_time_at"
                label="配送日時"
                defaultValue={props.search_params.specified_delivery_time_at || getDefaultStart()}
              />
            </>
          }
          formFields={FIELDS}
        />
      }
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Heading = styled.div`
  .Button {
    margin-left: 24px;
  }
`

export default Index
