import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { formatPrice } from 'utils/number'
import I18n from '../../../../core/i18n'
import { IUser, IWindow } from '../../../../core/interfaces'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { reservationService } from '../../../../core/services'
import { BREAKPOINT_TABLET_MOBILE } from '../../../../static/constants'
import injectGoogleMaps from '../../../../utils/injectGoogleMaps'
import { Button } from '../../../atoms'
import ReceiptModal from './ReceiptModal'

declare var window: IWindow

export interface IMessage {
  body: string
  created_at: string
  file_attached: boolean
  file_url: null | string
  formatted_sent_time: string
  id: number
  sender: IUser
  sent_at: string
  updated_at: string
  other?: boolean
}

interface IProps {
  user: IJsonResponse
  reservation: IJsonResponse
  receipt_id: number
  purchase_statement_id: number
  post_image: any
}

const ShowDetail: React.FC<IProps> = props => {
  const { data: reservation } = reservationService.getDataFromJson(props.reservation)
  const { data: postImage } = reservationService.getDataFromJson(props.post_image)
  const [receiptId, setReceiptId] = React.useState(props.receipt_id || null)
  const [purchaseStatementId, setPurchaseStatementId] = React.useState(
    props.purchase_statement_id || null
  )

  const post = reservation.post
  const reservationItems = reservation.reservation_items

  const calc_unit_price = item => {
    return item.price / item.quantity
  }

  function parseJapaneseDate(jpDateStr) {
    const cleaned = jpDateStr
      .replace('年', '-')
      .replace('月', '-')
      .replace('日', ' ')
      .replace('時', ':')
      .replace('分', ':')
      .replace('秒', '')
    return new Date(cleaned)
  }

  const createReceiptFormRef = React.useRef(null)

  const showCreateReceiptModal = () => {
    if (receiptId) {
      return
    }

    window.globalModal.showModal({
      title: I18n.t('receipt.create_receipt'),
      body: (
        <ReceiptModal
          reservationId={reservation.id}
          createReceiptFormRef={createReceiptFormRef}
          setReceiptId={setReceiptId}
        />
      ),
      closeText: I18n.t('generic.cancel'),
      submitText: I18n.t('generic.create'),
      handleSubmit: () => createReceiptFormRef.current.handleFormSubmit(),
    })
  }

  return (
    <S.Main>
      <h2>注文履歴詳細</h2>
      <div className="Panel">
        <div className="Panel_Section">
          <dt>注文番号</dt>
          <dd>{reservation.id}</dd>
          <dt>注文状況</dt>
          <dd>{reservation.workflow_state_i18n}</dd>
          <dt>注文日時</dt>
          <dd>{reservation.ordered_at}</dd>
          <dt>配送日時</dt>
          <dd>{reservation.display_specified_delivery_time_at}</dd>
          <dt>配送先</dt>
          <dd>{`${reservation.address?.full_address_without_phone}`}</dd>
        </div>
        <div className="Panel_Section">
          <h3>{I18n.t('reservation.reservation_detail')}</h3>
          <table className="Reservation_Table">
            <thead>
              <tr>
                <th>商品</th>
                <th>単価(税込)</th>
                <th>個数</th>
                <th>小計(税込)</th>
              </tr>
            </thead>
            {reservationItems.map(item => (
              <tbody key={item.id}>
                <tr>
                  <td>{item.name}</td>
                  <td>{formatPrice(calc_unit_price(item))}</td>
                  <td>{item.quantity}</td>
                  <td>{formatPrice(item.price)}</td>
                </tr>
                {item.reservation_item_options.map(option => (
                  <tr className="OptionTr" key={option.id}>
                    <td>
                      {option.option_title} - {option.option_item_title}
                    </td>
                  </tr>
                ))}
              </tbody>
            ))}
            <tbody>
              <tr>
                <td />
                <td colSpan={2} style={{ textAlign: 'right' }}>
                  商品代金(税込)
                </td>
                <td style={{ textAlign: 'right' }}>{formatPrice(reservation.price)}</td>
              </tr>
              <tr>
                <td />
                <td colSpan={2} style={{ textAlign: 'right' }}>
                  配送料(税込)
                </td>
                <td style={{ textAlign: 'right' }}>{formatPrice(reservation.delivery_fee)}</td>
              </tr>
              {reservation.used_campaign && (
                <tr>
                  <td />
                  <td />
                  <td>{reservation.campaign_name}</td>
                  <td>-{formatPrice(reservation.used_campaign)}</td>
                </tr>
              )}
              {reservation.coupons.length > 0 && (
                <>
                  {reservation.coupons.map((coupon, index) => (
                    <tr key={index}>
                      <td />
                      <td colSpan={2} style={{ textAlign: 'right' }}>
                        クーポン({coupon.code})
                      </td>
                      <td style={{ textAlign: 'right' }}>-{formatPrice(coupon.value)}</td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td />
                <td colSpan={2} style={{ textAlign: 'right' }}>
                  {I18n.t('price.total')}
                </td>
                <td className="totalAmount" style={{ textAlign: 'right' }}>
                  {formatPrice(reservation.total_price)}
                </td>
              </tr>
            </tfoot>
          </table>
          {reservation.remarks && (
            <div className="Panel_Section">
              <h4>{I18n.t('generic.note')}</h4>
              <p className="reservationRemark">{reservation.remarks}</p>
            </div>
          )}
          {post.address && (
            <div className="Panel_Section">
              <h4>{I18n.t('reservation.remarks_notes')}</h4>
              {post.note && <div className="Post_Note">{post.note}</div>}
              <p>
                <i className="material-icons">phone</i> {post.address.phone}
              </p>
            </div>
          )}
        </div>
        <div className="Panel_Section">
          <Button small={true}>
            <a href={`/reservations/`}>注文履歴一覧へ</a>
          </Button>
          {reservation.workflow_state === 'approved' &&
            parseJapaneseDate(reservation.specified_delivery_time_at) < new Date() && (
              <Button small={true} handleClick={showCreateReceiptModal}>
                {receiptId ? (
                  <a href={`/receipts/${receiptId}`} target="_blank">
                    {I18n.t('generic.receipt')}
                  </a>
                ) : (
                  I18n.t('generic.create_receipt')
                )}
              </Button>
            )}
          {purchaseStatementId && (
            <Button small={true}>
              <a href={`/purchase_statements/${purchaseStatementId}`} target="_blank">
                {I18n.t('generic.purchase_statement')}
              </a>
            </Button>
          )}
        </div>
      </div>
    </S.Main>
  )
}
const Show: React.FC<IProps> = props => {
  const WithGoogleMap = injectGoogleMaps(ShowDetail)

  return <WithGoogleMap {...props} />
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Main = styled.div`
  margin: 0 auto;
  max-width: 880px;

  h2 {
    padding: 16px;
  }
  .Panel {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
    padding: 0 24px 24px 24px;
    margin-bottom: 24px;
  }
  .Panel_Section {
    padding-top: 16px;

    > h3 {
      font-size: 18px;
      margin-bottom: 20px;
    }

    dt {
      font-weight: bold;
    }

    dd {
      margin-top: 4px;
    }

    dd + dt {
      margin-top: 12px;
    }
    .reservationRemark {
      font-weight: bold;
      color: red;
    }
  }

  .Reservation_Post {
    display: flex;
    align-items: center;
  }

  .Reservation_Image {
    width: 200px;
    height: 160px;
    border-radius: 3px;
    overflow: hidden;

    > img {
      width: 100%;
      height: inherit;
      object-fit: cover;
    }
  }

  .Reservation_PostInfo {
    flex: 1;
  }

  .Reservation_PostTitle {
    font-size: 20px;
  }

  .Reservation_Table {
    width: 100%;
    font-size: 15px;
    tr {
      height: 40px;
      th:nth-child(1) {
        width: 50%;
      }
      th:nth-child(2) {
        text-align: center;
      }
      th:nth-child(3) {
        text-align: center;
      }
      th:nth-child(4) {
        text-align: right;
      }
      td {
        min-width: 60px;
      }
      td:nth-child(1) {
        width: 50%;
      }
      td:nth-child(2) {
        text-align: center;
      }
      td:nth-child(3) {
        text-align: center;
      }
      td:nth-child(4) {
        text-align: right;
      }
    }
    tbody {
      border-bottom: 1px solid #eaedef;
    }
    .OptionTr {
      td {
        font-size: 12px;
      }
      td:nth-child(1) {
        padding-left: 16px;
        @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
          padding-left: 0;
        }
      }
    }
    .totalAmount {
      font-weight: bold;
    }
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      thead {
        display: none;
      }
    }
  }
`

S.OptionItem = styled.ul`
  display: flex;
  list-style: none;
  li::after {
    content: '、';
  }
  li:first-child::before {
    content: '（';
  }
  li:last-child::after {
    content: '）';
  }
`

export default Show
